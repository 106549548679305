const authTokenKey = 'auth-token';
const featureFlagsKey = 'feature-flags';
const mostRecentReactionsKey = 'most-recent-reactions';
const transportAgreementPublicKeysKey = 'transport-agreement-public-keys';
const castDraftsKey = 'cast-drafts';

const wallet = {
  dismissedSetPreferredWalletKey: 'wallet:dismissed-set-preferred-wallet',
  forceCreateWallet: 'wallet:force-create-wallet',
};

export {
  authTokenKey,
  castDraftsKey,
  featureFlagsKey,
  mostRecentReactionsKey,
  transportAgreementPublicKeysKey,
};

// prefer adding keys here to minimize global namespace polution
export const storageKeys = {
  wallet,
};
