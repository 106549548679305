import { ApiFrame } from 'farcaster-client-data';

import {
  useBatchMergeIntoGlobalCache,
  useMergeIntoGlobalCache,
  useOptimisticallyUpdateObject,
} from '../../../../utils/CacheUtils';
import { buildFrameDetailsKey } from './buildFrameDetailsKey';

export const useMergeIntoGloballyCachedFrame = () => {
  return useMergeIntoGlobalCache<ApiFrame, 'domain'>({
    keyGenerator: (value) => buildFrameDetailsKey({ domain: value.domain }),
  });
};

export type MergeIntoGloballyCachedFrame = ReturnType<
  typeof useMergeIntoGloballyCachedFrame
>;

export const useBatchMergeIntoGloballyCachedFrame = () => {
  return useBatchMergeIntoGlobalCache<ApiFrame, 'domain'>({
    keyGenerator: (value) => buildFrameDetailsKey({ domain: value.domain }),
  });
};

export type BatchMergeIntoGloballyCachedFrame = ReturnType<
  typeof useBatchMergeIntoGloballyCachedFrame
>;

export const useOptimisticallyUpdateFrame = () => {
  return useOptimisticallyUpdateObject<ApiFrame, 'domain'>({
    keyGenerator: (value) => buildFrameDetailsKey({ domain: value.domain }),
  });
};
