import { ApiUserMinimal } from 'farcaster-client-data';
import { useCallback } from 'react';

import { useTrackEvent } from '../../../providers';
import { useFarcasterApiClient } from '../../../providers/FarcasterApiClientProvider';
import { SharedAmpEvent } from '../../../utils';
import { frameAnalyticsProperties } from '../../generic/useFrameAnalyticsProperties';
import {
  useInvalidateFavoriteFrames,
  useInvalidateFeaturedFrames,
  useInvalidateFrameDetails,
  useOptimisticallyUpdateFrame,
} from '../queries';

export function useAddFavoriteFrame() {
  const { apiClient } = useFarcasterApiClient();
  const { trackEvent } = useTrackEvent();

  const invalidateFavoriteFrames = useInvalidateFavoriteFrames();
  const invalidateFeaturedFrames = useInvalidateFeaturedFrames();
  const invalidateFrameDetails = useInvalidateFrameDetails();
  const optimisticallyUpdateFrame = useOptimisticallyUpdateFrame();

  return useCallback(
    async ({
      domain,
      url,
      name,
      author,
    }: {
      domain: string;
      url: string;
      name: string;
      author?: ApiUserMinimal;
    }) => {
      const revertOptimisticUpdate = optimisticallyUpdateFrame({
        domain,
        viewerContext: {
          favorited: true,
        },
      });

      try {
        const result = await apiClient.addFavoriteFrame({ domain });

        if (result.data.result.notificationDetails) {
          optimisticallyUpdateFrame({
            domain,
            viewerContext: {
              notificationsEnabled: true,
              notificationDetails: result.data.result.notificationDetails,
            },
          });
        }

        trackEvent(
          SharedAmpEvent.AddFavoriteFrame,
          frameAnalyticsProperties({
            frameUrl: url,
            frameName: name,
            author,
          }),
        );

        setTimeout(() => {
          invalidateFavoriteFrames();
          invalidateFeaturedFrames();
          invalidateFrameDetails({ domain });
        }, 1500);

        return result.data.result;
      } catch (e: unknown) {
        revertOptimisticUpdate();
        invalidateFavoriteFrames();
        invalidateFeaturedFrames();
        invalidateFrameDetails({ domain });
        throw e;
      }
    },
    [
      apiClient,
      optimisticallyUpdateFrame,
      invalidateFavoriteFrames,
      invalidateFeaturedFrames,
      invalidateFrameDetails,
      trackEvent,
    ],
  );
}
