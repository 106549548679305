// Event names to send to Statsig
export enum StatsigAction {
  ClickCast = 'click cast',
  ClickCastAction = 'click cast action',
  ClickCastAuthor = 'click cast author',
  ClickCastBookmark = 'click cast bookmark',
  ClickCastChannelMention = 'click cast channel mention',
  ClickCastTokenLink = 'click cast token link',
  ClickCastCALink = 'click cast ca link',
  ClickCastExtLink = 'click cast ext link',
  ClickCastFollowAuthor = 'click cast follow author',
  ClickCastFrame = 'click cast frame',
  ClickCastFrameButton = 'click cast frame button',
  ClickCastImage = 'click cast image',
  ClickCastIntLink = 'click cast int link',
  ClickCastLike = 'click cast like',
  ClickCastMention = 'click cast mention',
  ClickCastMint = 'click cast mint',
  ClickCastQuote = 'click cast quote',
  ClickCastQuotedCast = 'click cast quoted cast',
  ClickCastRecast = 'click cast recast',
  ClickCastReply = 'click cast reply',
  ClickCastReport = 'click cast report',
  ClickCastShareDirectCast = 'click cast share direct cast',
  ClickCastShareImage = 'click cast share image',
  ClickCastShareLink = 'click cast share link',
  ClickCastShareNative = 'click cast share native',
  ClickCastTip = 'click cast tip',
  ClickCastVideoFullscreen = 'click cast video fullscreen',
  ClickCastVideoUnmute = 'click cast video unmute',
  ClickCastWatch = 'click cast watch',
  ClickNewCastsToast = 'click new casts toast',
  ClickGenericChannelMention = 'click generic channel mention',
  ClickGenericExtLink = 'click generic ext link',
  ClickGenericIntLink = 'click generic int link',
  ClickGenericMention = 'click generic mention',
  ClickUser = 'click user',
  ClickUserFollow = 'click user follow',
  ViewChannelFeed = 'view channel feed',
  ViewFeedHighlights = 'view feed highlights',
  ViewFeedHome = 'view feed home',
  ViewFeedNfts = 'view feed nfts',
  ViewNewCastsToast = 'view new casts toast',
}

// Active experiment layers in Statsig. A layer is a collection of related experiments.
// The idea is that this way users only fall into one experiment (and one group), and
// have no conflicts. See more: https://docs.statsig.com/layers
export enum StatsigLayer {
  _example = 'example', // Dummy, so the type system infers that this is a string enum
}

// Active params across all experiment layers. Experiments have parameters which are set
// in the UI. Since a user can only be in one experiment and group within a lyaer, we
// don't care which one. We only care about the parameter name that the experiment group
// has set.
export enum StatsigLayerParam {
  _example = 'example', // Dummy, so the type system infers that this is a string enum
}

export type StatsigGateName =
  | 'slim-frame-embeds'
  | 'composer-actions'
  | 'multi-step_actions'
  | 'rainbow-mwp'
  | 'frames_v2'
  | 'frames_home'
  | 'scheduled_casts'
  | 'invites_leaderboard'
  | 'dune_team'
  | 'starter_pack_feeds'
  | 'creator_labels'
  | 'earnings_page'
  | 'updated_verifications_scoring'
  | 'farcaster_kudos'
  | 'sent_from_earth'
  | 'mobile_debug_menu_access';

// Type of the value that can be sent with an event
export type StatsigEventValue = string | number | null | undefined;

// Type of the metadata that can be sent with an event
export type StatsigEventData = Record<string, string> | undefined;

// Type of the experimentation layer parameter value returned from Statsig
export type StatsigLayerValue = string | number | boolean;

export interface StatsigEvent {
  on?: string;
  channel?: string;
  castHash?: string;
  action?: StatsigAction;
  to?: string;
  url?: string;
  user?: string;
}

export const eventToPayload = (event: StatsigEvent): Record<string, string> => {
  // Excludes the action param
  return {
    ...(event.on && { on: event.on.toString() }),
    ...(event.channel && { feed: event.channel.toString() }),
    ...(event.castHash && { cast_hash: event.castHash }),
    ...(event.to && { to: event.to }),
    ...(event.url && { url: event.url }),
    ...(event.user && { user: event.user }),
  };
};
