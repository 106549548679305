import {
  ApiAbandonVideoUpload200Response,
  ApiAbandonVideoUploadRequestBody,
  ApiAddCastAction200Response,
  ApiAddCastActionRequestBody,
  ApiAddComposerAction200Response,
  ApiAddComposerActionRequestBody,
  ApiAddDirectCastKeysByAccount200Response,
  ApiAddDirectCastKeysByAccountRequestBody,
  ApiAddDirectCastUserKey200Response,
  ApiAddDirectCastUserKeyRequestBody,
  ApiAddDiscoveryApp200Response,
  ApiAddDiscoveryAppRequestBody,
  ApiAddDiscoveryFrame200Response,
  ApiAddDiscoveryFrameRequestBody,
  ApiAddFavoriteFeed200Response,
  ApiAddFavoriteFeedRequestBody,
  ApiAddFavoriteFrame200Response,
  ApiAddFavoriteFrameRequestBody,
  ApiAddFeaturedMint200Response,
  ApiAddFeaturedMintRequestBody,
  ApiAddMuteKeyword200Response,
  ApiAddMuteKeywordRequestBody,
  ApiAddUserUsername200Response,
  ApiAddUserUsernameRequestBody,
  ApiAdminAction200Response,
  ApiAdminActionRequestBody,
  ApiAdminAddChannelModerator200Response,
  ApiAdminAddChannelModeratorRequestBody,
  ApiAdminChangeChannelOwner200Response,
  ApiAdminChangeChannelOwnerRequestBody,
  ApiAdminUpdateChannelsAllThreads200Response,
  ApiAdminUpdateChannelsAllThreadsRequestBody,
  ApiAdminUpdateChannelThreads200Response,
  ApiAdminUpdateChannelThreadsRequestBody,
  ApiAllocateInvites200Response,
  ApiAllocateInvitesRequestBody,
  ApiAllowSponsoredRegistration200Response,
  ApiAllowSponsoredRegistrationRequestBody,
  ApiApproveRecovery200Response,
  ApiApproveRecoveryTransaction200Response,
  ApiApproveRecoveryTransactionRequestBody,
  ApiApproveSignedKeyRequest200Response,
  ApiApproveSignedKeyRequestRequestBody,
  ApiAssignQuestForUser200Response,
  ApiAssignQuestForUserRequestBody,
  ApiBanUserFromChannel200Response,
  ApiBanUserFromChannelRequestBody,
  ApiBookmarkCast200Response,
  ApiBookmarkCastRequestBody,
  ApiBoostCast200Response,
  ApiBoostCastRequestBody,
  ApiBuyWarpsCoinbaseCommerce200Response,
  ApiBuyWarpsCoinbaseCommerceInfo200Response,
  ApiBuyWarpsCoinbaseCommerceRequestBody,
  ApiCanGiftWarps200Response,
  ApiCanGiftWarpsQueryParams,
  ApiCheckSwapStatus200Response,
  ApiCheckSwapStatusQueryParams,
  ApiClaimQuestReward200Response,
  ApiClaimQuestRewardRequestBody,
  ApiClearJobQueue200Response,
  ApiClearJobQueueRequestBody,
  ApiCompleteCampaign200Response,
  ApiCompleteCampaignRequestBody,
  ApiCompleteMagicLink200Response,
  ApiCompleteMagicLinkRequestBody,
  ApiCompletePeerToPeerPayment200Response,
  ApiCompletePeerToPeerPaymentRequestBody,
  ApiCompletePhoneVerification200Response,
  ApiCompletePhoneVerificationRequestBody,
  ApiCompleteRegistration200Response,
  ApiCompleteRegistrationRequestBody,
  ApiConfirmAccountDeleteQueryParams,
  ApiConfirmTaxDocuments200Response,
  ApiConfirmTaxDocumentsRequestBody,
  ApiCreateApiKey200Response,
  ApiCreateApiKeyRequestBody,
  ApiCreateAuthToken200Response,
  ApiCreateAuthTokenRequestBody,
  ApiCreateCast201Response,
  ApiCreateCastLike200Response,
  ApiCreateCastLikeRequestBody,
  ApiCreateCastRequestBody,
  ApiCreateChannel200Response,
  ApiCreateChannelRequestBody,
  ApiCreateFeedFollow200Response,
  ApiCreateFeedFollowRequestBody,
  ApiCreateFollow200Response,
  ApiCreateFollowRequestBody,
  ApiCreateInvite200Response,
  ApiCreateInviteRequestBody,
  ApiCreateOnboarding200Response,
  ApiCreateOnboardingRequestBody,
  ApiCreatePoll200Response,
  ApiCreatePollRequestBody,
  ApiCreateRecast200Response,
  ApiCreateRecastRequestBody,
  ApiCreateRecovery200Response,
  ApiCreateRecoveryRequestBody,
  ApiCreateRemoteSiwfRequest200Response,
  ApiCreateRemoteSiwfRequestRequestBody,
  ApiCreateSignedKeyRequest200Response,
  ApiCreateSignedKeyRequestRequestBody,
  ApiCreateStarterPack200Response,
  ApiCreateStarterPackRequestBody,
  ApiCreateTotpSecret200Response,
  ApiCreateWarpcastSignedKeyRequest200Response,
  ApiCreateWarpcastSponsoredInvite200Response,
  ApiCreatorRewardsFrameWebhook200Response,
  ApiDeleteAccountVerification200Response,
  ApiDeleteAccountVerificationRequestBody,
  ApiDeleteApiKey200Response,
  ApiDeleteApiKeyRequestBody,
  ApiDeleteAuthToken200Response,
  ApiDeleteCampaign200Response,
  ApiDeleteCampaignQueryParams,
  ApiDeleteCast200Response,
  ApiDeleteCastLike200Response,
  ApiDeleteCastLikeRequestBody,
  ApiDeleteCastRequestBody,
  ApiDeleteComposerAction200Response,
  ApiDeleteComposerActionRequestBody,
  ApiDeleteContacts200Response,
  ApiDeleteDirectCastConversationReactionsV3RequestBody,
  ApiDeleteDirectCastConversationReactionsV3200Response,
  ApiDeleteDirectCastKeysByInbox200Response,
  ApiDeleteDirectCastKeysByInboxQueryParams,
  ApiDeleteDirectCastMessage200Response,
  ApiDeleteDirectCastMessageRequestBody,
  ApiDeleteDiscoveryApp200Response,
  ApiDeleteDiscoveryAppRequestBody,
  ApiDeleteDiscoveryFrame200Response,
  ApiDeleteDiscoveryFrameRequestBody,
  ApiDeleteFeaturedMint200Response,
  ApiDeleteFeaturedMintRequestBody,
  ApiDeleteFeedFollow200Response,
  ApiDeleteFeedFollowRequestBody,
  ApiDeleteFollow200Response,
  ApiDeleteFollowRequestBody,
  ApiDeleteQuestsForUser200Response,
  ApiDeleteQuestsForUserRequestBody,
  ApiDeleteRecast200Response,
  ApiDeleteRecastRequestBody,
  ApiDeleteStarterPack200Response,
  ApiDeleteStarterPackRequestBody,
  ApiDeleteSyncChannel200Response,
  ApiDeleteSyncChannelQueryParams,
  ApiDeleteUserCastAction200Response,
  ApiDeleteUserCastActionRequestBody,
  ApiDeleteVerification200Response,
  ApiDeleteVerificationRequestBody,
  ApiDenyRecoveryTransaction200Response,
  ApiDenyRecoveryTransactionRequestBody,
  ApiDevToolsFarcasterJsonQueryParams,
  ApiDevToolsGetOpenGraphMetadata200Response,
  ApiDevToolsGetOpenGraphMetadataQueryParams,
  ApiDevToolsGetTempAccountAssociation200Response,
  ApiDevToolsGetTempAccountAssociationQueryParams,
  ApiDevToolsMetaTagsQueryParams,
  ApiDevToolsRefreshDomainManifest200Response,
  ApiDevToolsRefreshDomainManifestRequestBody,
  ApiDevToolsRefreshOpenGraphMetadata200Response,
  ApiDevToolsRefreshOpenGraphMetadataRequestBody,
  ApiDevToolsRegisterDomain200Response,
  ApiDevToolsRegisterDomainRequestBody,
  ApiDevToolsStoreTempAccountAssociation200Response,
  ApiDevToolsStoreTempAccountAssociationRequestBody,
  ApiDevToolsUnregisterDomain200Response,
  ApiDevToolsUnregisterDomainRequestBody,
  ApiDisableChannelNotifications200Response,
  ApiDisableChannelNotificationsRequestBody,
  ApiDisableFrameNotifications200Response,
  ApiDisableFrameNotificationsRequestBody,
  ApiDisableLinkNotifications200Response,
  ApiDisableLinkNotificationsRequestBody,
  ApiDisableTotp200Response,
  ApiDiscardDraftCast200Response,
  ApiDiscardDraftCastRequestBody,
  ApiDiscoverActions200Response,
  ApiDiscoverActionsQueryParams,
  ApiDiscoverApps200Response,
  ApiDiscoverAppsQueryParams,
  ApiDiscoverChannels200Response,
  ApiDiscoverChannelsQueryParams,
  ApiDiscoverComposerActions200Response,
  ApiDiscoverComposerActionsQueryParams,
  ApiDiscoverFrames200Response,
  ApiDiscoverFramesQueryParams,
  ApiDismissNewUserFollowInstructions200Response,
  ApiDismissNewUserFollowsBanner200Response,
  ApiDismissSuggestedUsers200Response,
  ApiDismissSuggestedUsersRequestBody,
  ApiDownvoteCast200Response,
  ApiDownvoteCastRequestBody,
  ApiEnableCastAction200Response,
  ApiEnableCastActionRequestBody,
  ApiEnableChannelNotifications200Response,
  ApiEnableChannelNotificationsRequestBody,
  ApiEnableFrameNotifications200Response,
  ApiEnableFrameNotificationsRequestBody,
  ApiEnableLinkNotifications200Response,
  ApiEnableLinkNotificationsRequestBody,
  ApiExecuteWarpsTrade200Response,
  ApiFcBanUserFromChannel200Response,
  ApiFcBanUserFromChannelRequestBody,
  ApiFcBlockUser200Response,
  ApiFcBlockUserRequestBody,
  ApiFcDeleteGroupMembers200Response,
  ApiFcDeleteGroupMembersRequestBody,
  ApiFcDeleteMessage200Response,
  ApiFcDeleteMessageRequestBody,
  ApiFcFollowChannel200Response,
  ApiFcFollowChannelRequestBody,
  ApiFcGetAccountVerifications200Response,
  ApiFcGetAccountVerificationsQueryParams,
  ApiFcGetBlockedUsers200Response,
  ApiFcGetBlockedUsersQueryParams,
  ApiFcGetChannelBannedUsers200Response,
  ApiFcGetChannelBannedUsersQueryParams,
  ApiFcGetChannelEvents200Response,
  ApiFcGetChannelEventsQueryParams,
  ApiFcGetChannelInvites200Response,
  ApiFcGetChannelInvitesQueryParams,
  ApiFcGetChannelMembers200Response,
  ApiFcGetChannelMembersQueryParams,
  ApiFcGetChannelRestrictedUsers200Response,
  ApiFcGetChannelRestrictedUsersQueryParams,
  ApiFcGetConversation200Response,
  ApiFcGetConversationList200Response,
  ApiFcGetConversationListQueryParams,
  ApiFcGetConversationQueryParams,
  ApiFcGetGroup200Response,
  ApiFcGetGroupInvites200Response,
  ApiFcGetGroupInvitesQueryParams,
  ApiFcGetGroupList200Response,
  ApiFcGetGroupListQueryParams,
  ApiFcGetGroupMembers200Response,
  ApiFcGetGroupMembersQueryParams,
  ApiFcGetGroupQueryParams,
  ApiFcGetMessage200Response,
  ApiFcGetMessageList200Response,
  ApiFcGetMessageListQueryParams,
  ApiFcGetMessageQueryParams,
  ApiFcGetModeratedCasts200Response,
  ApiFcGetModeratedCastsQueryParams,
  ApiFcGetPrimaryAddressEvents200Response,
  ApiFcGetPrimaryAddressEventsQueryParams,
  ApiFcGetStarterPackUsers200Response,
  ApiFcGetStarterPackUsersQueryParams,
  ApiFcInviteUserToChannelRole200Response,
  ApiFcInviteUserToChannelRoleRequestBody,
  ApiFcModerateCast200Response,
  ApiFcModerateCastRequestBody,
  ApiFcPinCast200Response,
  ApiFcPinCastRequestBody,
  ApiFcPostConversation200Response,
  ApiFcPostConversationRequestBody,
  ApiFcPostGroup200Response,
  ApiFcPostGroupMembers200Response,
  ApiFcPostGroupMembersRequestBody,
  ApiFcPostGroupRequestBody,
  ApiFcPostMarkMentionsAsRead200Response,
  ApiFcPutConversation200Response,
  ApiFcPutConversationRequestBody,
  ApiFcPutGroup200Response,
  ApiFcPutGroupInvites200Response,
  ApiFcPutGroupInvitesRequestBody,
  ApiFcPutGroupRequestBody,
  ApiFcPutMessage200Response,
  ApiFcPutMessageRequestBody,
  ApiFcRemoveUserFromChannelRole200Response,
  ApiFcRemoveUserFromChannelRoleRequestBody,
  ApiFcRespondToChannelRoleInvite200Response,
  ApiFcRespondToChannelRoleInviteRequestBody,
  ApiFcUnbanUserFromChannel200Response,
  ApiFcUnbanUserFromChannelRequestBody,
  ApiFcUnblockUser200Response,
  ApiFcUnblockUserRequestBody,
  ApiFcUnfollowChannel200Response,
  ApiFcUnfollowChannelRequestBody,
  ApiFcUnpinCast200Response,
  ApiFcUnpinCastRequestBody,
  ApiFeaturedMintFrameWebhook200Response,
  ApiFindLocation200Response,
  ApiFindLocationQueryParams,
  ApiFinishInAppPurchase200Response,
  ApiFinishInAppPurchaseRequestBody,
  ApiFinishInAppPurchaseWithCustody200Response,
  ApiFinishInAppPurchaseWithCustodyRequestBody,
  ApiFollowAllStarterPackUsers200Response,
  ApiFollowAllStarterPackUsersRequestBody,
  ApiGenerateCastShareableImageQueryParams,
  ApiGenerateChannelImageQueryParams,
  ApiGenerateImageUploadUrl201Response,
  ApiGenerateInviteOpenGraphImageQueryParams,
  ApiGenerateOpenGraphImageQueryParams,
  ApiGenerateRegistrationHashes200Response,
  ApiGenerateRegistrationHashesRequestBody,
  ApiGenerateTotpToken200Response,
  ApiGenerateTotpTokenForEmail200Response,
  ApiGenerateTotpTokenForEmailRequestBody,
  ApiGenerateTotpTokenRequestBody,
  ApiGetAccountVerifications200Response,
  ApiGetActiveChannelStreak200Response,
  ApiGetActiveChannelStreakQueryParams,
  ApiGetAdminFeed200Response,
  ApiGetAdminFeedQueryParams,
  ApiGetAllChannelsPublic200Response,
  ApiGetAllRecentCasts200Response,
  ApiGetAllRecentCastsQueryParams,
  ApiGetAllStarterPacks200Response,
  ApiGetAllStarterPacksQueryParams,
  ApiGetAMA200Response,
  ApiGetAMAQueryParams,
  ApiGetApiKeys200Response,
  ApiGetAppBlockedUsers200Response,
  ApiGetAppsByAuthor200Response,
  ApiGetAppsByAuthorQueryParams,
  ApiGetAuthenticatedUser200Response,
  ApiGetAvailableWalletsToMintTo200Response,
  ApiGetBookmarkedCasts200Response,
  ApiGetBookmarkedCastsQueryParams,
  ApiGetCampaign200Response,
  ApiGetCampaignQueryParams,
  ApiGetCastLikes200Response,
  ApiGetCastLikesQueryParams,
  ApiGetCastQuotes200Response,
  ApiGetCastQuotesQueryParams,
  ApiGetCastReactions200Response,
  ApiGetCastReactionsQueryParams,
  ApiGetCastRecasters200Response,
  ApiGetCastRecastersQueryParams,
  ApiGetChannel200Response,
  ApiGetChannelBannedUsers200Response,
  ApiGetChannelBannedUsersQueryParams,
  ApiGetChannelCreationInfo200Response,
  ApiGetChannelDetails200Response,
  ApiGetChannelDetailsQueryParams,
  ApiGetChannelFollowers200Response,
  ApiGetChannelFollowersPublic200Response,
  ApiGetChannelFollowersPublicQueryParams,
  ApiGetChannelFollowersQueryParams,
  ApiGetChannelFollowersYouKnow200Response,
  ApiGetChannelFollowersYouKnowQueryParams,
  ApiGetChannelHosts200Response,
  ApiGetChannelHostsQueryParams,
  ApiGetChannelPublic200Response,
  ApiGetChannelPublicQueryParams,
  ApiGetChannelQueryParams,
  ApiGetChannelRecsForCast200Response,
  ApiGetChannelRecsForCastQueryParams,
  ApiGetChannelSettings200Response,
  ApiGetChannelSettingsQueryParams,
  ApiGetChannelTopCasters200Response,
  ApiGetChannelTopCastersQueryParams,
  ApiGetChannelUsers200Response,
  ApiGetChannelUsersForInvite200Response,
  ApiGetChannelUsersForInviteQueryParams,
  ApiGetChannelUsersForManagement200Response,
  ApiGetChannelUsersForManagementQueryParams,
  ApiGetChannelUsersQueryParams,
  ApiGetClientConfig200Response,
  ApiGetComposerAction200Response,
  ApiGetComposerActionQueryParams,
  ApiGetComposerChannelSuggestions200Response,
  ApiGetComposerChannelSuggestionsQueryParams,
  ApiGetConnectedAccounts200Response,
  ApiGetConnectedAccountsQueryParams,
  ApiGetContactsUsers200Response,
  ApiGetContactsUsersQueryParams,
  ApiGetConversationCastReplies200Response,
  ApiGetConversationCastRepliesObsolete200Response,
  ApiGetConversationCastRepliesObsoleteQueryParams,
  ApiGetConversationCastRepliesQueryParams,
  ApiGetCreatorRewardsEarningsHistory200Response,
  ApiGetCreatorRewardsEarningsHistoryQueryParams,
  ApiGetCreatorRewardsForUser200Response,
  ApiGetCreatorRewardsForUserQueryParams,
  ApiGetCreatorRewardsLeaderboard200Response,
  ApiGetCreatorRewardsLeaderboardQueryParams,
  ApiGetCreatorRewardsMetadata200Response,
  ApiGetCreatorRewardsPayoutEligibilityForUser200Response,
  ApiGetCreatorRewardsPayoutEligibilityForUserQueryParams,
  ApiGetCreatorRewardsPeriodSummary200Response,
  ApiGetCreatorRewardsPeriodSummaryQueryParams,
  ApiGetCreatorRewardsWinnerHistory200Response,
  ApiGetCreatorRewardsWinnerHistoryQueryParams,
  ApiGetCustodyAddress200Response,
  ApiGetCustodyAddressQueryParams,
  ApiGetDCAuthToken200Response,
  ApiGetDCAuthTokenQueryParams,
  ApiGetDCNonce200Response,
  ApiGetDelegateSigner200Response,
  ApiGetDirectCastConversation200Response,
  ApiGetDirectCastConversationMessages200Response,
  ApiGetDirectCastConversationMessagesQueryParams,
  ApiGetDirectCastConversationQueryParams,
  ApiGetDirectCastConversationReactionsV3QueryParams,
  ApiGetDirectCastConversationReactionsV3200Response,
  ApiGetDirectCastConversationRecentMessages200Response,
  ApiGetDirectCastConversationRecentMessagesQueryParams,
  ApiGetDirectCastConversationsV3QueryParams,
  ApiGetDirectCastConversationsV3200Response,
  ApiGetDirectCastConversationV3QueryParams,
  ApiGetDirectCastConversationV3200Response,
  ApiGetDirectCastGroupInvites200Response,
  ApiGetDirectCastGroupInvitesQueryParams,
  ApiGetDirectCastGroupInviteV3QueryParams,
  ApiGetDirectCastGroupInviteV3200Response,
  ApiGetDirectCastInbox200Response,
  ApiGetDirectCastInboxQueryParams,
  ApiGetDirectCastKeys200Response,
  ApiGetDirectCastKeysByAccount200Response,
  ApiGetDirectCastKeysByAccountQueryParams,
  ApiGetDirectCastKeysQueryParams,
  ApiGetDirectCastTTLs200Response,
  ApiGetDirectCastUsers200Response,
  ApiGetDirectCastUsersQueryParams,
  ApiGetDiscordAuthLink200Response,
  ApiGetDiscoveryApp200Response,
  ApiGetDiscoveryAppQueryParams,
  ApiGetDiscoveryFrame200Response,
  ApiGetDiscoveryFrameQueryParams,
  ApiGetDomainManifestState200Response,
  ApiGetDomainManifestStateQueryParams,
  ApiGetDraftCasts200Response,
  ApiGetDraftCastsQueryParams,
  ApiGetDraftCaststorms200Response,
  ApiGetDraftCaststormsQueryParams,
  ApiGetDynamicConfigs200Response,
  ApiGetFarcasterAction200Response,
  ApiGetFarcasterActionQueryParams,
  ApiGetFarcasterEarnings200Response,
  ApiGetFarcasterEarningsQueryParams,
  ApiGetFavoriteFrames200Response,
  ApiGetFavoriteFramesQueryParams,
  ApiGetFeaturedFrames200Response,
  ApiGetFeaturedFramesQueryParams,
  ApiGetFeaturedHeroApps200Response,
  ApiGetFeaturedMint200Response,
  ApiGetFeaturedMintQueryParams,
  ApiGetFeaturedMintTransaction200Response,
  ApiGetFeaturedMintTransactionQueryParams,
  ApiGetFeedItems200Response,
  ApiGetFeedItemsRequestBody,
  ApiGetFeedPageViews200Response,
  ApiGetFeedPageViewsQueryParams,
  ApiGetFeedSummaries200Response,
  ApiGetFname200Response,
  ApiGetFnameQueryParams,
  ApiGetFollowedUsersAffinityScore200Response,
  ApiGetFollowedUsersAffinityScoreQueryParams,
  ApiGetFollowers200Response,
  ApiGetFollowersQueryParams,
  ApiGetFollowersYouKnow200Response,
  ApiGetFollowersYouKnowQueryParams,
  ApiGetFollowing200Response,
  ApiGetFollowingQueryParams,
  ApiGetFrameBlocklist200Response,
  ApiGetFrameDetails200Response,
  ApiGetFrameDetailsQueryParams,
  ApiGetGeneralizedInAppMintsWarpsOffering200Response,
  ApiGetGeneralizedInAppMintsWarpsOfferingQueryParams,
  ApiGetGitHubAuthLink200Response,
  ApiGetGlobalFrameAnalytics200Response,
  ApiGetGlobalFrameAnalyticsQueryParams,
  ApiGetHealth200Response,
  ApiGetHighlightedChannels200Response,
  ApiGetHomeFeedGenerationHistory200Response,
  ApiGetHomeFeedGenerationHistoryQueryParams,
  ApiGetInAppMintsWarpsOffering200Response,
  ApiGetInAppMintsWarpsOfferingQueryParams,
  ApiGetInvite200Response,
  ApiGetInviteQueryParams,
  ApiGetInvitersLeaderboard200Response,
  ApiGetInvitesAvailable200Response,
  ApiGetInvitesViewed200Response,
  ApiGetInviteWithWarpsOffering200Response,
  ApiGetIsUserInvited200Response,
  ApiGetIsUserInvitedQueryParams,
  ApiGetKeyTransaction200Response,
  ApiGetKeyTransactionQueryParams,
  ApiGetLeastInteractedWithFollowing200Response,
  ApiGetLeastInteractedWithFollowingQueryParams,
  ApiGetLinkedInAuthLink200Response,
  ApiGetMintStatus200Response,
  ApiGetMintStatusQueryParams,
  ApiGetMintWithWarpsOffering200Response,
  ApiGetMintWithWarpsOfferingQueryParams,
  ApiGetMostActiveChannels200Response,
  ApiGetMostActiveChannelsQueryParams,
  ApiGetMutedKeyword200Response,
  ApiGetMutedKeywordQueryParams,
  ApiGetMutedKeywords200Response,
  ApiGetNotificationActorsInGroup200Response,
  ApiGetNotificationActorsInGroupQueryParams,
  ApiGetNotificationGroupsAdmin200Response,
  ApiGetNotificationGroupsAdminQueryParams,
  ApiGetNotificationsForTab200Response,
  ApiGetNotificationsForTabQueryParams,
  ApiGetNotificationsInGroup200Response,
  ApiGetNotificationsInGroupQueryParams,
  ApiGetOffering200Response,
  ApiGetOfferingQueryParams,
  ApiGetOnboardingInterests200Response,
  ApiGetOnboardingInterestsQueryParams,
  ApiGetOnboardingState200Response,
  ApiGetOnboardingStateAndAuthToken200Response,
  ApiGetOnboardingStateAndAuthTokenRequestBody,
  ApiGetOnchainAction200Response,
  ApiGetOnchainActionQueryParams,
  ApiGetPendingAdminReviews200Response,
  ApiGetPendingAdminReviewsQueryParams,
  ApiGetPhoneVerificationStatus200Response,
  ApiGetPollResults200Response,
  ApiGetPollResultsQueryParams,
  ApiGetPrimaryAddress200Response,
  ApiGetPrimaryAddressQueryParams,
  ApiGetProductCatalog200Response,
  ApiGetPublicDeveloperRewardsWinnerHistory200Response,
  ApiGetPublicDeveloperRewardsWinnerHistoryQueryParams,
  ApiGetQuest200Response,
  ApiGetQuestQueryParams,
  ApiGetQuests200Response,
  ApiGetRecentlyLaunchedFrames200Response,
  ApiGetRecentlyLaunchedFramesQueryParams,
  ApiGetRecoverHash200Response,
  ApiGetRecoverHashQueryParams,
  ApiGetRecovery200Response,
  ApiGetRecoveryAddress200Response,
  ApiGetRecoveryAddressChange200Response,
  ApiGetRecoveryAddressChangeHash200Response,
  ApiGetRecoveryAddressChangeHashQueryParams,
  ApiGetRecoveryAddressChangeQueryParams,
  ApiGetRecoveryQueryParams,
  ApiGetRemoteSiwfRequest200Response,
  ApiGetRemoteSiwfRequestQueryParams,
  ApiGetRentStorageOfferings200Response,
  ApiGetRewardsEarningsHistory200Response,
  ApiGetRewardsEarningsHistoryQueryParams,
  ApiGetRewardsLeaderboard200Response,
  ApiGetRewardsLeaderboardQueryParams,
  ApiGetRewardsMetadata200Response,
  ApiGetRewardsMetadataQueryParams,
  ApiGetRewardsPayoutEligibility200Response,
  ApiGetRewardsPeriodSummary200Response,
  ApiGetRewardsPeriodSummaryQueryParams,
  ApiGetRewardsScoresForUser200Response,
  ApiGetRewardsScoresForUserQueryParams,
  ApiGetRewardsWinnerHistory200Response,
  ApiGetRewardsWinnerHistoryQueryParams,
  ApiGetSignedKeyRequest200Response,
  ApiGetSignedKeyRequestQueryParams,
  ApiGetSigner200Response,
  ApiGetSignerQueryParams,
  ApiGetSignerRemoveHash200Response,
  ApiGetSignerRemoveHashQueryParams,
  ApiGetSigners200Response,
  ApiGetSignersMetadata200Response,
  ApiGetSignersQueryParams,
  ApiGetSiweNonce200Response,
  ApiGetStarterPack200Response,
  ApiGetStarterPackFeed200Response,
  ApiGetStarterPackFeedQueryParams,
  ApiGetStarterPackQueryParams,
  ApiGetStarterPacks200Response,
  ApiGetStarterPacksQueryParams,
  ApiGetStarterPackUsers200Response,
  ApiGetStarterPackUsersQueryParams,
  ApiGetStepUpMessage200Response,
  ApiGetStorageUtilization200Response,
  ApiGetSuggestedKudos200Response,
  ApiGetSuggestedKudosQueryParams,
  ApiGetSuggestedStarterPacks200Response,
  ApiGetSuggestedStarterPacksQueryParams,
  ApiGetSuggestedUsers200Response,
  ApiGetSuggestedUsersQueryParams,
  ApiGetSwapGaslessTokens200Response,
  ApiGetSwapGaslessTokensQueryParams,
  ApiGetSwapPrice200Response,
  ApiGetSwapPriceQueryParams,
  ApiGetSwapQuote200Response,
  ApiGetSwapQuoteQueryParams,
  ApiGetSwapToken200Response,
  ApiGetSwapTokenQueryParams,
  ApiGetSwapTokens200Response,
  ApiGetSwapTokensQueryParams,
  ApiGetSyncChannel200Response,
  ApiGetSyncChannelQueryParams,
  ApiGetThread200Response,
  ApiGetThreadQueryParams,
  ApiGetToken200Response,
  ApiGetTokenChart200Response,
  ApiGetTokenChartQueryParams,
  ApiGetTokenHolders200Response,
  ApiGetTokenHoldersQueryParams,
  ApiGetTokenLinks200Response,
  ApiGetTokenLinksQueryParams,
  ApiGetTokenQueryParams,
  ApiGetTopFrames200Response,
  ApiGetTopFramesQueryParams,
  ApiGetTotpEnabled200Response,
  ApiGetTotpEnabledForEmail200Response,
  ApiGetTotpEnabledForEmailQueryParams,
  ApiGetUnseen200Response,
  ApiGetUser200Response,
  ApiGetUserAppContext200Response,
  ApiGetUserByFID200Response,
  ApiGetUserByFIDQueryParams,
  ApiGetUserByUsername200Response,
  ApiGetUserByUsernameQueryParams,
  ApiGetUserByVerification200Response,
  ApiGetUserByVerificationQueryParams,
  ApiGetUserCast200Response,
  ApiGetUserCastableChannels200Response,
  ApiGetUserCastableChannelsQueryParams,
  ApiGetUserCastQueryParams,
  ApiGetUserCasts200Response,
  ApiGetUserCastsAndReplies200Response,
  ApiGetUserCastsAndRepliesQueryParams,
  ApiGetUserCastsQueryParams,
  ApiGetUserChannelPublic200Response,
  ApiGetUserChannelPublicQueryParams,
  ApiGetUserChannels200Response,
  ApiGetUserChannelsQueryParams,
  ApiGetUserFollowingChannelsPublic200Response,
  ApiGetUserFollowingChannelsPublicQueryParams,
  ApiGetUserLikedCasts200Response,
  ApiGetUserLikedCastsQueryParams,
  ApiGetUserPreferences200Response,
  ApiGetUserQueryParams,
  ApiGetUsersByLocation200Response,
  ApiGetUsersByLocationQueryParams,
  ApiGetUserThreadCasts200Response,
  ApiGetUserThreadCastsQueryParams,
  ApiGetUserThreadHiddenReplies200Response,
  ApiGetUserThreadHiddenRepliesQueryParams,
  ApiGetUserUsernames200Response,
  ApiGetUserViewedCasts200Response,
  ApiGetUserViewedCastsQueryParams,
  ApiGetVerifications200Response,
  ApiGetVerificationsQueryParams,
  ApiGetVideoState200Response,
  ApiGetVideoStateQueryParams,
  ApiGetWalletActivity200Response,
  ApiGetWalletActivityQueryParams,
  ApiGetWalletChainNativeAsset200Response,
  ApiGetWalletChainNativeAssetQueryParams,
  ApiGetWalletNftCollections200Response,
  ApiGetWalletNftCollectionsQueryParams,
  ApiGetWalletNfts200Response,
  ApiGetWalletNftsQueryParams,
  ApiGetWalletPortfolio200Response,
  ApiGetWalletPortfolioQueryParams,
  ApiGetWalletPositions200Response,
  ApiGetWalletPositionsQueryParams,
  ApiGetWarpcastSponsoredInvites200Response,
  ApiGetWarpsBalance200Response,
  ApiGetWarpsBalanceQueryParams,
  ApiGetWarpsOffering200Response,
  ApiGetWarpsOfferingQueryParams,
  ApiGetWarpsTradeStatus200Response,
  ApiGetWarpTransactions200Response,
  ApiGetWarpTransactionsQueryParams,
  ApiGetXAuthLink200Response,
  ApiGiftWarps200Response,
  ApiGiftWarpsRequestBody,
  ApiGlobalSearchForMessages200Response,
  ApiGlobalSearchForMessagesQueryParams,
  ApiIngestNotificationFeedback200Response,
  ApiIngestNotificationFeedbackRequestBody,
  ApiInitiateMagicLink200Response,
  ApiInitiateMagicLinkRequestBody,
  ApiInitiateRecovery200Response,
  ApiInitiateRecoveryRequestBody,
  ApiInsertFeaturedApp200Response,
  ApiInsertFeaturedAppRequestBody,
  ApiInviteChannelUserToRole200Response,
  ApiInviteChannelUserToRoleRequestBody,
  ApiJoinChannelViaCode200Response,
  ApiJoinChannelViaCodeRequestBody,
  ApiLimitVisibility200Response,
  ApiLimitVisibilityRequestBody,
  ApiLookupOnboardingState200Response,
  ApiLookupOnboardingStateQueryParams,
  ApiManuallyMarkConversationUnread200Response,
  ApiManuallyMarkConversationUnreadRequestBody,
  ApiMarkAllNotificationsRead200Response,
  ApiMarkAllTabNotificationsSeen200Response,
  ApiMarkAllTabNotificationsSeenRequestBody,
  ApiMarkAllWarpTransactionsRead200Response,
  ApiMarkDirectCastKeyAsDead200Response,
  ApiMarkDirectCastKeyAsDeadRequestBody,
  ApiMarkNudgedForContacts200Response,
  ApiMarkNudgedForInterests200Response,
  ApiMarkNudgedForPushNotifications200Response,
  ApiMarkPromptedFor200Response,
  ApiMarkPromptedForRequestBody,
  ApiMarkSyncChannelMessageRead200Response,
  ApiMarkSyncChannelMessageReadRequestBody,
  ApiMarkVerificationsStart200Response,
  ApiMint200Response,
  ApiMintRequestBody,
  ApiNotifyUsersAboutCampaign200Response,
  ApiNotifyUsersAboutCampaignRequestBody,
  ApiPayWarpsAndConnectApp200Response,
  ApiPayWarpsAndConnectAppRequestBody,
  ApiPayWarpsAndDisconnectApp200Response,
  ApiPayWarpsAndDisconnectAppRequestBody,
  ApiPinCastOnUserProfile200Response,
  ApiPinCastOnUserProfileRequestBody,
  ApiPinDirectCastConversation200Response,
  ApiPinDirectCastConversationRequestBody,
  ApiPinDirectCastMessage200Response,
  ApiPinDirectCastMessageRequestBody,
  ApiPostCastAction200Response,
  ApiPostCastActionRequestBody,
  ApiPostComposerAction200Response,
  ApiPostComposerActionRequestBody,
  ApiPostDirectCastAcceptGroupInviteV3RequestBody,
  ApiPostDirectCastAcceptGroupInviteV3200Response,
  ApiPostDirectCastConversationCategorizationBulkV3RequestBody,
  ApiPostDirectCastConversationCategorizationBulkV3200Response,
  ApiPostDirectCastConversationCategorizationV3RequestBody,
  ApiPostDirectCastConversationCategorizationV3200Response,
  ApiPostDirectCastConversationMessageTTL200Response,
  ApiPostDirectCastConversationMessageTTLRequestBody,
  ApiPostDirectCastConversationNotificationsV3RequestBody,
  ApiPostDirectCastConversationNotificationsV3200Response,
  ApiPostDirectCastDeclineGroupInviteV3RequestBody,
  ApiPostDirectCastDeclineGroupInviteV3200Response,
  ApiPostDirectCastGroupMembershipV3RequestBody,
  ApiPostDirectCastGroupMembershipV3200Response,
  ApiPostDirectCastGroupNameV3RequestBody,
  ApiPostDirectCastGroupNameV3200Response,
  ApiPostDirectCastGroupPhotoUrlV3RequestBody,
  ApiPostDirectCastGroupPhotoUrlV3200Response,
  ApiPostDirectCastReadV3RequestBody,
  ApiPostDirectCastReadV3200Response,
  ApiPostFrameAction200Response,
  ApiPostFrameActionRequestBody,
  ApiPostFrameJSON200Response,
  ApiPostFrameJSONRequestBody,
  ApiPostFrameRedirect200Response,
  ApiPostFrameRedirectRequestBody,
  ApiPostFrameTransaction200Response,
  ApiPostFrameTransactionRequestBody,
  ApiPostMiniAppTransaction200Response,
  ApiPostMiniAppTransactionRequestBody,
  ApiPostStepUpMessage200Response,
  ApiPostStepUpMessageRequestBody,
  ApiPrepareVideoUpload200Response,
  ApiPrepareVideoUploadRequestBody,
  ApiProcessCastAttachments200Response,
  ApiProcessCastAttachmentsRequestBody,
  ApiProcessDirectCastMessageMetadata200Response,
  ApiProcessDirectCastMessageMetadataRequestBody,
  ApiPublicGetPrimaryAddress200Response,
  ApiPublicGetPrimaryAddresses200Response,
  ApiPublicGetPrimaryAddressesQueryParams,
  ApiPublicGetPrimaryAddressQueryParams,
  ApiPutDirectCastConversationReactionsV3RequestBody,
  ApiPutDirectCastConversationReactionsV3200Response,
  ApiPutDirectCastGroupInviteV3RequestBody,
  ApiPutDirectCastGroupInviteV3200Response,
  ApiPutDirectCastGroupV3RequestBody,
  ApiPutDirectCastGroupV3200Response,
  ApiPutDirectCastV3RequestBody,
  ApiPutDirectCastV3200Response,
  ApiPutExtSendDirectCast200Response,
  ApiPutExtSendDirectCastRequestBody,
  ApiPutFrameEvent200Response,
  ApiPutFrameEventRequestBody,
  ApiPutPrimaryVerification200Response,
  ApiPutPrimaryVerificationRequestBody,
  ApiPutVerification200Response,
  ApiPutVerificationRequestBody,
  ApiPutWarpcastWalletAddress200Response,
  ApiPutWarpcastWalletAddressRequestBody,
  ApiReceiveAppStoreServerNotification200Response,
  ApiReceiveAppStoreServerNotificationRequestBody,
  ApiReceiveAppStoreServerNotificationSandbox200Response,
  ApiReceiveAppStoreServerNotificationSandboxRequestBody,
  ApiReceiveCoinbaseCommerceWebhookEvent200Response,
  ApiRecommendedChannels200Response,
  ApiRecommendedChannelsQueryParams,
  ApiRecordAnalyticsEvents200Response,
  ApiRecordAnalyticsEventsRequestBody,
  ApiRecordCastFeedback200Response,
  ApiRecordCastFeedbackRequestBody,
  ApiRecordPollVote200Response,
  ApiRecordPollVoteRequestBody,
  ApiRecordWalletTransaction200Response,
  ApiRecordWalletTransactionRequestBody,
  ApiRedirectToLinkQueryParams,
  ApiRedirectToLinkV2QueryParams,
  ApiRefreshDomainManifestState200Response,
  ApiRefreshDomainManifestStateRequestBody,
  ApiRegisterDevice200Response,
  ApiRegisterDeviceRequestBody,
  ApiRegisterFid200Response,
  ApiRegisterFidRequestBody,
  ApiRejectRecovery200Response,
  ApiRemoveCastBookmark200Response,
  ApiRemoveCastBookmarkRequestBody,
  ApiRemoveCastBoost200Response,
  ApiRemoveCastBoostRequestBody,
  ApiRemoveChannelUserFromRole200Response,
  ApiRemoveChannelUserFromRoleRequestBody,
  ApiRemoveConnectedAccount200Response,
  ApiRemoveConnectedAccountRequestBody,
  ApiRemoveFavoriteFeed200Response,
  ApiRemoveFavoriteFeedRequestBody,
  ApiRemoveFavoriteFrame200Response,
  ApiRemoveFavoriteFrameRequestBody,
  ApiRemoveFeaturedApp200Response,
  ApiRemoveFeaturedAppRequestBody,
  ApiRemoveMuteKeyword200Response,
  ApiRemoveMuteKeywordRequestBody,
  ApiRemovePhoneVerificationForUser200Response,
  ApiRemovePhoneVerificationForUserRequestBody,
  ApiRemoveSigner200Response,
  ApiRemoveSignerRequestBody,
  ApiRemoveVisibilityRestrictions200Response,
  ApiRemoveVisibilityRestrictionsRequestBody,
  ApiRentStorage201Response,
  ApiRentStorageRequestBody,
  ApiRentTransactionDataQueryParams,
  ApiReportCast200Response,
  ApiReportCastRequestBody,
  ApiReportFrameTransaction200Response,
  ApiReportFrameTransactionRequestBody,
  ApiReportProfileActivity200Response,
  ApiReportProfileActivityRequestBody,
  ApiReportUser200Response,
  ApiReportUserRequestBody,
  ApiRequestAccountDelete200Response,
  ApiResetChannelInviteCode200Response,
  ApiResetChannelInviteCodeRequestBody,
  ApiResetDynamicConfig200Response,
  ApiResetDynamicConfigRequestBody,
  ApiResetOnboardingState200Response,
  ApiResetOnboardingStateRequestBody,
  ApiResetToNewUserExperience200Response,
  ApiRespondToChannelInvite200Response,
  ApiRespondToChannelInviteRequestBody,
  ApiRevokeApiKey200Response,
  ApiRevokeApiKeyRequestBody,
  ApiScrapeContractAddress200Response,
  ApiScrapeContractAddressQueryParams,
  ApiScrapeEmbed200Response,
  ApiScrapeEmbedRequestBody,
  ApiSearchActions200Response,
  ApiSearchActionsQueryParams,
  ApiSearchCasts200Response,
  ApiSearchCastsQueryParams,
  ApiSearchChannels200Response,
  ApiSearchChannelsQueryParams,
  ApiSearchComposerActions200Response,
  ApiSearchComposerActionsQueryParams,
  ApiSearchDirectCastInbox200Response,
  ApiSearchDirectCastInboxQueryParams,
  ApiSearchDirectCasts200Response,
  ApiSearchDirectCastsQueryParams,
  ApiSearchSummary200Response,
  ApiSearchSummaryQueryParams,
  ApiSearchUsers200Response,
  ApiSearchUsersForStarterPacks200Response,
  ApiSearchUsersForStarterPacksQueryParams,
  ApiSearchUsersQueryParams,
  ApiSearchWalletSendTargets200Response,
  ApiSearchWalletSendTargetsQueryParams,
  ApiSendBuyWarpsInfoEmail200Response,
  ApiSendConnectAddressLinkEmail200Response,
  ApiSendFrameNotification200Response,
  ApiSendFrameNotificationRequestBody,
  ApiSendPushNotification200Response,
  ApiSendPushNotificationRequestBody,
  ApiSendVerificationEmail200Response,
  ApiSendVerificationEmailRequestBody,
  ApiSendWarps200Response,
  ApiSendWarpsRequestBody,
  ApiSetAppBlockedUsers200Response,
  ApiSetAppBlockedUsersRequestBody,
  ApiSetBulkAutoUserQuality200Response,
  ApiSetBulkAutoUserQualityRequestBody,
  ApiSetBulkUserQuality200Response,
  ApiSetBulkUserQualityRequestBody,
  ApiSetChannelDistribution200Response,
  ApiSetChannelDistributionRequestBody,
  ApiSetContactsDeviceState200Response,
  ApiSetContactsDeviceStateRequestBody,
  ApiSetCreatorLabel200Response,
  ApiSetCreatorLabelRequestBody,
  ApiSetDynamicConfig200Response,
  ApiSetDynamicConfigRequestBody,
  ApiSetFavoriteFeedPosition200Response,
  ApiSetFavoriteFeedPositionRequestBody,
  ApiSetFeedSeen200Response,
  ApiSetFeedSeenRequestBody,
  ApiSetInvitesViewed200Response,
  ApiSetLastCheckedTimestamp200Response,
  ApiSetLowQualityUserBadness200Response,
  ApiSetLowQualityUserBadnessRequestBody,
  ApiSetSuggestedUsersAsSeen200Response,
  ApiSetSuggestedUsersAsSeenRequestBody,
  ApiSetTurnstileChallengeState200Response,
  ApiSetTurnstileChallengeStateRequestBody,
  ApiSetupAdvancedProtection200Response,
  ApiSetupAdvancedProtectionRequestBody,
  ApiSetUserCastActionPosition200Response,
  ApiSetUserCastActionPositionRequestBody,
  ApiSetUserPreferences200Response,
  ApiSetUserPreferencesRequestBody,
  ApiSetUserQuality200Response,
  ApiSetUserQualityRequestBody,
  ApiSetUserReferrer200Response,
  ApiSetUserReferrerRequestBody,
  ApiSetUserUsername200Response,
  ApiSetUserUsernameRequestBody,
  ApiShareCast200Response,
  ApiShareCastQueryParams,
  ApiSignInWithFarcaster200Response,
  ApiSignInWithFarcasterRequestBody,
  ApiSignRecoveryTransactionHashQueryParams,
  ApiSignupForInvite200Response,
  ApiSignupForInviteRequestBody,
  ApiSimulateCreateSignedKeyRequest200Response,
  ApiSimulateCreateSignedKeyRequestRequestBody,
  ApiSimulateRegisterFid200Response,
  ApiSimulateRegisterFidRequestBody,
  ApiSimulateRemoveSignedKeyRequest200Response,
  ApiSimulateRemoveSignedKeyRequestRequestBody,
  ApiSimulateRentStorage200Response,
  ApiSimulateRentStorageQueryParams,
  ApiSkipConnectAddress200Response,
  ApiSkipPhone200Response,
  ApiSkipPhoneRequestBody,
  ApiStartChannelStreak200Response,
  ApiStartChannelStreakRequestBody,
  ApiStartInAppPurchase200Response,
  ApiStartInAppPurchaseRequestBody,
  ApiStartInAppPurchaseWithCustody200Response,
  ApiStartInAppPurchaseWithCustodyRequestBody,
  ApiStartPhoneVerification200Response,
  ApiStartPhoneVerificationRequestBody,
  ApiStartVerification200Response,
  ApiStopActiveChannelStreaks200Response,
  ApiStoreDraftCast200Response,
  ApiStoreDraftCastRequestBody,
  ApiStoreDraftCaststorm200Response,
  ApiStoreDraftCaststormRequestBody,
  ApiSubmitSelectedOnboardingInterests200Response,
  ApiSubmitSelectedOnboardingInterestsRequestBody,
  ApiSubmitSignedMessageData200Response,
  ApiSubmitSignedMessageDataRequestBody,
  ApiTip10WarpsActionRequestBody,
  ApiTipCast200Response,
  ApiTipCastRequestBody,
  ApiUnbanUserFromChannel200Response,
  ApiUnbanUserFromChannelRequestBody,
  ApiUnfollowLeastInteractedWithFollowing200Response,
  ApiUnpinCastOnUserProfile200Response,
  ApiUnpinCastOnUserProfileRequestBody,
  ApiUnpinDirectCastConversation200Response,
  ApiUnpinDirectCastConversationRequestBody,
  ApiUnpinDirectCastMessage200Response,
  ApiUnpinDirectCastMessageRequestBody,
  ApiUnregisterDevice200Response,
  ApiUnregisterDeviceRequestBody,
  ApiUnsubscribeEmailOneClickQueryParams,
  ApiUnsubscribeEmailQueryParams,
  ApiUnsubscribeFidOneClickQueryParams,
  ApiUnsubscribeFidQueryParams,
  ApiUpdateChannel200Response,
  ApiUpdateChannelRequestBody,
  ApiUpdateDirectCastGroupPreferences200Response,
  ApiUpdateDirectCastGroupPreferencesRequestBody,
  ApiUpdateEmail200Response,
  ApiUpdateEmailRequestBody,
  ApiUpdateFavoriteFrame200Response,
  ApiUpdateFavoriteFrameRequestBody,
  ApiUpdateFeaturedApp200Response,
  ApiUpdateFeaturedAppRequestBody,
  ApiUpdateJobQueue200Response,
  ApiUpdateJobQueueRequestBody,
  ApiUpdatePaidInvite200Response,
  ApiUpdatePaidInviteRequestBody,
  ApiUpdatePendingAdminReview200Response,
  ApiUpdatePendingAdminReviewRequestBody,
  ApiUpdateRecoveryAddress200Response,
  ApiUpdateRecoveryAddressRequestBody,
  ApiUpdateRemoteSiwfRequest200Response,
  ApiUpdateRemoteSiwfRequestRequestBody,
  ApiUpdateStarterPack200Response,
  ApiUpdateStarterPackRequestBody,
  ApiUpdateSyncChannel200Response,
  ApiUpdateSyncChannelRequestBody,
  ApiUpdateUser200Response,
  ApiUpdateUserLocation200Response,
  ApiUpdateUserLocationRequestBody,
  ApiUpdateUserRequestBody,
  ApiUploadContacts200Response,
  ApiUploadContactsRequestBody,
  ApiUploadVideoWithTusQueryParams,
  ApiUpsertCampaign200Response,
  ApiUpsertCampaignRequestBody,
  ApiValidateApiKey200Response,
  ApiValidateComposerAction200Response,
  ApiValidateComposerActionRequestBody,
  ApiValidateDCAuthToken200Response,
  ApiValidateFrameEmbed200Response,
  ApiValidateFrameEmbedRequestBody,
  ApiValidateFrameEmbedV2RequestBody,
  ApiValidateFrameEmbedV2200Response,
  ApiValidateNewChannelKey200Response,
  ApiValidateNewChannelKeyQueryParams,
  ApiVerifyEmailQueryParams,
  ApiVerifyTotpCode200Response,
  ApiVerifyTotpCodeForEmail200Response,
  ApiVerifyTotpCodeForEmailRequestBody,
  ApiVerifyTotpCodeRequestBody,
  ApiWalletEvmScanAction200Response,
  ApiWalletEvmScanActionRequestBody,
  ApiWalletResource200Response,
  ApiWalletResourceRequestBody,
  ApiWalletSendSuggestions200Response,
  RequestHeaders,
} from '../types';
import { AbstractFarcasterApiClient } from './AbstractFarcasterApiClient';

export class FarcasterApiClient extends AbstractFarcasterApiClient {
  /**
   * Accept or decline an outstanding channel member or moderator invite
   */
  fcRespondToChannelRoleInvite(
    body: ApiFcRespondToChannelRoleInviteRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiFcRespondToChannelRoleInvite200Response>(
      '/fc/channel-invites',
      {
        headers,
        timeout,
        endpointName: 'fcRespondToChannelRoleInvite',
        body,
      },
    );
  }

  /**
   * Accept or decline an outstanding channel member or moderator invite
   */
  respondToChannelInvite(
    body: ApiRespondToChannelInviteRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiRespondToChannelInvite200Response>(
      '/v1/manage-channel-users',
      {
        headers,
        timeout,
        endpointName: 'respondToChannelInvite',
        body,
      },
    );
  }

  /**
   * Accepts an invite to a direct cast group.
   */
  postDirectCastAcceptGroupInviteV3(
    body: ApiPostDirectCastAcceptGroupInviteV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastAcceptGroupInviteV3200Response>(
      '/v2/direct-cast-accept-group-invite',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastAcceptGroupInviteV3',
        body,
      },
    );
  }

  /**
   * Add a keyword to a user's mute list
   */
  addMuteKeyword(
    body: ApiAddMuteKeywordRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAddMuteKeyword200Response>('/v2/mute-keyword', {
      headers,
      timeout,
      endpointName: 'addMuteKeyword',
      body,
    });
  }

  /**
   * Add a public key for direct cast conversations (deprecated).
   */
  addDirectCastUserKey(
    body: ApiAddDirectCastUserKeyRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiAddDirectCastUserKey200Response>(
      '/v2/direct-cast-keys',
      {
        headers,
        timeout,
        endpointName: 'addDirectCastUserKey',
        body,
      },
    );
  }

  /**
   * Add a public key for direct cast conversations. (deprecated)
   */
  addDirectCastKeysByAccount(
    body: ApiAddDirectCastKeysByAccountRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiAddDirectCastKeysByAccount200Response>(
      '/v2/direct-cast-device-keys',
      {
        headers,
        timeout,
        endpointName: 'addDirectCastKeysByAccount',
        body,
      },
    );
  }

  /**
   * Add a usename to the authenticated user
   */
  addUserUsername(
    body: ApiAddUserUsernameRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAddUserUsername200Response>('/v2/user-usernames', {
      headers,
      timeout,
      endpointName: 'addUserUsername',
      body,
    });
  }

  /**
   * Adds an action to a users actions
   */
  addCastAction(
    body: ApiAddCastActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAddCastAction200Response>('/v2/add-cast-action', {
      headers,
      timeout,
      endpointName: 'addCastAction',
      body,
    });
  }

  /**
   * Allocate invite to user.
   */
  allocateInvites(
    body: ApiAllocateInvitesRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiAllocateInvites200Response>('/v2/invites-available', {
      headers,
      timeout,
      endpointName: 'allocateInvites',
      body,
    });
  }

  /**
   * Allowlist an email for sponsored registration
   */
  allowSponsoredRegistration(
    body: ApiAllowSponsoredRegistrationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiAllowSponsoredRegistration200Response>(
      '/v2/allow-sponsored-registration',
      {
        headers,
        timeout,
        endpointName: 'allowSponsoredRegistration',
        body,
      },
    );
  }

  /**
   * Allows admins to delete campaigns
   */
  deleteCampaign(
    params: ApiDeleteCampaignQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteCampaign200Response>('/v2/campaign-admin', {
      headers,
      timeout,
      endpointName: 'deleteCampaign',
      params,
    });
  }

  /**
   * Allows admins to fetch all or a single notification for a user, used for,  * troubleshooting issues
   */
  getNotificationGroupsAdmin(
    params: ApiGetNotificationGroupsAdminQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetNotificationGroupsAdmin200Response>(
      '/v2/notification-groups-admin',
      {
        headers,
        timeout,
        endpointName: 'getNotificationGroupsAdmin',
        params,
      },
    );
  }

  /**
   * Allows admins to pin a message in conversation.
   */
  pinDirectCastMessage(
    body: ApiPinDirectCastMessageRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPinDirectCastMessage200Response>(
      '/v2/direct-cast-pin-message',
      {
        headers,
        timeout,
        endpointName: 'pinDirectCastMessage',
        body,
      },
    );
  }

  /**
   * Allows admins to unpint a message in conversation.
   */
  unpinDirectCastMessage(
    body: ApiUnpinDirectCastMessageRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiUnpinDirectCastMessage200Response>(
      '/v2/direct-cast-pin-message',
      {
        headers,
        timeout,
        endpointName: 'unpinDirectCastMessage',
        body,
      },
    );
  }

  /**
   * Allows admins to upsert campaigns
   */
  upsertCampaign(
    body: ApiUpsertCampaignRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiUpsertCampaign200Response>('/v2/campaign-admin', {
      headers,
      timeout,
      endpointName: 'upsertCampaign',
      body,
    });
  }

  /**
   * Allows user pay in warps and connect app (create a signer).
   */
  payWarpsAndConnectApp(
    body: ApiPayWarpsAndConnectAppRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPayWarpsAndConnectApp200Response>(
      '/v2/pay-warps-and-connect-app',
      {
        headers,
        timeout,
        endpointName: 'payWarpsAndConnectApp',
        body,
      },
    );
  }

  /**
   * Allows user pay in warps and disconnect app (create a signer).
   */
  payWarpsAndDisconnectApp(
    body: ApiPayWarpsAndDisconnectAppRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPayWarpsAndDisconnectApp200Response>(
      '/v2/pay-warps-and-disconnect-app',
      {
        headers,
        timeout,
        endpointName: 'payWarpsAndDisconnectApp',
        body,
      },
    );
  }

  /**
   * Allows user pay in warps and mint an asset.
   */
  mint(
    body: ApiMintRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiMint200Response>('/v2/mint', {
      headers,
      timeout,
      endpointName: 'mint',
      body,
    });
  }

  /**
   * Allows users to manually mark a conversation unread.
   */
  manuallyMarkConversationUnread(
    body: ApiManuallyMarkConversationUnreadRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiManuallyMarkConversationUnread200Response>(
      '/v2/direct-cast-manually-mark-unread',
      {
        headers,
        timeout,
        endpointName: 'manuallyMarkConversationUnread',
        body,
      },
    );
  }

  /**
   * Alters a direct cast conversation categorization.
   */
  postDirectCastConversationCategorizationV3(
    body: ApiPostDirectCastConversationCategorizationV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastConversationCategorizationV3200Response>(
      '/v2/direct-cast-conversation-categorization',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastConversationCategorizationV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast conversation's message ttl.
   */
  postDirectCastConversationMessageTTL(
    body: ApiPostDirectCastConversationMessageTTLRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastConversationMessageTTL200Response>(
      '/v2/direct-cast-conversation-message-ttl',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastConversationMessageTTL',
        body,
      },
    );
  }

  /**
   * Alters a direct cast conversation's notification behavior.
   */
  postDirectCastConversationNotificationsV3(
    body: ApiPostDirectCastConversationNotificationsV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastConversationNotificationsV3200Response>(
      '/v2/direct-cast-conversation-notifications',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastConversationNotificationsV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast conversations categorization in bulk.
   */
  postDirectCastConversationCategorizationBulkV3(
    body: ApiPostDirectCastConversationCategorizationBulkV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastConversationCategorizationBulkV3200Response>(
      '/v2/direct-cast-conversation-categorization-all',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastConversationCategorizationBulkV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast group's membership.
   */
  postDirectCastGroupMembershipV3(
    body: ApiPostDirectCastGroupMembershipV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastGroupMembershipV3200Response>(
      '/v2/direct-cast-group-membership',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastGroupMembershipV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast group's name.
   */
  postDirectCastGroupNameV3(
    body: ApiPostDirectCastGroupNameV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastGroupNameV3200Response>(
      '/v2/direct-cast-group',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastGroupNameV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast group's photo url.
   */
  postDirectCastGroupPhotoUrlV3(
    body: ApiPostDirectCastGroupPhotoUrlV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastGroupPhotoUrlV3200Response>(
      '/v2/direct-cast-group-photo',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastGroupPhotoUrlV3',
        body,
      },
    );
  }

  /**
   * Applies a reaction to the specified message.
   */
  putDirectCastConversationReactionsV3(
    body: ApiPutDirectCastConversationReactionsV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPutDirectCastConversationReactionsV3200Response>(
      '/v2/direct-cast-message-reaction',
      {
        headers,
        timeout,
        endpointName: 'putDirectCastConversationReactionsV3',
        body,
      },
    );
  }

  /**
   * Approve a recovery
   */
  approveRecovery({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiApproveRecovery200Response>('/v2/approve-recovery', {
      headers,
      timeout,
      endpointName: 'approveRecovery',
      body: {},
    });
  }

  /**
   * Approve a signed key request.
   */
  approveSignedKeyRequest(
    body: ApiApproveSignedKeyRequestRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiApproveSignedKeyRequest200Response>(
      '/v2/approve-signed-key-requests',
      {
        headers,
        timeout,
        endpointName: 'approveSignedKeyRequest',
        body,
      },
    );
  }

  /**
   * Approve or reject a pending admin review
   */
  updatePendingAdminReview(
    body: ApiUpdatePendingAdminReviewRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiUpdatePendingAdminReview200Response>(
      '/v2/pending-admin-reviews',
      {
        headers,
        timeout,
        endpointName: 'updatePendingAdminReview',
        body,
      },
    );
  }

  /**
   * Assign a quest to a user
   */
  assignQuestForUser(
    body: ApiAssignQuestForUserRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAssignQuestForUser200Response>(
      '/v2/quests/debug/quests',
      {
        headers,
        timeout,
        endpointName: 'assignQuestForUser',
        body,
      },
    );
  }

  /**
   * Ban a user from a channel
   */
  banUserFromChannel(
    body: ApiBanUserFromChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiBanUserFromChannel200Response>(
      '/v1/channel-banned-users',
      {
        headers,
        timeout,
        endpointName: 'banUserFromChannel',
        body,
      },
    );
  }

  /**
   * Ban a user from a channel
   */
  fcBanUserFromChannel(
    body: ApiFcBanUserFromChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFcBanUserFromChannel200Response>('/fc/channel-bans', {
      headers,
      timeout,
      endpointName: 'fcBanUserFromChannel',
      body,
    });
  }

  /**
   * Block a user
   */
  fcBlockUser(
    body: ApiFcBlockUserRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFcBlockUser200Response>('/fc/blocked-users', {
      headers,
      timeout,
      endpointName: 'fcBlockUser',
      body,
    });
  }

  /**
   * Block users from using the app
   */
  setAppBlockedUsers(
    body: ApiSetAppBlockedUsersRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetAppBlockedUsers200Response>('/v1/app-blocked-users', {
      headers,
      timeout,
      endpointName: 'setAppBlockedUsers',
      body,
    });
  }

  /**
   * Bookmark a cast.
   */
  bookmarkCast(
    body: ApiBookmarkCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiBookmarkCast200Response>('/v2/bookmarked-casts', {
      headers,
      timeout,
      endpointName: 'bookmarkCast',
      body,
    });
  }

  /**
   * Boost a cast in a channel
   */
  boostCast(
    body: ApiBoostCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiBoostCast200Response>('/v2/boost-cast', {
      headers,
      timeout,
      endpointName: 'boostCast',
      body,
    });
  }

  /**
   * Browse different lists of actions
   */
  discoverActions(
    params: ApiDiscoverActionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiDiscoverActions200Response>(
      '/v2/discover-actions',
      {
        headers,
        timeout,
        endpointName: 'discoverActions',
        params,
      },
    );
  }

  /**
   * Browse different lists of actions
   */
  discoverComposerActions(
    params: ApiDiscoverComposerActionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiDiscoverComposerActions200Response>(
      '/v2/discover-composer-actions',
      {
        headers,
        timeout,
        endpointName: 'discoverComposerActions',
        params,
      },
    );
  }

  /**
   * Browse different lists of apps
   */
  discoverApps(
    params: ApiDiscoverAppsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiDiscoverApps200Response>('/v2/discover-apps', {
      headers,
      timeout,
      endpointName: 'discoverApps',
      params,
    });
  }

  /**
   * Browse different lists of frames
   */
  discoverFrames(
    params: ApiDiscoverFramesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiDiscoverFrames200Response>('/v2/discover-frames', {
      headers,
      timeout,
      endpointName: 'discoverFrames',
      params,
    });
  }

  /**
   * Buy Warps with Coinbase Commerce.
   */
  buyWarpsCoinbaseCommerce(
    body: ApiBuyWarpsCoinbaseCommerceRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiBuyWarpsCoinbaseCommerce200Response>(
      '/v2/buy-warps-coinbase-commerce',
      {
        headers,
        timeout,
        endpointName: 'buyWarpsCoinbaseCommerce',
        body,
      },
    );
  }

  /**
   * Check and confirm the receipt to finalize in-app purchase for authenticated user.
   */
  finishInAppPurchase(
    body: ApiFinishInAppPurchaseRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFinishInAppPurchase200Response>(
      '/v2/finish-in-app-purchase',
      {
        headers,
        timeout,
        endpointName: 'finishInAppPurchase',
        body,
      },
    );
  }

  /**
   * Check execution status for a swap
   */
  checkSwapStatus(
    params: ApiCheckSwapStatusQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiCheckSwapStatus200Response>(
      '/v2/swaps/check-status',
      {
        headers,
        timeout,
        endpointName: 'checkSwapStatus',
        params,
      },
    );
  }

  /**
   * Check if a user has TOTP enabled
   */
  getTotpEnabled({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetTotpEnabled200Response>('/v2/totp/enabled', {
      headers,
      timeout,
      endpointName: 'getTotpEnabled',
    });
  }

  /**
   * Check if a user has TOTP enabled for email
   */
  getTotpEnabledForEmail(
    params: ApiGetTotpEnabledForEmailQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetTotpEnabledForEmail200Response>(
      '/v2/totp/enabled-for-email',
      {
        headers,
        timeout,
        endpointName: 'getTotpEnabledForEmail',
        params,
      },
    );
  }

  /**
   * Check if gifting is allowed between users
   */
  canGiftWarps(
    params: ApiCanGiftWarpsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiCanGiftWarps200Response>('/v2/gift-warps', {
      headers,
      timeout,
      endpointName: 'canGiftWarps',
      params,
    });
  }

  /**
   * Check if the desired key for a channel is valid and free
   */
  validateNewChannelKey(
    params: ApiValidateNewChannelKeyQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiValidateNewChannelKey200Response>(
      '/v2/validate-new-channel-key',
      {
        headers,
        timeout,
        endpointName: 'validateNewChannelKey',
        params,
      },
    );
  }

  /**
   * Claim a quest reward
   */
  claimQuestReward(
    body: ApiClaimQuestRewardRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiClaimQuestReward200Response>(
      '/v2/quests/claim-reward',
      {
        headers,
        timeout,
        endpointName: 'claimQuestReward',
        body,
      },
    );
  }

  /**
   * Clear job queue or queue shart
   */
  clearJobQueue(
    body: ApiClearJobQueueRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiClearJobQueue200Response>('/v1/job-queue', {
      headers,
      timeout,
      endpointName: 'clearJobQueue',
      body,
    });
  }

  /**
   * Complete a campaign
   */
  completeCampaign(
    body: ApiCompleteCampaignRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCompleteCampaign200Response>('/v2/campaign', {
      headers,
      timeout,
      endpointName: 'completeCampaign',
      body,
    });
  }

  /**
   * Complete a phone verification
   */
  completePhoneVerification(
    body: ApiCompletePhoneVerificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCompletePhoneVerification200Response>(
      '/v2/complete-phone-verification',
      {
        headers,
        timeout,
        endpointName: 'completePhoneVerification',
        body,
      },
    );
  }

  /**
   * Complete registration for an FID by submitting a username and delegating a signer.
   */
  completeRegistration(
    body: ApiCompleteRegistrationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCompleteRegistration200Response>(
      '/v2/complete-registration',
      {
        headers,
        timeout,
        endpointName: 'completeRegistration',
        body,
      },
    );
  }

  /**
   * Confirm a magic link
   */
  completeMagicLink(
    body: ApiCompleteMagicLinkRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCompleteMagicLink200Response>(
      '/v2/magic-link-complete',
      {
        headers,
        timeout,
        endpointName: 'completeMagicLink',
        body,
      },
    );
  }

  /**
   * Confirm creator has tax documents and make them eligibile for rewards
   */
  confirmTaxDocuments(
    body: ApiConfirmTaxDocumentsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiConfirmTaxDocuments200Response>(
      '/v2/confirm-tax-documents',
      {
        headers,
        timeout,
        endpointName: 'confirmTaxDocuments',
        body,
      },
    );
  }

  /**
   * Confirms account deletion. Intended to be loaded via browser.
   */
  confirmAccountDelete(
    params: ApiConfirmAccountDeleteQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/v2/confirm-account-delete', {
      headers,
      timeout,
      endpointName: 'confirmAccountDelete',
      params,
    });
  }

  /**
   * Create a TOTP secret for a user
   */
  createTotpSecret({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiCreateTotpSecret200Response>('/v2/totp/create', {
      headers,
      timeout,
      endpointName: 'createTotpSecret',
      body: {},
    });
  }

  /**
   * Create a direct cast conversation.
   */
  fcPutConversation(
    body: ApiFcPutConversationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiFcPutConversation200Response>('/fc/conversation', {
      headers,
      timeout,
      endpointName: 'fcPutConversation',
      body,
    });
  }

  /**
   * Create a direct cast group.
   */
  fcPutGroup(
    body: ApiFcPutGroupRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiFcPutGroup200Response>('/fc/group', {
      headers,
      timeout,
      endpointName: 'fcPutGroup',
      body,
    });
  }

  /**
   * Create a featured mint
   */
  addFeaturedMint(
    body: ApiAddFeaturedMintRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAddFeaturedMint200Response>('/v1/featured-mint', {
      headers,
      timeout,
      endpointName: 'addFeaturedMint',
      body,
    });
  }

  /**
   * Create a frame event.
   */
  putFrameEvent(
    body: ApiPutFrameEventRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPutFrameEvent200Response>('/v2/frame-event', {
      headers,
      timeout,
      endpointName: 'putFrameEvent',
      body,
    });
  }

  /**
   * Create a like reaction for a cast.
   */
  createCastLike(
    body: ApiCreateCastLikeRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCreateCastLike200Response>('/v2/cast-likes', {
      headers,
      timeout,
      endpointName: 'createCastLike',
      body,
    });
  }

  /**
   * Create a new channel
   */
  createChannel(
    body: ApiCreateChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCreateChannel200Response>('/v2/channels-owned', {
      headers,
      timeout,
      endpointName: 'createChannel',
      body,
    });
  }

  /**
   * Create a new invite.
   */
  createInvite(
    body: ApiCreateInviteRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCreateInvite200Response>('/v2/invite', {
      headers,
      timeout,
      endpointName: 'createInvite',
      body,
    });
  }

  /**
   * Create a new recovery
   */
  createRecovery(
    body: ApiCreateRecoveryRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCreateRecovery200Response>('/v2/recoveries', {
      headers,
      timeout,
      endpointName: 'createRecovery',
      body,
    });
  }

  /**
   * Create a new starter pack
   */
  createStarterPack(
    body: ApiCreateStarterPackRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCreateStarterPack200Response>('/v2/starter-pack', {
      headers,
      timeout,
      endpointName: 'createStarterPack',
      body,
    });
  }

  /**
   * Create a poll
   */
  createPoll(
    body: ApiCreatePollRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCreatePoll200Response>('/v2/create-poll', {
      headers,
      timeout,
      endpointName: 'createPoll',
      body,
    });
  }

  /**
   * Create a remote SIWF request
   */
  createRemoteSiwfRequest(
    body: ApiCreateRemoteSiwfRequestRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCreateRemoteSiwfRequest200Response>('/v1/remote-siwf', {
      headers,
      timeout,
      endpointName: 'createRemoteSiwfRequest',
      body,
    });
  }

  /**
   * Create a signed key request.
   */
  createSignedKeyRequest(
    body: ApiCreateSignedKeyRequestRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCreateSignedKeyRequest200Response>(
      '/v2/signed-key-requests',
      {
        headers,
        timeout,
        endpointName: 'createSignedKeyRequest',
        body,
      },
    );
  }

  /**
   * Create a warpcast sponsored invite.
   */
  createWarpcastSponsoredInvite({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiCreateWarpcastSponsoredInvite200Response>(
      '/v2/warpcast-sponsored-invites',
      {
        headers,
        timeout,
        endpointName: 'createWarpcastSponsoredInvite',
        body: {},
      },
    );
  }

  /**
   * Creates a cast for the currently authenticated user.
   */
  createCast(
    body: ApiCreateCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCreateCast201Response>('/v2/casts', {
      headers,
      timeout,
      endpointName: 'createCast',
      body,
    });
  }

  /**
   * Creates a group for direct casts.
   */
  putDirectCastGroupV3(
    body: ApiPutDirectCastGroupV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPutDirectCastGroupV3200Response>(
      '/v2/direct-cast-group',
      {
        headers,
        timeout,
        endpointName: 'putDirectCastGroupV3',
        body,
      },
    );
  }

  /**
   * Creates an invite to a direct cast group, removing the old one if present.
   */
  putDirectCastGroupInviteV3(
    body: ApiPutDirectCastGroupInviteV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPutDirectCastGroupInviteV3200Response>(
      '/v2/direct-cast-group-invite',
      {
        headers,
        timeout,
        endpointName: 'putDirectCastGroupInviteV3',
        body,
      },
    );
  }

  /**
   * Creates an onboarding for an Ethereum account
   */
  createOnboarding(
    body: ApiCreateOnboardingRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCreateOnboarding200Response>('/v2/onboarding', {
      headers,
      timeout,
      endpointName: 'createOnboarding',
      body,
    });
  }

  /**
   * Declines an invite to a direct cast group.
   */
  postDirectCastDeclineGroupInviteV3(
    body: ApiPostDirectCastDeclineGroupInviteV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastDeclineGroupInviteV3200Response>(
      '/v2/direct-cast-decline-group-invite',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastDeclineGroupInviteV3',
        body,
      },
    );
  }

  /**
   * Deeplink to clients.
   */
  redirectToCastDeepLink({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<void>('/casts/*', {
      headers,
      timeout,
      endpointName: 'redirectToCastDeepLink',
    });
  }

  /**
   * Delete a composer action
   */
  deleteComposerAction(
    body: ApiDeleteComposerActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteComposerAction200Response>(
      '/v2/composer-action',
      {
        headers,
        timeout,
        endpointName: 'deleteComposerAction',
        body,
      },
    );
  }

  /**
   * Delete a direct cast message.
   */
  fcDeleteMessage(
    body: ApiFcDeleteMessageRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiFcDeleteMessage200Response>('/fc/message', {
      headers,
      timeout,
      endpointName: 'fcDeleteMessage',
      body,
    });
  }

  /**
   * Delete a discovery app
   */
  deleteDiscoveryApp(
    body: ApiDeleteDiscoveryAppRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteDiscoveryApp200Response>('/v2/discovery-app', {
      headers,
      timeout,
      endpointName: 'deleteDiscoveryApp',
      body,
    });
  }

  /**
   * Delete a discovery frame
   */
  deleteDiscoveryFrame(
    body: ApiDeleteDiscoveryFrameRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteDiscoveryFrame200Response>(
      '/v2/discovery-frame',
      {
        headers,
        timeout,
        endpointName: 'deleteDiscoveryFrame',
        body,
      },
    );
  }

  /**
   * Delete a featured mint
   */
  deleteFeaturedMint(
    body: ApiDeleteFeaturedMintRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteFeaturedMint200Response>('/v1/featured-mint', {
      headers,
      timeout,
      endpointName: 'deleteFeaturedMint',
      body,
    });
  }

  /**
   * Delete a recast.
   */
  deleteRecast(
    body: ApiDeleteRecastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteRecast200Response>('/v2/recasts', {
      headers,
      timeout,
      endpointName: 'deleteRecast',
      body,
    });
  }

  /**
   * Delete all quests for the user
   */
  deleteQuestsForUser(
    body: ApiDeleteQuestsForUserRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteQuestsForUser200Response>(
      '/v2/quests/debug/quests',
      {
        headers,
        timeout,
        endpointName: 'deleteQuestsForUser',
        body,
      },
    );
  }

  /**
   * Delete an account verification for a user
   */
  deleteAccountVerification(
    body: ApiDeleteAccountVerificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteAccountVerification200Response>(
      '/v2/account-verifications',
      {
        headers,
        timeout,
        endpointName: 'deleteAccountVerification',
        body,
      },
    );
  }

  /**
   * Delete direct cast message
   */
  deleteDirectCastMessage(
    body: ApiDeleteDirectCastMessageRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteDirectCastMessage200Response>(
      '/v2/delete-message',
      {
        headers,
        timeout,
        endpointName: 'deleteDirectCastMessage',
        body,
      },
    );
  }

  /**
   * Delete information about the specified device.
   */
  unregisterDevice(
    body: ApiUnregisterDeviceRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiUnregisterDevice200Response>('/v2/devices', {
      headers,
      timeout,
      endpointName: 'unregisterDevice',
      body,
    });
  }

  /**
   * Delete starter pack
   */
  deleteStarterPack(
    body: ApiDeleteStarterPackRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteStarterPack200Response>('/v2/starter-pack', {
      headers,
      timeout,
      endpointName: 'deleteStarterPack',
      body,
    });
  }

  /**
   * Delete uploaded video
   */
  abandonVideoUpload(
    body: ApiAbandonVideoUploadRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiAbandonVideoUpload200Response>('/v1/uploaded-video', {
      headers,
      timeout,
      endpointName: 'abandonVideoUpload',
      body,
    });
  }

  /**
   * Deletes a cast.
   */
  deleteCast(
    body: ApiDeleteCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteCast200Response>('/v2/casts', {
      headers,
      timeout,
      endpointName: 'deleteCast',
      body,
    });
  }

  /**
   * Deletes a user's contacts.
   */
  deleteContacts({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.delete<ApiDeleteContacts200Response>('/v2/contacts', {
      headers,
      timeout,
      endpointName: 'deleteContacts',
      body: {},
    });
  }

  /**
   * Deletes an API key.
   */
  deleteApiKey(
    body: ApiDeleteApiKeyRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteApiKey200Response>('/v2/api-keys', {
      headers,
      timeout,
      endpointName: 'deleteApiKey',
      body,
    });
  }

  /**
   * Deletes an action from a users actions
   */
  deleteUserCastAction(
    body: ApiDeleteUserCastActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteUserCastAction200Response>(
      '/v2/user-cast-actions',
      {
        headers,
        timeout,
        endpointName: 'deleteUserCastAction',
        body,
      },
    );
  }

  /**
   * Deletes the synchronization channel.
   */
  deleteSyncChannel(
    params: ApiDeleteSyncChannelQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteSyncChannel200Response>('/v2/sync-channel', {
      headers,
      timeout,
      endpointName: 'deleteSyncChannel',
      params,
    });
  }

  /**
   * Deny a recovery, providing a signature representing an admin's rejection of the recovery.
   */
  denyRecoveryTransaction(
    body: ApiDenyRecoveryTransactionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiDenyRecoveryTransaction200Response>(
      '/v2/deny-recovery-transaction',
      {
        headers,
        timeout,
        endpointName: 'denyRecoveryTransaction',
        body,
      },
    );
  }

  /**
   * Diagnose the state of a domain manifest
   */
  getDomainManifestState(
    params: ApiGetDomainManifestStateQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDomainManifestState200Response>(
      '/v1/domain-manifest',
      {
        headers,
        timeout,
        endpointName: 'getDomainManifestState',
        params,
      },
    );
  }

  /**
   * Disable TOTP for a user
   */
  disableTotp({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiDisableTotp200Response>('/v2/totp/disable', {
      headers,
      timeout,
      endpointName: 'disableTotp',
      body: {},
    });
  }

  /**
   * Disable frame notifications
   */
  disableFrameNotifications(
    body: ApiDisableFrameNotificationsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDisableFrameNotifications200Response>(
      '/v1/frame-notifications',
      {
        headers,
        timeout,
        endpointName: 'disableFrameNotifications',
        body,
      },
    );
  }

  /**
   * Disable notifications on link cast activity.
   */
  disableChannelNotifications(
    body: ApiDisableChannelNotificationsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDisableChannelNotifications200Response>(
      '/v2/channel-notifications',
      {
        headers,
        timeout,
        endpointName: 'disableChannelNotifications',
        body,
      },
    );
  }

  /**
   * Disable notifications on link cast activity.
   */
  disableLinkNotifications(
    body: ApiDisableLinkNotificationsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDisableLinkNotifications200Response>(
      '/v2/link-notifications',
      {
        headers,
        timeout,
        endpointName: 'disableLinkNotifications',
        body,
      },
    );
  }

  /**
   * Discards a draft cast or caststorm
   */
  discardDraftCast(
    body: ApiDiscardDraftCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDiscardDraftCast200Response>('/v2/draft-casts', {
      headers,
      timeout,
      endpointName: 'discardDraftCast',
      body,
    });
  }

  /**
   * Discord auth callbacks
   */
  authenticateDiscord({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<void>('/auth/discord', {
      headers,
      timeout,
      endpointName: 'authenticateDiscord',
    });
  }

  /**
   * Dismiss suggested users
   */
  dismissSuggestedUsers(
    body: ApiDismissSuggestedUsersRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiDismissSuggestedUsers200Response>(
      '/v1/dismiss-suggested-users',
      {
        headers,
        timeout,
        endpointName: 'dismissSuggestedUsers',
        body,
      },
    );
  }

  /**
   * Downvote casts
   */
  downvoteCast(
    body: ApiDownvoteCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiDownvoteCast200Response>('/v2/debug-cast-embeds', {
      headers,
      timeout,
      endpointName: 'downvoteCast',
      body,
    });
  }

  /**
   * Enable frame notifications
   */
  enableFrameNotifications(
    body: ApiEnableFrameNotificationsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiEnableFrameNotifications200Response>(
      '/v1/frame-notifications',
      {
        headers,
        timeout,
        endpointName: 'enableFrameNotifications',
        body,
      },
    );
  }

  /**
   * Enable notifications on link cast activity.
   */
  enableChannelNotifications(
    body: ApiEnableChannelNotificationsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiEnableChannelNotifications200Response>(
      '/v2/channel-notifications',
      {
        headers,
        timeout,
        endpointName: 'enableChannelNotifications',
        body,
      },
    );
  }

  /**
   * Enable notifications on link cast activity.
   */
  enableLinkNotifications(
    body: ApiEnableLinkNotificationsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiEnableLinkNotifications200Response>(
      '/v2/link-notifications',
      {
        headers,
        timeout,
        endpointName: 'enableLinkNotifications',
        body,
      },
    );
  }

  /**
   * Enables a cast action
   */
  enableCastAction(
    body: ApiEnableCastActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiEnableCastAction200Response>('/v2/enable-cast-action', {
      headers,
      timeout,
      endpointName: 'enableCastAction',
      body,
    });
  }

  /**
   * Establish or update an E2EE synchronization channel between devices.
   */
  updateSyncChannel(
    body: ApiUpdateSyncChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiUpdateSyncChannel200Response>('/v2/sync-channel', {
      headers,
      timeout,
      endpointName: 'updateSyncChannel',
      body,
    });
  }

  /**
   * Execute a post action on a frame
   */
  postFrameAction(
    body: ApiPostFrameActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostFrameAction200Response>('/v2/frame-action', {
      headers,
      timeout,
      endpointName: 'postFrameAction',
      body,
    });
  }

  /**
   * Execute a post action on a frame
   */
  postFrameJSON(
    body: ApiPostFrameJSONRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostFrameJSON200Response>('/v2/frame-json', {
      headers,
      timeout,
      endpointName: 'postFrameJSON',
      body,
    });
  }

  /**
   * Execute a post_redirect action on a frame
   */
  postFrameRedirect(
    body: ApiPostFrameRedirectRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostFrameRedirect200Response>('/v2/frame-redirect', {
      headers,
      timeout,
      endpointName: 'postFrameRedirect',
      body,
    });
  }

  /**
   * Execute a transaction action on a frame
   */
  postFrameTransaction(
    body: ApiPostFrameTransactionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostFrameTransaction200Response>(
      '/v2/frame-transaction',
      {
        headers,
        timeout,
        endpointName: 'postFrameTransaction',
        body,
      },
    );
  }

  /**
   * Execute an exchange of warps for USDC.
   */
  executeWarpsTrade({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiExecuteWarpsTrade200Response>(
      '/v2/warps/exchange/execute',
      {
        headers,
        timeout,
        endpointName: 'executeWarpsTrade',
        body: {},
      },
    );
  }

  /**
   * Favorite a feed
   */
  addFavoriteFeed(
    body: ApiAddFavoriteFeedRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAddFavoriteFeed200Response>('/v2/favorite-feeds', {
      headers,
      timeout,
      endpointName: 'addFavoriteFeed',
      body,
    });
  }

  /**
   * Favorite a frame
   */
  addFavoriteFrame(
    body: ApiAddFavoriteFrameRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiAddFavoriteFrame200Response>('/v1/favorite-frames', {
      headers,
      timeout,
      endpointName: 'addFavoriteFrame',
      body,
    });
  }

  /**
   * Feedback from user for a notification
   */
  ingestNotificationFeedback(
    body: ApiIngestNotificationFeedbackRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiIngestNotificationFeedback200Response>(
      '/v2/notification-feedback',
      {
        headers,
        timeout,
        endpointName: 'ingestNotificationFeedback',
        body,
      },
    );
  }

  /**
   * Fetch information about a key transaction
   */
  getKeyTransaction(
    params: ApiGetKeyTransactionQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetKeyTransaction200Response>(
      '/v2/key-transaction',
      {
        headers,
        timeout,
        endpointName: 'getKeyTransaction',
        params,
      },
    );
  }

  /**
   * Fetch possible metadata for direct cast message.
   */
  processDirectCastMessageMetadata(
    body: ApiProcessDirectCastMessageMetadataRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiProcessDirectCastMessageMetadata200Response>(
      '/v2/direct-cast-message-metadata',
      {
        headers,
        timeout,
        endpointName: 'processDirectCastMessageMetadata',
        body,
      },
    );
  }

  /**
   * Fetch price data for native chain asset
   */
  getWalletChainNativeAsset(
    params: ApiGetWalletChainNativeAssetQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWalletChainNativeAsset200Response>(
      '/v2/wallet/chain-native-asset',
      {
        headers,
        timeout,
        endpointName: 'getWalletChainNativeAsset',
        params,
      },
    );
  }

  /**
   * Fetch status of the mint workflow.
   */
  getMintStatus(
    params: ApiGetMintStatusQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetMintStatus200Response>('/v2/mint-status', {
      headers,
      timeout,
      endpointName: 'getMintStatus',
      params,
    });
  }

  /**
   * Finalize in-app purchase flow for custody-token auth user.
   */
  finishInAppPurchaseWithCustody(
    body: ApiFinishInAppPurchaseWithCustodyRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFinishInAppPurchaseWithCustody200Response>(
      '/v2/finish-in-app-purchase-with-custody',
      {
        headers,
        timeout,
        endpointName: 'finishInAppPurchaseWithCustody',
        body,
      },
    );
  }

  /**
   * Follow a channel
   */
  fcFollowChannel(
    body: ApiFcFollowChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFcFollowChannel200Response>('/fc/channel-follows', {
      headers,
      timeout,
      endpointName: 'fcFollowChannel',
      body,
    });
  }

  /**
   * Follow a feed.
   */
  createFeedFollow(
    body: ApiCreateFeedFollowRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCreateFeedFollow200Response>('/v2/feed-follows', {
      headers,
      timeout,
      endpointName: 'createFeedFollow',
      body,
    });
  }

  /**
   * Follow a user.
   */
  createFollow(
    body: ApiCreateFollowRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCreateFollow200Response>('/v2/follows', {
      headers,
      timeout,
      endpointName: 'createFollow',
      body,
    });
  }

  /**
   * Follow all starter pack users
   */
  followAllStarterPackUsers(
    body: ApiFollowAllStarterPackUsersRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiFollowAllStarterPackUsers200Response>(
      '/v2/follow-all-starter-pack-users',
      {
        headers,
        timeout,
        endpointName: 'followAllStarterPackUsers',
        body,
      },
    );
  }

  /**
   * Force add a moderator to a channel as an admin (skips invites)
   */
  adminAddChannelModerator(
    body: ApiAdminAddChannelModeratorRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAdminAddChannelModerator200Response>(
      '/v1/admin-add-channel-moderator',
      {
        headers,
        timeout,
        endpointName: 'adminAddChannelModerator',
        body,
      },
    );
  }

  /**
   * Force change channel owner as an admin (skips invites)
   */
  adminChangeChannelOwner(
    body: ApiAdminChangeChannelOwnerRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAdminChangeChannelOwner200Response>(
      '/v1/admin-transfer-channel-ownership',
      {
        headers,
        timeout,
        endpointName: 'adminChangeChannelOwner',
        body,
      },
    );
  }

  /**
   * Full text search through the contents of all DC messages the user has access to.
   */
  globalSearchForMessages(
    params: ApiGlobalSearchForMessagesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGlobalSearchForMessages200Response>(
      '/v2/direct-cast-message-search',
      {
        headers,
        timeout,
        endpointName: 'globalSearchForMessages',
        params,
      },
    );
  }

  /**
   * Generate a SIWE nonce to be used for registration
   */
  getSiweNonce({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetSiweNonce200Response>('/v2/get-siwe-nonce', {
      headers,
      timeout,
      endpointName: 'getSiweNonce',
    });
  }

  /**
   * Generate a TOTP token
   */
  generateTotpToken(
    body: ApiGenerateTotpTokenRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiGenerateTotpToken200Response>(
      '/v2/totp/generate-token',
      {
        headers,
        timeout,
        endpointName: 'generateTotpToken',
        body,
      },
    );
  }

  /**
   * Generate a TOTP token for email
   */
  generateTotpTokenForEmail(
    body: ApiGenerateTotpTokenForEmailRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiGenerateTotpTokenForEmail200Response>(
      '/v2/totp/generate-token-for-email',
      {
        headers,
        timeout,
        endpointName: 'generateTotpTokenForEmail',
        body,
      },
    );
  }

  /**
   * Generate a nonce to be used for get-dc-auth-token
   */
  getDCNonce({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetDCNonce200Response>('/v2/get-dc-nonce', {
      headers,
      timeout,
      endpointName: 'getDCNonce',
    });
  }

  /**
   * Generate a unique URL that can be used to upload an image directly to Cloudflare.
   */
  generateImageUploadUrl({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiGenerateImageUploadUrl201Response>(
      '/v1/generate-image-upload-url',
      {
        headers,
        timeout,
        endpointName: 'generateImageUploadUrl',
        body: {},
      },
    );
  }

  /**
   * Generates a SIWE nonce
   */
  getStepUpMessage({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetStepUpMessage200Response>('/v2/auth/step-up', {
      headers,
      timeout,
      endpointName: 'getStepUpMessage',
    });
  }

  /**
   * Generates an API key.
   */
  createApiKey(
    body: ApiCreateApiKeyRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCreateApiKey200Response>('/v2/api-keys', {
      headers,
      timeout,
      endpointName: 'createApiKey',
      body,
    });
  }

  /**
   * Generates an OpenGraph image
   */
  generateOpenGraphImage(
    params: ApiGenerateOpenGraphImageQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/v2/og-image', {
      headers,
      timeout,
      endpointName: 'generateOpenGraphImage',
      params,
    });
  }

  /**
   * Generates an authentication token.
   */
  createAuthToken(
    body: ApiCreateAuthTokenRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCreateAuthToken200Response>('/v2/auth', {
      headers,
      timeout,
      endpointName: 'createAuthToken',
      body,
    });
  }

  /**
   * Generates an default image for a channel
   */
  generateChannelImage(
    params: ApiGenerateChannelImageQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/v2/channel-image', {
      headers,
      timeout,
      endpointName: 'generateChannelImage',
      params,
    });
  }

  /**
   * Generates an niceley formatted cast image for sharing
   */
  generateCastShareableImage(
    params: ApiGenerateCastShareableImageQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/v2/cast-image', {
      headers,
      timeout,
      endpointName: 'generateCastShareableImage',
      params,
    });
  }

  /**
   * Generates an nicely formatted image for invite links
   */
  generateInviteOpenGraphImage(
    params: ApiGenerateInviteOpenGraphImageQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/v2/invite-image', {
      headers,
      timeout,
      endpointName: 'generateInviteOpenGraphImage',
      params,
    });
  }

  /**
   * Get Discord auth link to redirect authenticated user
   */
  getDiscordAuthLink({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetDiscordAuthLink200Response>(
      '/v2/get-discord-auth-link',
      {
        headers,
        timeout,
        endpointName: 'getDiscordAuthLink',
      },
    );
  }

  /**
   * Get Farcasters matched through uploaded contacts
   */
  getContactsUsers(
    params: ApiGetContactsUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetContactsUsers200Response>(
      '/v2/contacts-users',
      {
        headers,
        timeout,
        endpointName: 'getContactsUsers',
        params,
      },
    );
  }

  /**
   * Get GitHub auth link to redirect authenticated user
   */
  getGitHubAuthLink({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetGitHubAuthLink200Response>(
      '/v2/get-gh-auth-link',
      {
        headers,
        timeout,
        endpointName: 'getGitHubAuthLink',
      },
    );
  }

  /**
   * Get LinkedIn auth link to redirect authenticated user
   */
  getLinkedInAuthLink({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetLinkedInAuthLink200Response>(
      '/v2/get-linkedin-auth-link',
      {
        headers,
        timeout,
        endpointName: 'getLinkedInAuthLink',
      },
    );
  }

  /**
   * Get TTL for direct casts authenticated user is a part of. (deprecated)
   */
  getDirectCastTTLs({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetDirectCastTTLs200Response>(
      '/v2/direct-cast-ttls',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastTTLs',
      },
    );
  }

  /**
   * Get Warpcast SKU offering for various onchain transactions.
   */
  getOffering(
    params: ApiGetOfferingQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetOffering200Response>('/v2/offering', {
      headers,
      timeout,
      endpointName: 'getOffering',
      params,
    });
  }

  /**
   * Get Warpcast in-app minting warps offering. (deprecated)
   */
  getGeneralizedInAppMintsWarpsOffering(
    params: ApiGetGeneralizedInAppMintsWarpsOfferingQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetGeneralizedInAppMintsWarpsOffering200Response>(
      '/v2/generalized-in-app-mints-warps-offering',
      {
        headers,
        timeout,
        endpointName: 'getGeneralizedInAppMintsWarpsOffering',
        params,
      },
    );
  }

  /**
   * Get Warpcast in-app minting warps offering. (deprecated)
   */
  getInAppMintsWarpsOffering(
    params: ApiGetInAppMintsWarpsOfferingQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetInAppMintsWarpsOffering200Response>(
      '/v2/in-app-mints-warps-offering',
      {
        headers,
        timeout,
        endpointName: 'getInAppMintsWarpsOffering',
        params,
      },
    );
  }

  /**
   * Get Warpcast mint with warps eligibility and offering.
   */
  getMintWithWarpsOffering(
    params: ApiGetMintWithWarpsOfferingQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetMintWithWarpsOffering200Response>(
      '/v2/mint-with-warps-offering',
      {
        headers,
        timeout,
        endpointName: 'getMintWithWarpsOffering',
        params,
      },
    );
  }

  /**
   * Get Warpcast product catalog and all offerings.
   */
  getProductCatalog({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetProductCatalog200Response>(
      '/v2/product-catalog',
      {
        headers,
        timeout,
        endpointName: 'getProductCatalog',
      },
    );
  }

  /**
   * Get Warpcast rent storage offering SKUs
   */
  getRentStorageOfferings({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetRentStorageOfferings200Response>(
      '/v2/rent-storage-offerings',
      {
        headers,
        timeout,
        endpointName: 'getRentStorageOfferings',
      },
    );
  }

  /**
   * Get Warpcast warps offering for signers feature specifically.
   */
  getWarpsOffering(
    params: ApiGetWarpsOfferingQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWarpsOffering200Response>(
      '/v2/warps-offering',
      {
        headers,
        timeout,
        endpointName: 'getWarpsOffering',
        params,
      },
    );
  }

  /**
   * Get X auth link to redirect authenticated user
   */
  getXAuthLink({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetXAuthLink200Response>('/v2/get-x-auth-link', {
      headers,
      timeout,
      endpointName: 'getXAuthLink',
    });
  }

  /**
   * Get a direct cast conversation.
   */
  fcGetConversation(
    params: ApiFcGetConversationQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetConversation200Response>('/fc/conversation', {
      headers,
      timeout,
      endpointName: 'fcGetConversation',
      params,
    });
  }

  /**
   * Get a direct cast group's invites.
   */
  fcGetGroupInvites(
    params: ApiFcGetGroupInvitesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetGroupInvites200Response>(
      '/fc/group-invites',
      {
        headers,
        timeout,
        endpointName: 'fcGetGroupInvites',
        params,
      },
    );
  }

  /**
   * Get a direct cast group's members.
   */
  fcGetGroupMembers(
    params: ApiFcGetGroupMembersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetGroupMembers200Response>(
      '/fc/group-members',
      {
        headers,
        timeout,
        endpointName: 'fcGetGroupMembers',
        params,
      },
    );
  }

  /**
   * Get a direct cast group.
   */
  fcGetGroup(
    params: ApiFcGetGroupQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetGroup200Response>('/fc/group', {
      headers,
      timeout,
      endpointName: 'fcGetGroup',
      params,
    });
  }

  /**
   * Get a direct cast message.
   */
  fcGetMessage(
    params: ApiFcGetMessageQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetMessage200Response>('/fc/message', {
      headers,
      timeout,
      endpointName: 'fcGetMessage',
      params,
    });
  }

  /**
   * Get a finalized quote for a swap
   */
  getSwapQuote(
    params: ApiGetSwapQuoteQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSwapQuote200Response>('/v2/swaps/get-quote', {
      headers,
      timeout,
      endpointName: 'getSwapQuote',
      params,
    });
  }

  /**
   * Get a list of direct cast conversations.
   */
  fcGetConversationList(
    params: ApiFcGetConversationListQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetConversationList200Response>(
      '/fc/conversation-list',
      {
        headers,
        timeout,
        endpointName: 'fcGetConversationList',
        params,
      },
    );
  }

  /**
   * Get a list of direct cast groups.
   */
  fcGetGroupList(
    params: ApiFcGetGroupListQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetGroupList200Response>('/fc/group-list', {
      headers,
      timeout,
      endpointName: 'fcGetGroupList',
      params,
    });
  }

  /**
   * Get a list of direct cast messages.
   */
  fcGetMessageList(
    params: ApiFcGetMessageListQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetMessageList200Response>('/fc/message-list', {
      headers,
      timeout,
      endpointName: 'fcGetMessageList',
      params,
    });
  }

  /**
   * Get a list of the actors for a specified notification group.
   */
  getNotificationActorsInGroup(
    params: ApiGetNotificationActorsInGroupQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetNotificationActorsInGroup200Response>(
      '/v2/notification-group-actors',
      {
        headers,
        timeout,
        endpointName: 'getNotificationActorsInGroup',
        params,
      },
    );
  }

  /**
   * Get a recovery
   */
  getRecovery(
    params: ApiGetRecoveryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRecovery200Response>('/v2/recoveries', {
      headers,
      timeout,
      endpointName: 'getRecovery',
      params,
    });
  }

  /**
   * Get a signed key request.
   */
  getSignedKeyRequest(
    params: ApiGetSignedKeyRequestQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSignedKeyRequest200Response>(
      '/v2/signed-key-request',
      {
        headers,
        timeout,
        endpointName: 'getSignedKeyRequest',
        params,
      },
    );
  }

  /**
   * Get a temporary account association
   */
  devToolsGetTempAccountAssociation(
    params: ApiDevToolsGetTempAccountAssociationQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiDevToolsGetTempAccountAssociation200Response>(
      '/v1/dev-tools/temp-account-association',
      {
        headers,
        timeout,
        endpointName: 'devToolsGetTempAccountAssociation',
        params,
      },
    );
  }

  /**
   * Get a user's warpcast wallet resource
   */
  walletResource(
    body: ApiWalletResourceRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiWalletResource200Response>('/v2/wallet/resource', {
      headers,
      timeout,
      endpointName: 'walletResource',
      body,
    });
  }

  /**
   * Get a video
   */
  getVideo({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<void>('/v1/video/:uid.m3u8', {
      headers,
      timeout,
      endpointName: 'getVideo',
    });
  }

  /**
   * Get activity for a wallet
   */
  getWalletActivity(
    params: ApiGetWalletActivityQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWalletActivity200Response>(
      '/v2/wallet/activity',
      {
        headers,
        timeout,
        endpointName: 'getWalletActivity',
        params,
      },
    );
  }

  /**
   * Get all API keys for a user.
   */
  getApiKeys({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetApiKeys200Response>('/v2/api-keys', {
      headers,
      timeout,
      endpointName: 'getApiKeys',
    });
  }

  /**
   * Get all account verifications for a user
   */
  getAccountVerifications({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetAccountVerifications200Response>(
      '/v2/account-verifications',
      {
        headers,
        timeout,
        endpointName: 'getAccountVerifications',
      },
    );
  }

  /**
   * Get all available addresses we can mint an asset for the authed user.
   */
  getAvailableWalletsToMintTo({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetAvailableWalletsToMintTo200Response>(
      '/v2/available-wallets-to-mint-to',
      {
        headers,
        timeout,
        endpointName: 'getAvailableWalletsToMintTo',
      },
    );
  }

  /**
   * Get all available usernames for the authenticated user
   */
  getUserUsernames({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetUserUsernames200Response>(
      '/v2/user-usernames',
      {
        headers,
        timeout,
        endpointName: 'getUserUsernames',
      },
    );
  }

  /**
   * Get all bookmarked casts.
   */
  getBookmarkedCasts(
    params: ApiGetBookmarkedCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetBookmarkedCasts200Response>(
      '/v2/bookmarked-casts',
      {
        headers,
        timeout,
        endpointName: 'getBookmarkedCasts',
        params,
      },
    );
  }

  /**
   * Get all casts in reverse chronological order.
   */
  getAllRecentCasts(
    params: ApiGetAllRecentCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetAllRecentCasts200Response>('/v2/recent-casts', {
      headers,
      timeout,
      endpointName: 'getAllRecentCasts',
      params,
    });
  }

  /**
   * Get all connected accounts for authenticated user
   */
  getConnectedAccounts(
    params: ApiGetConnectedAccountsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetConnectedAccounts200Response>(
      '/v2/connected-accounts',
      {
        headers,
        timeout,
        endpointName: 'getConnectedAccounts',
        params,
      },
    );
  }

  /**
   * Get all direct cast users the authenticated user can start a conversation with.
   */
  getDirectCastUsers(
    params: ApiGetDirectCastUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastUsers200Response>(
      '/v2/direct-cast-users',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastUsers',
        params,
      },
    );
  }

  /**
   * Get all like reactions for a cast.
   */
  getCastLikes(
    params: ApiGetCastLikesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCastLikes200Response>('/v2/cast-likes', {
      headers,
      timeout,
      endpointName: 'getCastLikes',
      params,
    });
  }

  /**
   * Get all members of a starter pack
   */
  fcGetStarterPackUsers(
    params: ApiFcGetStarterPackUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetStarterPackUsers200Response>(
      '/fc/starter-pack-members',
      {
        headers,
        timeout,
        endpointName: 'fcGetStarterPackUsers',
        params,
      },
    );
  }

  /**
   * Get all of casts liked by user.
   */
  getUserLikedCasts(
    params: ApiGetUserLikedCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserLikedCasts200Response>(
      '/v2/user-liked-casts',
      {
        headers,
        timeout,
        endpointName: 'getUserLikedCasts',
        params,
      },
    );
  }

  /**
   * Get all possible interests to showcase to user during onboarding
   */
  getOnboardingInterests(
    params: ApiGetOnboardingInterestsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetOnboardingInterests200Response>(
      '/v2/onboarding-interests',
      {
        headers,
        timeout,
        endpointName: 'getOnboardingInterests',
        params,
      },
    );
  }

  /**
   * Get all preferences for the authenticated user.
   */
  getUserPreferences({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetUserPreferences200Response>(
      '/v2/user-preferences',
      {
        headers,
        timeout,
        endpointName: 'getUserPreferences',
      },
    );
  }

  /**
   * Get all quotes of a cast.
   */
  getCastQuotes(
    params: ApiGetCastQuotesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCastQuotes200Response>('/v2/cast-quotes', {
      headers,
      timeout,
      endpointName: 'getCastQuotes',
      params,
    });
  }

  /**
   * Get all reactions (both likes and recasts) for a cast.
   */
  getCastReactions(
    params: ApiGetCastReactionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCastReactions200Response>(
      '/v2/cast-reactions',
      {
        headers,
        timeout,
        endpointName: 'getCastReactions',
        params,
      },
    );
  }

  /**
   * Get all signers for a user.
   */
  getSigners(
    params: ApiGetSignersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSigners200Response>('/v2/signers', {
      headers,
      timeout,
      endpointName: 'getSigners',
      params,
    });
  }

  /**
   * Get all starter packs on the network
   */
  getAllStarterPacks(
    params: ApiGetAllStarterPacksQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetAllStarterPacks200Response>(
      '/v2/starter-packs/all',
      {
        headers,
        timeout,
        endpointName: 'getAllStarterPacks',
        params,
      },
    );
  }

  /**
   * Get all the viewed casts for a user that are stored in Redis.
   */
  getUserViewedCasts(
    params: ApiGetUserViewedCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserViewedCasts200Response>(
      '/v2/user-viewed-casts',
      {
        headers,
        timeout,
        endpointName: 'getUserViewedCasts',
        params,
      },
    );
  }

  /**
   * Get all users blocked from using the app
   */
  getAppBlockedUsers({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetAppBlockedUsers200Response>(
      '/v1/app-blocked-users',
      {
        headers,
        timeout,
        endpointName: 'getAppBlockedUsers',
      },
    );
  }

  /**
   * Get all users that follow the specified channel that the viewer follows.
   */
  getChannelFollowersYouKnow(
    params: ApiGetChannelFollowersYouKnowQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelFollowersYouKnow200Response>(
      '/v2/channel-followers-you-know',
      {
        headers,
        timeout,
        endpointName: 'getChannelFollowersYouKnow',
        params,
      },
    );
  }

  /**
   * Get all users that follow the specified channel.
   */
  getChannelFollowers(
    params: ApiGetChannelFollowersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelFollowers200Response>(
      '/v2/channel-followers',
      {
        headers,
        timeout,
        endpointName: 'getChannelFollowers',
        params,
      },
    );
  }

  /**
   * Get all users that follow the specified user that viewer knows.
   */
  getFollowersYouKnow(
    params: ApiGetFollowersYouKnowQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFollowersYouKnow200Response>(
      '/v2/followers-you-know',
      {
        headers,
        timeout,
        endpointName: 'getFollowersYouKnow',
        params,
      },
    );
  }

  /**
   * Get all users that follow the specified user.
   */
  getFollowers(
    params: ApiGetFollowersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFollowers200Response>('/v2/followers', {
      headers,
      timeout,
      endpointName: 'getFollowers',
      params,
    });
  }

  /**
   * Get all users the specified user is following.
   */
  getFollowing(
    params: ApiGetFollowingQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFollowing200Response>('/v2/following', {
      headers,
      timeout,
      endpointName: 'getFollowing',
      params,
    });
  }

  /**
   * Get all users who recasted a cast.
   */
  getCastRecasters(
    params: ApiGetCastRecastersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCastRecasters200Response>(
      '/v2/cast-recasters',
      {
        headers,
        timeout,
        endpointName: 'getCastRecasters',
        params,
      },
    );
  }

  /**
   * Get all users with a particular profile location.
   */
  getUsersByLocation(
    params: ApiGetUsersByLocationQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUsersByLocation200Response>(
      '/v2/location-users',
      {
        headers,
        timeout,
        endpointName: 'getUsersByLocation',
        params,
      },
    );
  }

  /**
   * Get all verifications for the specified user.
   */
  getVerifications(
    params: ApiGetVerificationsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetVerifications200Response>('/v2/verifications', {
      headers,
      timeout,
      endpointName: 'getVerifications',
      params,
    });
  }

  /**
   * Get an active campaign
   */
  getCampaign(
    params: ApiGetCampaignQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCampaign200Response>('/v2/campaign', {
      headers,
      timeout,
      endpointName: 'getCampaign',
      params,
    });
  }

  /**
   * Get an auth token for calling direct cast APIs
   */
  getDCAuthToken(
    params: ApiGetDCAuthTokenQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDCAuthToken200Response>(
      '/v2/get-dc-auth-token',
      {
        headers,
        timeout,
        endpointName: 'getDCAuthToken',
        params,
      },
    );
  }

  /**
   * Get an indicative price for a swap
   */
  getSwapPrice(
    params: ApiGetSwapPriceQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSwapPrice200Response>('/v2/swaps/get-price', {
      headers,
      timeout,
      endpointName: 'getSwapPrice',
      params,
    });
  }

  /**
   * Get application context for the current user.
   */
  getUserAppContext({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetUserAppContext200Response>(
      '/v2/user-app-context',
      {
        headers,
        timeout,
        endpointName: 'getUserAppContext',
      },
    );
  }

  /**
   * Get apps by author
   */
  getAppsByAuthor(
    params: ApiGetAppsByAuthorQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetAppsByAuthor200Response>('/v1/apps-by-author', {
      headers,
      timeout,
      endpointName: 'getAppsByAuthor',
      params,
    });
  }

  /**
   * Get autocomplete predictions for a location query.
   */
  findLocation(
    params: ApiFindLocationQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFindLocation200Response>('/v2/find-location', {
      headers,
      timeout,
      endpointName: 'findLocation',
      params,
    });
  }

  /**
   * Get balance of warps
   */
  getWarpsBalance(
    params: ApiGetWarpsBalanceQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWarpsBalance200Response>('/v2/warps-balance', {
      headers,
      timeout,
      endpointName: 'getWarpsBalance',
      params,
    });
  }

  /**
   * Get cast moderations
   */
  fcGetModeratedCasts(
    params: ApiFcGetModeratedCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetModeratedCasts200Response>(
      '/fc/moderated-casts',
      {
        headers,
        timeout,
        endpointName: 'fcGetModeratedCasts',
        params,
      },
    );
  }

  /**
   * Get casts corresponding to the specified thread given by the cast hash prefix and username, filtered for relevancy.
   */
  getUserThreadCasts(
    params: ApiGetUserThreadCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserThreadCasts200Response>(
      '/v2/user-thread-casts',
      {
        headers,
        timeout,
        endpointName: 'getUserThreadCasts',
        params,
      },
    );
  }

  /**
   * Get casts corresponding to the specified thread, filtered for relevancy.
   */
  getThread(
    params: ApiGetThreadQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetThread200Response>('/v2/thread-casts', {
      headers,
      timeout,
      endpointName: 'getThread',
      params,
    });
  }

  /**
   * Get channel suggestions during cast compose.
   */
  getComposerChannelSuggestions(
    params: ApiGetComposerChannelSuggestionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetComposerChannelSuggestions200Response>(
      '/v2/composer-channel-suggestions',
      {
        headers,
        timeout,
        endpointName: 'getComposerChannelSuggestions',
        params,
      },
    );
  }

  /**
   * Get combined unseen counts for notifications, direct casts, warps & invites.
   */
  getUnseen({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetUnseen200Response>('/v2/unseen', {
      headers,
      timeout,
      endpointName: 'getUnseen',
    });
  }

  /**
   * Get creator rewards earnings history
   */
  getCreatorRewardsEarningsHistory(
    params: ApiGetCreatorRewardsEarningsHistoryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCreatorRewardsEarningsHistory200Response>(
      '/v1/creator-rewards-earnings-history',
      {
        headers,
        timeout,
        endpointName: 'getCreatorRewardsEarningsHistory',
        params,
      },
    );
  }

  /**
   * Get creator rewards leaderboard
   */
  getCreatorRewardsLeaderboard(
    params: ApiGetCreatorRewardsLeaderboardQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCreatorRewardsLeaderboard200Response>(
      '/v1/creator-rewards-leaderboard',
      {
        headers,
        timeout,
        endpointName: 'getCreatorRewardsLeaderboard',
        params,
      },
    );
  }

  /**
   * Get creator rewards metadata
   */
  getCreatorRewardsMetadata({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetCreatorRewardsMetadata200Response>(
      '/v1/creator-rewards-metadata',
      {
        headers,
        timeout,
        endpointName: 'getCreatorRewardsMetadata',
      },
    );
  }

  /**
   * Get creator rewards payout eligibility for a user
   */
  getCreatorRewardsPayoutEligibilityForUser(
    params: ApiGetCreatorRewardsPayoutEligibilityForUserQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCreatorRewardsPayoutEligibilityForUser200Response>(
      '/v1/creator-rewards-payout-eligibility-for-user',
      {
        headers,
        timeout,
        endpointName: 'getCreatorRewardsPayoutEligibilityForUser',
        params,
      },
    );
  }

  /**
   * Get creator rewards period summary
   */
  getCreatorRewardsPeriodSummary(
    params: ApiGetCreatorRewardsPeriodSummaryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCreatorRewardsPeriodSummary200Response>(
      '/v1/creator-rewards-period-summary',
      {
        headers,
        timeout,
        endpointName: 'getCreatorRewardsPeriodSummary',
        params,
      },
    );
  }

  /**
   * Get creator rewards scores for a user
   */
  getCreatorRewardsForUser(
    params: ApiGetCreatorRewardsForUserQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCreatorRewardsForUser200Response>(
      '/v1/creator-rewards-scores-for-user',
      {
        headers,
        timeout,
        endpointName: 'getCreatorRewardsForUser',
        params,
      },
    );
  }

  /**
   * Get creator rewards winner history
   */
  getCreatorRewardsWinnerHistory(
    params: ApiGetCreatorRewardsWinnerHistoryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCreatorRewardsWinnerHistory200Response>(
      '/v1/creator-rewards-winner-history',
      {
        headers,
        timeout,
        endpointName: 'getCreatorRewardsWinnerHistory',
        params,
      },
    );
  }

  /**
   * Get detailed properties for the muted keyword
   */
  getMutedKeyword(
    params: ApiGetMutedKeywordQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetMutedKeyword200Response>('/v2/muted-keyword', {
      headers,
      timeout,
      endpointName: 'getMutedKeyword',
      params,
    });
  }

  /**
   * Get details about a frame based on a domain
   */
  getFrameDetails(
    params: ApiGetFrameDetailsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFrameDetails200Response>('/v1/frame', {
      headers,
      timeout,
      endpointName: 'getFrameDetails',
      params,
    });
  }

  /**
   * Get details about all non-following channels, and include a summary of the followed ones.
   */
  discoverChannels(
    params: ApiDiscoverChannelsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiDiscoverChannels200Response>(
      '/v2/discover-channels',
      {
        headers,
        timeout,
        endpointName: 'discoverChannels',
        params,
      },
    );
  }

  /**
   * Get details for an existing invite.
   */
  getInvite(
    params: ApiGetInviteQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetInvite200Response>('/v2/invite', {
      headers,
      timeout,
      endpointName: 'getInvite',
      params,
    });
  }

  /**
   * Get developer rewards winner history (public)
   */
  getPublicDeveloperRewardsWinnerHistory(
    params: ApiGetPublicDeveloperRewardsWinnerHistoryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetPublicDeveloperRewardsWinnerHistory200Response>(
      '/v1/developer-rewards-winner-history',
      {
        headers,
        timeout,
        endpointName: 'getPublicDeveloperRewardsWinnerHistory',
        params,
      },
    );
  }

  /**
   * Get direct cast conversation (if authed user has access)
   */
  getDirectCastConversation(
    params: ApiGetDirectCastConversationQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversation200Response>(
      '/v2/direct-cast-conversation',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastConversation',
        params,
      },
    );
  }

  /**
   * Get earnings for the authenticated Farcaster user
   */
  getFarcasterEarnings(
    params: ApiGetFarcasterEarningsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFarcasterEarnings200Response>(
      '/v2/farcaster-earnings',
      {
        headers,
        timeout,
        endpointName: 'getFarcasterEarnings',
        params,
      },
    );
  }

  /**
   * Get extra details about a channel
   */
  getChannelDetails(
    params: ApiGetChannelDetailsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelDetails200Response>(
      '/v1/channel-details',
      {
        headers,
        timeout,
        endpointName: 'getChannelDetails',
        params,
      },
    );
  }

  /**
   * Get favorite frames
   */
  getFavoriteFrames(
    params: ApiGetFavoriteFramesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFavoriteFrames200Response>(
      '/v1/favorite-frames',
      {
        headers,
        timeout,
        endpointName: 'getFavoriteFrames',
        params,
      },
    );
  }

  /**
   * Get featured Frames - to be deprecated
   */
  getFeaturedFrames(
    params: ApiGetFeaturedFramesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFeaturedFrames200Response>(
      '/v1/featured-frameapps',
      {
        headers,
        timeout,
        endpointName: 'getFeaturedFrames',
        params,
      },
    );
  }

  /**
   * Get featured mint information
   */
  getFeaturedMint(
    params: ApiGetFeaturedMintQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFeaturedMint200Response>('/v1/featured-mint', {
      headers,
      timeout,
      endpointName: 'getFeaturedMint',
      params,
    });
  }

  /**
   * Get featured mint transaction data
   */
  getFeaturedMintTransaction(
    params: ApiGetFeaturedMintTransactionQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFeaturedMintTransaction200Response>(
      '/v1/featured-mint-transaction',
      {
        headers,
        timeout,
        endpointName: 'getFeaturedMintTransaction',
        params,
      },
    );
  }

  /**
   * Get feed items, excluding ones the client already has
   */
  getFeedItems(
    body: ApiGetFeedItemsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiGetFeedItems200Response>('/v2/feed-items', {
      headers,
      timeout,
      endpointName: 'getFeedItems',
      body,
    });
  }

  /**
   * Get flag indicating whether invites page has been viewed since nudge indicator shown
   */
  getInvitesViewed({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetInvitesViewed200Response>(
      '/v2/invites-viewed',
      {
        headers,
        timeout,
        endpointName: 'getInvitesViewed',
      },
    );
  }

  /**
   * Get flag indicating whether invites page has been viewed since nudge indicator shown
   */
  setInvitesViewed({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiSetInvitesViewed200Response>('/v2/invites-viewed', {
      headers,
      timeout,
      endpointName: 'setInvitesViewed',
      body: {},
    });
  }

  /**
   * Get frame blocklist
   */
  getFrameBlocklist({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetFrameBlocklist200Response>(
      '/v2/frame-blocklist',
      {
        headers,
        timeout,
        endpointName: 'getFrameBlocklist',
      },
    );
  }

  /**
   * Get global frame analytics data
   */
  getGlobalFrameAnalytics(
    params: ApiGetGlobalFrameAnalyticsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetGlobalFrameAnalytics200Response>(
      '/v2/global-frame-analytics',
      {
        headers,
        timeout,
        endpointName: 'getGlobalFrameAnalytics',
        params,
      },
    );
  }

  /**
   * Get hidden replies for a thread of a focused cast
   */
  getUserThreadHiddenReplies(
    params: ApiGetUserThreadHiddenRepliesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserThreadHiddenReplies200Response>(
      '/v1/user-thread-hidden-replies',
      {
        headers,
        timeout,
        endpointName: 'getUserThreadHiddenReplies',
        params,
      },
    );
  }

  /**
   * Get hosts for a channel
   */
  getChannelHosts(
    params: ApiGetChannelHostsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelHosts200Response>(
      '/v2/get-channel-hosts',
      {
        headers,
        timeout,
        endpointName: 'getChannelHosts',
        params,
      },
    );
  }

  /**
   * Get information about Starter Pack
   */
  getStarterPack(
    params: ApiGetStarterPackQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetStarterPack200Response>('/v2/starter-pack', {
      headers,
      timeout,
      endpointName: 'getStarterPack',
      params,
    });
  }

  /**
   * Get information about a Farcaster action
   */
  getFarcasterAction(
    params: ApiGetFarcasterActionQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFarcasterAction200Response>(
      '/v2/farcaster-action',
      {
        headers,
        timeout,
        endpointName: 'getFarcasterAction',
        params,
      },
    );
  }

  /**
   * Get information about a Farcaster composer action
   */
  getComposerAction(
    params: ApiGetComposerActionQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetComposerAction200Response>(
      '/v2/composer-action',
      {
        headers,
        timeout,
        endpointName: 'getComposerAction',
        params,
      },
    );
  }

  /**
   * Get information about a Farcaster username (fname).
   */
  getFname(
    params: ApiGetFnameQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFname200Response>('/v2/fname', {
      headers,
      timeout,
      endpointName: 'getFname',
      params,
    });
  }

  /**
   * Get information about a discovery app
   */
  getDiscoveryApp(
    params: ApiGetDiscoveryAppQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDiscoveryApp200Response>('/v2/discovery-app', {
      headers,
      timeout,
      endpointName: 'getDiscoveryApp',
      params,
    });
  }

  /**
   * Get information about a discovery frame
   */
  getDiscoveryFrame(
    params: ApiGetDiscoveryFrameQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDiscoveryFrame200Response>(
      '/v2/discovery-frame',
      {
        headers,
        timeout,
        endpointName: 'getDiscoveryFrame',
        params,
      },
    );
  }

  /**
   * Get information about a user's eligibility to exchange warps for USDC.
   */
  getWarpsTradeStatus({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetWarpsTradeStatus200Response>(
      '/v2/warps/exchange/status',
      {
        headers,
        timeout,
        endpointName: 'getWarpsTradeStatus',
      },
    );
  }

  /**
   * Get information about creating a channel, including cost
   */
  getChannelCreationInfo({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetChannelCreationInfo200Response>(
      '/v2/channel-creation-info',
      {
        headers,
        timeout,
        endpointName: 'getChannelCreationInfo',
      },
    );
  }

  /**
   * Get information about the AMA
   */
  getAMA(
    params: ApiGetAMAQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetAMA200Response>('/v2/ama', {
      headers,
      timeout,
      endpointName: 'getAMA',
      params,
    });
  }

  /**
   * Get invites for a channel
   */
  fcGetChannelInvites(
    params: ApiFcGetChannelInvitesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetChannelInvites200Response>(
      '/fc/channel-invites',
      {
        headers,
        timeout,
        endpointName: 'fcGetChannelInvites',
        params,
      },
    );
  }

  /**
   * Get least interacted with users, user is following.
   */
  getLeastInteractedWithFollowing(
    params: ApiGetLeastInteractedWithFollowingQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetLeastInteractedWithFollowing200Response>(
      '/v2/least-interacted-with-following',
      {
        headers,
        timeout,
        endpointName: 'getLeastInteractedWithFollowing',
        params,
      },
    );
  }

  /**
   * Get list of channel in which the user can cast a root cast
   */
  getUserCastableChannels(
    params: ApiGetUserCastableChannelsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserCastableChannels200Response>(
      '/v2/user-following-channels',
      {
        headers,
        timeout,
        endpointName: 'getUserCastableChannels',
        params,
      },
    );
  }

  /**
   * Get list of channels user follow, is a member or moderate.
   */
  getUserChannels(
    params: ApiGetUserChannelsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserChannels200Response>('/v2/user-channels', {
      headers,
      timeout,
      endpointName: 'getUserChannels',
      params,
    });
  }

  /**
   * Get live featured hero apps
   */
  getFeaturedHeroApps({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetFeaturedHeroApps200Response>(
      '/v1/featured-hero-apps',
      {
        headers,
        timeout,
        endpointName: 'getFeaturedHeroApps',
      },
    );
  }

  /**
   * Get matching tokens for a linked ticker
   */
  getTokenLinks(
    params: ApiGetTokenLinksQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetTokenLinks200Response>('/v2/token-links', {
      headers,
      timeout,
      endpointName: 'getTokenLinks',
      params,
    });
  }

  /**
   * Get members of a channel
   */
  fcGetChannelMembers(
    params: ApiFcGetChannelMembersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetChannelMembers200Response>(
      '/fc/channel-members',
      {
        headers,
        timeout,
        endpointName: 'fcGetChannelMembers',
        params,
      },
    );
  }

  /**
   * Get message hashes needed to perform a registration on behalf an address.
   */
  generateRegistrationHashes(
    body: ApiGenerateRegistrationHashesRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiGenerateRegistrationHashes200Response>(
      '/v2/registration-hashes',
      {
        headers,
        timeout,
        endpointName: 'generateRegistrationHashes',
        body,
      },
    );
  }

  /**
   * Get metadata about a channel when editing it
   */
  getChannelSettings(
    params: ApiGetChannelSettingsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelSettings200Response>(
      '/v2/channels-owned',
      {
        headers,
        timeout,
        endpointName: 'getChannelSettings',
        params,
      },
    );
  }

  /**
   * Get metadata about channel.
   */
  getChannel(
    params: ApiGetChannelQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannel200Response>('/v2/channel', {
      headers,
      timeout,
      endpointName: 'getChannel',
      params,
    });
  }

  /**
   * Get muted keywords for a user
   */
  getMutedKeywords({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetMutedKeywords200Response>(
      '/v2/get-muted-keywords',
      {
        headers,
        timeout,
        endpointName: 'getMutedKeywords',
      },
    );
  }

  /**
   * Get notifications for a given tab
   */
  getNotificationsForTab(
    params: ApiGetNotificationsForTabQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetNotificationsForTab200Response>(
      '/v1/notifications-for-tab',
      {
        headers,
        timeout,
        endpointName: 'getNotificationsForTab',
        params,
      },
    );
  }

  /**
   * Get notifications within the specified group.
   */
  getNotificationsInGroup(
    params: ApiGetNotificationsInGroupQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetNotificationsInGroup200Response>(
      '/v2/notification-group',
      {
        headers,
        timeout,
        endpointName: 'getNotificationsInGroup',
        params,
      },
    );
  }

  /**
   * Get paid invite warps offering.
   */
  getInviteWithWarpsOffering({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetInviteWithWarpsOffering200Response>(
      '/v2/invite-with-warps-offering',
      {
        headers,
        timeout,
        endpointName: 'getInviteWithWarpsOffering',
      },
    );
  }

  /**
   * Get pending admin reviews
   */
  getPendingAdminReviews(
    params: ApiGetPendingAdminReviewsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetPendingAdminReviews200Response>(
      '/v2/pending-admin-reviews',
      {
        headers,
        timeout,
        endpointName: 'getPendingAdminReviews',
        params,
      },
    );
  }

  /**
   * Get pending invites for a direct cast group.
   */
  getDirectCastGroupInvites(
    params: ApiGetDirectCastGroupInvitesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastGroupInvites200Response>(
      '/v2/direct-cast-group-invites',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastGroupInvites',
        params,
      },
    );
  }

  /**
   * Get phone verification status
   */
  getPhoneVerificationStatus({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetPhoneVerificationStatus200Response>(
      '/v2/quests/phone-verification',
      {
        headers,
        timeout,
        endpointName: 'getPhoneVerificationStatus',
      },
    );
  }

  /**
   * Get poll results
   */
  getPollResults(
    params: ApiGetPollResultsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetPollResults200Response>(
      '/v2/get-poll-results',
      {
        headers,
        timeout,
        endpointName: 'getPollResults',
        params,
      },
    );
  }

  /**
   * Get primary address for a user
   */
  getPrimaryAddress(
    params: ApiGetPrimaryAddressQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetPrimaryAddress200Response>(
      '/v2/get-primary-address',
      {
        headers,
        timeout,
        endpointName: 'getPrimaryAddress',
        params,
      },
    );
  }

  /**
   * Get public keys related to direct cast conversations (deprecated).
   */
  getDirectCastKeys(
    params: ApiGetDirectCastKeysQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastKeys200Response>(
      '/v2/direct-cast-keys',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastKeys',
        params,
      },
    );
  }

  /**
   * Get public keys related to direct cast conversations. (deprecated)
   */
  getDirectCastKeysByAccount(
    params: ApiGetDirectCastKeysByAccountQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastKeysByAccount200Response>(
      '/v2/direct-cast-device-keys',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastKeysByAccount',
        params,
      },
    );
  }

  /**
   * Get quest for a user
   */
  getQuest(
    params: ApiGetQuestQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetQuest200Response>('/v2/quest', {
      headers,
      timeout,
      endpointName: 'getQuest',
      params,
    });
  }

  /**
   * Get quests for a user
   */
  getQuests({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetQuests200Response>('/v2/quests', {
      headers,
      timeout,
      endpointName: 'getQuests',
    });
  }

  /**
   * Get recently launched frames
   */
  getRecentlyLaunchedFrames(
    params: ApiGetRecentlyLaunchedFramesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRecentlyLaunchedFrames200Response>(
      '/v1/recently-launched-frames',
      {
        headers,
        timeout,
        endpointName: 'getRecentlyLaunchedFrames',
        params,
      },
    );
  }

  /**
   * Get recommendations for channels to follow, and include a summary of the followed ones.
   */
  recommendedChannels(
    params: ApiRecommendedChannelsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiRecommendedChannels200Response>(
      '/v2/channel-recommendations',
      {
        headers,
        timeout,
        endpointName: 'recommendedChannels',
        params,
      },
    );
  }

  /**
   * Get recommended channels based on cast text
   */
  getChannelRecsForCast(
    params: ApiGetChannelRecsForCastQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelRecsForCast200Response>(
      '/v2/get-channel-recs-for-cast',
      {
        headers,
        timeout,
        endpointName: 'getChannelRecsForCast',
        params,
      },
    );
  }

  /**
   * Get recover EIP-712 message hash
   */
  getRecoverHash(
    params: ApiGetRecoverHashQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRecoverHash200Response>('/v2/recover-hash', {
      headers,
      timeout,
      endpointName: 'getRecoverHash',
      params,
    });
  }

  /**
   * Get restricted users in a channel
   */
  fcGetChannelRestrictedUsers(
    params: ApiFcGetChannelRestrictedUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetChannelRestrictedUsers200Response>(
      '/fc/channel-restricted-users',
      {
        headers,
        timeout,
        endpointName: 'fcGetChannelRestrictedUsers',
        params,
      },
    );
  }

  /**
   * Get rewards earnings history
   */
  getRewardsEarningsHistory(
    params: ApiGetRewardsEarningsHistoryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRewardsEarningsHistory200Response>(
      '/v2/rewards-earnings-history',
      {
        headers,
        timeout,
        endpointName: 'getRewardsEarningsHistory',
        params,
      },
    );
  }

  /**
   * Get rewards leaderboard
   */
  getRewardsLeaderboard(
    params: ApiGetRewardsLeaderboardQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRewardsLeaderboard200Response>(
      '/v2/rewards-leaderboard',
      {
        headers,
        timeout,
        endpointName: 'getRewardsLeaderboard',
        params,
      },
    );
  }

  /**
   * Get rewards metadata
   */
  getRewardsMetadata(
    params: ApiGetRewardsMetadataQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRewardsMetadata200Response>(
      '/v2/rewards-metadata',
      {
        headers,
        timeout,
        endpointName: 'getRewardsMetadata',
        params,
      },
    );
  }

  /**
   * Get rewards payout eligibility for the authed user
   */
  getRewardsPayoutEligibility({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetRewardsPayoutEligibility200Response>(
      '/v2/rewards-payout-eligibility',
      {
        headers,
        timeout,
        endpointName: 'getRewardsPayoutEligibility',
      },
    );
  }

  /**
   * Get rewards period summary
   */
  getRewardsPeriodSummary(
    params: ApiGetRewardsPeriodSummaryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRewardsPeriodSummary200Response>(
      '/v2/rewards-period-summary',
      {
        headers,
        timeout,
        endpointName: 'getRewardsPeriodSummary',
        params,
      },
    );
  }

  /**
   * Get rewards scores for a user
   */
  getRewardsScoresForUser(
    params: ApiGetRewardsScoresForUserQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRewardsScoresForUser200Response>(
      '/v2/rewards-scores-for-user',
      {
        headers,
        timeout,
        endpointName: 'getRewardsScoresForUser',
        params,
      },
    );
  }

  /**
   * Get rewards winner history
   */
  getRewardsWinnerHistory(
    params: ApiGetRewardsWinnerHistoryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRewardsWinnerHistory200Response>(
      '/v2/rewards-winner-history',
      {
        headers,
        timeout,
        endpointName: 'getRewardsWinnerHistory',
        params,
      },
    );
  }

  /**
   * Get starter pack users
   */
  getStarterPackUsers(
    params: ApiGetStarterPackUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetStarterPackUsers200Response>(
      '/v2/starter-pack-users',
      {
        headers,
        timeout,
        endpointName: 'getStarterPackUsers',
        params,
      },
    );
  }

  /**
   * Get starter packs created by FID
   */
  getStarterPacks(
    params: ApiGetStarterPacksQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetStarterPacks200Response>('/v2/starter-packs', {
      headers,
      timeout,
      endpointName: 'getStarterPacks',
      params,
    });
  }

  /**
   * Get storage utilization for a user
   */
  getStorageUtilization({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetStorageUtilization200Response>(
      '/v2/storage-utilization',
      {
        headers,
        timeout,
        endpointName: 'getStorageUtilization',
      },
    );
  }

  /**
   * Get suggested starter packs on the network for user
   */
  getSuggestedStarterPacks(
    params: ApiGetSuggestedStarterPacksQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSuggestedStarterPacks200Response>(
      '/v2/starter-packs/suggested',
      {
        headers,
        timeout,
        endpointName: 'getSuggestedStarterPacks',
        params,
      },
    );
  }

  /**
   * Get suggested users for the authenticated user to follow.
   */
  getSuggestedUsers(
    params: ApiGetSuggestedUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSuggestedUsers200Response>(
      '/v2/suggested-users',
      {
        headers,
        timeout,
        endpointName: 'getSuggestedUsers',
        params,
      },
    );
  }

  /**
   * Get suggested users to tip
   */
  getSuggestedKudos(
    params: ApiGetSuggestedKudosQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSuggestedKudos200Response>(
      '/v2/farcaster-kudos/suggested-users',
      {
        headers,
        timeout,
        endpointName: 'getSuggestedKudos',
        params,
      },
    );
  }

  /**
   * Get the admin home feed
   */
  getAdminFeed(
    params: ApiGetAdminFeedQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetAdminFeed200Response>('/v2/admin-feed', {
      headers,
      timeout,
      endpointName: 'getAdminFeed',
      params,
    });
  }

  /**
   * Get the affinity scores between a user and the people they follow
   */
  getFollowedUsersAffinityScore(
    params: ApiGetFollowedUsersAffinityScoreQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFollowedUsersAffinityScore200Response>(
      '/v1/followed-users-affinity-scores',
      {
        headers,
        timeout,
        endpointName: 'getFollowedUsersAffinityScore',
        params,
      },
    );
  }

  /**
   * Get the authenticated requester's available invites count.
   */
  getInvitesAvailable({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetInvitesAvailable200Response>(
      '/v2/invites-available',
      {
        headers,
        timeout,
        endpointName: 'getInvitesAvailable',
      },
    );
  }

  /**
   * Get the authenticated requester's warpcast sponsored invites.
   */
  getWarpcastSponsoredInvites({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetWarpcastSponsoredInvites200Response>(
      '/v2/warpcast-sponsored-invites',
      {
        headers,
        timeout,
        endpointName: 'getWarpcastSponsoredInvites',
      },
    );
  }

  /**
   * Get the channels highlighted for the authenticated user
   */
  getHighlightedChannels({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetHighlightedChannels200Response>(
      '/v2/highlighted-channels',
      {
        headers,
        timeout,
        endpointName: 'getHighlightedChannels',
      },
    );
  }

  /**
   * Get the custody address associated with an FID.
   */
  getCustodyAddress(
    params: ApiGetCustodyAddressQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetCustodyAddress200Response>(
      '/v2/custody-address',
      {
        headers,
        timeout,
        endpointName: 'getCustodyAddress',
        params,
      },
    );
  }

  /**
   * Get the feeds the authenticated user is following, including non-subscribable.
   */
  getFeedSummaries({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetFeedSummaries200Response>('/v2/feeds', {
      headers,
      timeout,
      endpointName: 'getFeedSummaries',
    });
  }

  /**
   * Get the history of home feed generations and casts included in the home feed
   */
  getHomeFeedGenerationHistory(
    params: ApiGetHomeFeedGenerationHistoryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetHomeFeedGenerationHistory200Response>(
      '/v1/home-feed-history',
      {
        headers,
        timeout,
        endpointName: 'getHomeFeedGenerationHistory',
        params,
      },
    );
  }

  /**
   * Get the most active feeds for a user
   */
  getMostActiveChannels(
    params: ApiGetMostActiveChannelsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetMostActiveChannels200Response>(
      '/v2/most-active-channels',
      {
        headers,
        timeout,
        endpointName: 'getMostActiveChannels',
        params,
      },
    );
  }

  /**
   * Get the non-signed delegate signer add message hash managed by Merkle for the authenticated user.
   */
  getDelegateSigner({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetDelegateSigner200Response>(
      '/v2/delegate-signer',
      {
        headers,
        timeout,
        endpointName: 'getDelegateSigner',
      },
    );
  }

  /**
   * Get the number of page views per feed for a user
   */
  getFeedPageViews(
    params: ApiGetFeedPageViewsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetFeedPageViews200Response>(
      '/v2/feed-page-views',
      {
        headers,
        timeout,
        endpointName: 'getFeedPageViews',
        params,
      },
    );
  }

  /**
   * Get the open graph metadata for a URL
   */
  devToolsGetOpenGraphMetadata(
    params: ApiDevToolsGetOpenGraphMetadataQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiDevToolsGetOpenGraphMetadata200Response>(
      '/v1/dev-tools/open-graph-metadata',
      {
        headers,
        timeout,
        endpointName: 'devToolsGetOpenGraphMetadata',
        params,
      },
    );
  }

  /**
   * Get the replies to a conversation cast for interactive expansion
   */
  getConversationCastReplies(
    params: ApiGetConversationCastRepliesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetConversationCastReplies200Response>(
      '/v1/conversation-cast-replies',
      {
        headers,
        timeout,
        endpointName: 'getConversationCastReplies',
        params,
      },
    );
  }

  /**
   * Get the replies to a conversation cast for interactive expansion. Excludes replies by the focus cast author, followed users, and viewer since those should have been returned by the thread endpoint
   */
  getConversationCastRepliesObsolete(
    params: ApiGetConversationCastRepliesObsoleteQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetConversationCastRepliesObsolete200Response>(
      '/v2/conversation-cast-replies',
      {
        headers,
        timeout,
        endpointName: 'getConversationCastRepliesObsolete',
        params,
      },
    );
  }

  /**
   * Get the state of an onchain action
   */
  getOnchainAction(
    params: ApiGetOnchainActionQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetOnchainAction200Response>(
      '/v2/onchain-action',
      {
        headers,
        timeout,
        endpointName: 'getOnchainAction',
        params,
      },
    );
  }

  /**
   * Get the state of an uploaded video
   */
  getVideoState(
    params: ApiGetVideoStateQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetVideoState200Response>('/v1/uploaded-video', {
      headers,
      timeout,
      endpointName: 'getVideoState',
      params,
    });
  }

  /**
   * Get the user's primary address
   */
  publicGetPrimaryAddress(
    params: ApiPublicGetPrimaryAddressQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiPublicGetPrimaryAddress200Response>(
      '/fc/primary-address',
      {
        headers,
        timeout,
        endpointName: 'publicGetPrimaryAddress',
        params,
      },
    );
  }

  /**
   * Get the user's primary addresss
   */
  publicGetPrimaryAddresses(
    params: ApiPublicGetPrimaryAddressesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiPublicGetPrimaryAddresses200Response>(
      '/fc/primary-addresses',
      {
        headers,
        timeout,
        endpointName: 'publicGetPrimaryAddresses',
        params,
      },
    );
  }

  /**
   * Get the value of all dynamic configs
   */
  getDynamicConfigs({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetDynamicConfigs200Response>(
      '/v1/dynamic-configs',
      {
        headers,
        timeout,
        endpointName: 'getDynamicConfigs',
      },
    );
  }

  /**
   * Get time-sorted list of events around channels
   */
  fcGetChannelEvents(
    params: ApiFcGetChannelEventsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetChannelEvents200Response>(
      '/fc/channel-events',
      {
        headers,
        timeout,
        endpointName: 'fcGetChannelEvents',
        params,
      },
    );
  }

  /**
   * Get time-sorted list of primary address events
   */
  fcGetPrimaryAddressEvents(
    params: ApiFcGetPrimaryAddressEventsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetPrimaryAddressEvents200Response>(
      '/fc/primary-address-events',
      {
        headers,
        timeout,
        endpointName: 'fcGetPrimaryAddressEvents',
        params,
      },
    );
  }

  /**
   * Get token by chain and contract address
   */
  getToken(
    params: ApiGetTokenQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetToken200Response>('/v2/token', {
      headers,
      timeout,
      endpointName: 'getToken',
      params,
    });
  }

  /**
   * Get token chart by chain, contract address, and period
   */
  getTokenChart(
    params: ApiGetTokenChartQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetTokenChart200Response>('/v2/token-chart', {
      headers,
      timeout,
      endpointName: 'getTokenChart',
      params,
    });
  }

  /**
   * Get token holders by chain and contract address
   */
  getTokenHolders(
    params: ApiGetTokenHoldersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetTokenHolders200Response>('/v2/token-holders', {
      headers,
      timeout,
      endpointName: 'getTokenHolders',
      params,
    });
  }

  /**
   * Get token info for a swap
   */
  getSwapToken(
    params: ApiGetSwapTokenQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSwapToken200Response>('/v2/swaps/token', {
      headers,
      timeout,
      endpointName: 'getSwapToken',
      params,
    });
  }

  /**
   * Get top Frames based on developer rewards scores.
   */
  getTopFrames(
    params: ApiGetTopFramesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetTopFrames200Response>('/v1/top-frameapps', {
      headers,
      timeout,
      endpointName: 'getTopFrames',
      params,
    });
  }

  /**
   * Get top casters for the channel.
   */
  getChannelTopCasters(
    params: ApiGetChannelTopCastersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelTopCasters200Response>(
      '/v2/channel-top-casters',
      {
        headers,
        timeout,
        endpointName: 'getChannelTopCasters',
        params,
      },
    );
  }

  /**
   * Get top inviters with score
   */
  getInvitersLeaderboard({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetInvitersLeaderboard200Response>(
      '/v2/inviters-leaderboard',
      {
        headers,
        timeout,
        endpointName: 'getInvitersLeaderboard',
      },
    );
  }

  /**
   * Get user account verifications attested by Warpcast
   */
  fcGetAccountVerifications(
    params: ApiFcGetAccountVerificationsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetAccountVerifications200Response>(
      '/fc/account-verifications',
      {
        headers,
        timeout,
        endpointName: 'fcGetAccountVerifications',
        params,
      },
    );
  }

  /**
   * Get whether the specified email address has already been invited.
   */
  getIsUserInvited(
    params: ApiGetIsUserInvitedQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetIsUserInvited200Response>('/v2/invites', {
      headers,
      timeout,
      endpointName: 'getIsUserInvited',
      params,
    });
  }

  /**
   * Gets a single cast for a specific user using a shortened hash.
   */
  getUserCast(
    params: ApiGetUserCastQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserCast200Response>('/v2/user-cast', {
      headers,
      timeout,
      endpointName: 'getUserCast',
      params,
    });
  }

  /**
   * Gets all casts (including replies and recasts) created by the specified user.
   */
  getUserCastsAndReplies(
    params: ApiGetUserCastsAndRepliesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserCastsAndReplies200Response>('/v2/casts', {
      headers,
      timeout,
      endpointName: 'getUserCastsAndReplies',
      params,
    });
  }

  /**
   * Gets an approval message hash for a recovery address change
   */
  getRecoveryAddressChangeHash(
    params: ApiGetRecoveryAddressChangeHashQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRecoveryAddressChangeHash200Response>(
      '/v2/recovery-address-change-hash',
      {
        headers,
        timeout,
        endpointName: 'getRecoveryAddressChangeHash',
        params,
      },
    );
  }

  /**
   * Gets casts that are new conversations (i.e. are not replies or recasts) created by the specified user.
   */
  getUserCasts(
    params: ApiGetUserCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserCasts200Response>('/v2/profile-casts', {
      headers,
      timeout,
      endpointName: 'getUserCasts',
      params,
    });
  }

  /**
   * Gets details of a signer.
   */
  getSigner(
    params: ApiGetSignerQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSigner200Response>('/v2/signer', {
      headers,
      timeout,
      endpointName: 'getSigner',
      params,
    });
  }

  /**
   * Gets details of a signer.
   */
  getSignerRemoveHash(
    params: ApiGetSignerRemoveHashQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSignerRemoveHash200Response>(
      '/v2/signer-remove-hash',
      {
        headers,
        timeout,
        endpointName: 'getSignerRemoveHash',
        params,
      },
    );
  }

  /**
   * Gets information about a recovery address change
   */
  getRecoveryAddressChange(
    params: ApiGetRecoveryAddressChangeQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRecoveryAddressChange200Response>(
      '/v2/recovery-address-changes',
      {
        headers,
        timeout,
        endpointName: 'getRecoveryAddressChange',
        params,
      },
    );
  }

  /**
   * Gets information about application feature flags for the authenticated user.
   */
  getClientConfig({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetClientConfig200Response>('/v2/client-config', {
      headers,
      timeout,
      endpointName: 'getClientConfig',
    });
  }

  /**
   * Gets invite info for a direct cast group.
   */
  getDirectCastGroupInviteV3(
    params: ApiGetDirectCastGroupInviteV3QueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastGroupInviteV3200Response>(
      '/v2/direct-cast-group-invite',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastGroupInviteV3',
        params,
      },
    );
  }

  /**
   * Gets the current onboarding state for the authenticated user and creates an auth token if possible.,  * This is an optimization to reduce the number of requests the client needs to make during initial,  * onboarding.
   */
  getOnboardingStateAndAuthToken(
    body: ApiGetOnboardingStateAndAuthTokenRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiGetOnboardingStateAndAuthToken200Response>(
      '/v2/onboarding-state',
      {
        headers,
        timeout,
        endpointName: 'getOnboardingStateAndAuthToken',
        body,
      },
    );
  }

  /**
   * Gets the current onboarding state for the authenticated user.
   */
  getOnboardingState({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetOnboardingState200Response>(
      '/v2/onboarding-state',
      {
        headers,
        timeout,
        endpointName: 'getOnboardingState',
      },
    );
  }

  /**
   * Gets the currently authenticated user.
   */
  getAuthenticatedUser({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetAuthenticatedUser200Response>('/v2/me', {
      headers,
      timeout,
      endpointName: 'getAuthenticatedUser',
    });
  }

  /**
   * Gets the direct cast conversations for the requesting user.
   */
  getDirectCastConversationsV3(
    params: ApiGetDirectCastConversationsV3QueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationsV3200Response>(
      '/v2/direct-cast-conversation-list',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastConversationsV3',
        params,
      },
    );
  }

  /**
   * Gets the direct casts for the specified conversation.
   */
  getDirectCastConversationMessages(
    params: ApiGetDirectCastConversationMessagesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationMessages200Response>(
      '/v2/direct-cast-conversation-messages',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastConversationMessages',
        params,
      },
    );
  }

  /**
   * Gets the direct casts for the specified conversation. (deprecated)
   */
  getDirectCastConversationV3(
    params: ApiGetDirectCastConversationV3QueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationV3200Response>(
      '/v2/direct-cast-conversation-details',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastConversationV3',
        params,
      },
    );
  }

  /**
   * Gets the inbox conversations for the requesting user.
   */
  getDirectCastInbox(
    params: ApiGetDirectCastInboxQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastInbox200Response>(
      '/v2/direct-cast-inbox',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastInbox',
        params,
      },
    );
  }

  /**
   * Gets the recent direct casts for the specified conversation.
   */
  getDirectCastConversationRecentMessages(
    params: ApiGetDirectCastConversationRecentMessagesQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationRecentMessages200Response>(
      '/v2/direct-cast-conversation-recent-messages',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastConversationRecentMessages',
        params,
      },
    );
  }

  /**
   * Gets the recovery address for the authenticated user
   */
  getRecoveryAddress({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetRecoveryAddress200Response>(
      '/v2/recovery-address',
      {
        headers,
        timeout,
        endpointName: 'getRecoveryAddress',
      },
    );
  }

  /**
   * Gets the specified user via their FID through replica.
   */
  getUserByFID(
    params: ApiGetUserByFIDQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserByFID200Response>('/v2/user-by-fid', {
      headers,
      timeout,
      endpointName: 'getUserByFID',
      params,
    });
  }

  /**
   * Gets the specified user via their FID. (Dangerous)
   */
  getUser(
    params: ApiGetUserQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUser200Response>('/v2/user', {
      headers,
      timeout,
      endpointName: 'getUser',
      params,
    });
  }

  /**
   * Gets the specified user via their username.
   */
  getUserByUsername(
    params: ApiGetUserByUsernameQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserByUsername200Response>(
      '/v2/user-by-username',
      {
        headers,
        timeout,
        endpointName: 'getUserByUsername',
        params,
      },
    );
  }

  /**
   * Gets the user who has most recently verified ownership of the specified ETH address.
   */
  getUserByVerification(
    params: ApiGetUserByVerificationQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserByVerification200Response>(
      '/v2/user-by-verification',
      {
        headers,
        timeout,
        endpointName: 'getUserByVerification',
        params,
      },
    );
  }

  /**
   * Gift warps between users
   */
  giftWarps(
    body: ApiGiftWarpsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiGiftWarps200Response>('/v2/gift-warps', {
      headers,
      timeout,
      endpointName: 'giftWarps',
      body,
    });
  }

  /**
   * GitHub auth callbacks
   */
  authenticateGitHub({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<void>('/auth/gh', {
      headers,
      timeout,
      endpointName: 'authenticateGitHub',
    });
  }

  /**
   * Handler for gift 10 warps Farcaster action
   */
  tip10WarpsAction(
    body: ApiTip10WarpsActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<void>('/v2/tip-10-warps-action', {
      headers,
      timeout,
      endpointName: 'tip10WarpsAction',
      body,
    });
  }

  /**
   * History of warps for given authenticated user.
   */
  getWarpTransactions(
    params: ApiGetWarpTransactionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWarpTransactions200Response>(
      '/v2/warp-transactions',
      {
        headers,
        timeout,
        endpointName: 'getWarpTransactions',
        params,
      },
    );
  }

  /**
   * Information about buying Warps with Coinbase Commerce.
   */
  buyWarpsCoinbaseCommerceInfo({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiBuyWarpsCoinbaseCommerceInfo200Response>(
      '/v2/buy-warps-coinbase-commerce',
      {
        headers,
        timeout,
        endpointName: 'buyWarpsCoinbaseCommerceInfo',
      },
    );
  }

  /**
   * Initiate a new recovery
   */
  initiateRecovery(
    body: ApiInitiateRecoveryRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiInitiateRecovery200Response>('/v2/initiate-recovery', {
      headers,
      timeout,
      endpointName: 'initiateRecovery',
      body,
    });
  }

  /**
   * Initiate a signer key request for a Warpcast signer.
   */
  createWarpcastSignedKeyRequest({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiCreateWarpcastSignedKeyRequest200Response>(
      '/v2/warpcast-signed-key-request',
      {
        headers,
        timeout,
        endpointName: 'createWarpcastSignedKeyRequest',
        body: {},
      },
    );
  }

  /**
   * Insert a featured app
   */
  insertFeaturedApp(
    body: ApiInsertFeaturedAppRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiInsertFeaturedApp200Response>(
      '/v1/featured-app-admin/insert',
      {
        headers,
        timeout,
        endpointName: 'insertFeaturedApp',
        body,
      },
    );
  }

  /**
   * Invite a user to a channel as a member or moderator
   */
  inviteChannelUserToRole(
    body: ApiInviteChannelUserToRoleRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiInviteChannelUserToRole200Response>(
      '/v1/manage-channel-users',
      {
        headers,
        timeout,
        endpointName: 'inviteChannelUserToRole',
        body,
      },
    );
  }

  /**
   * Invite a user to a channel as a member or moderator (public signer version)
   */
  fcInviteUserToChannelRole(
    body: ApiFcInviteUserToChannelRoleRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFcInviteUserToChannelRole200Response>(
      '/fc/channel-invites',
      {
        headers,
        timeout,
        endpointName: 'fcInviteUserToChannelRole',
        body,
      },
    );
  }

  /**
   * Invite a user to a direct cast group.
   */
  fcPutGroupInvites(
    body: ApiFcPutGroupInvitesRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiFcPutGroupInvites200Response>('/fc/group-invites', {
      headers,
      timeout,
      endpointName: 'fcPutGroupInvites',
      body,
    });
  }

  /**
   * Join a channel via an invite code
   */
  joinChannelViaCode(
    body: ApiJoinChannelViaCodeRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiJoinChannelViaCode200Response>(
      '/v1/join-channel-via-code',
      {
        headers,
        timeout,
        endpointName: 'joinChannelViaCode',
        body,
      },
    );
  }

  /**
   * Limits the content visibility between users.
   */
  limitVisibility(
    body: ApiLimitVisibilityRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiLimitVisibility200Response>('/v2/limit-visibility', {
      headers,
      timeout,
      endpointName: 'limitVisibility',
      body,
    });
  }

  /**
   * LinkedIn auth callbacks
   */
  authenticateLinkedIn({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<void>('/auth/linkedin', {
      headers,
      timeout,
      endpointName: 'authenticateLinkedIn',
    });
  }

  /**
   * List and search for channel banned users
   */
  fcGetChannelBannedUsers(
    params: ApiFcGetChannelBannedUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetChannelBannedUsers200Response>(
      '/fc/channel-bans',
      {
        headers,
        timeout,
        endpointName: 'fcGetChannelBannedUsers',
        params,
      },
    );
  }

  /**
   * List and search for channel banned users
   */
  getChannelBannedUsers(
    params: ApiGetChannelBannedUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelBannedUsers200Response>(
      '/v1/channel-banned-users',
      {
        headers,
        timeout,
        endpointName: 'getChannelBannedUsers',
        params,
      },
    );
  }

  /**
   * List and search members and directly invitable moderators of a channel
   */
  getChannelUsersForManagement(
    params: ApiGetChannelUsersForManagementQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelUsersForManagement200Response>(
      '/v1/manage-channel-users',
      {
        headers,
        timeout,
        endpointName: 'getChannelUsersForManagement',
        params,
      },
    );
  }

  /**
   * List and search users to invite to a channel
   */
  getChannelUsersForInvite(
    params: ApiGetChannelUsersForInviteQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelUsersForInvite200Response>(
      '/v1/invite-channel-users',
      {
        headers,
        timeout,
        endpointName: 'getChannelUsersForInvite',
        params,
      },
    );
  }

  /**
   * List or seach for supported tokens for swaps
   */
  getSwapTokens(
    params: ApiGetSwapTokensQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSwapTokens200Response>('/v2/swaps/tokens', {
      headers,
      timeout,
      endpointName: 'getSwapTokens',
      params,
    });
  }

  /**
   * List or search for followers and/or members of a channel
   */
  getChannelUsers(
    params: ApiGetChannelUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelUsers200Response>('/v1/channel-users', {
      headers,
      timeout,
      endpointName: 'getChannelUsers',
      params,
    });
  }

  /**
   * List tokens eligible for gasless swaps
   */
  getSwapGaslessTokens(
    params: ApiGetSwapGaslessTokensQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSwapGaslessTokens200Response>(
      '/v2/swaps/gasless-tokens',
      {
        headers,
        timeout,
        endpointName: 'getSwapGaslessTokens',
        params,
      },
    );
  }

  /**
   * Mark a direct cast mentions as read.
   */
  fcPostMarkMentionsAsRead({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiFcPostMarkMentionsAsRead200Response>(
      '/fc/mark-mentions-as-read',
      {
        headers,
        timeout,
        endpointName: 'fcPostMarkMentionsAsRead',
        body: {},
      },
    );
  }

  /**
   * Mark a peer-to-peer payment as completed
   */
  completePeerToPeerPayment(
    body: ApiCompletePeerToPeerPaymentRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiCompletePeerToPeerPayment200Response>(
      '/v2/complete-peer-to-peer-payment',
      {
        headers,
        timeout,
        endpointName: 'completePeerToPeerPayment',
        body,
      },
    );
  }

  /**
   * Mark all notifications as read.
   */
  markAllNotificationsRead({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiMarkAllNotificationsRead200Response>(
      '/v2/mark-all-notifications-read',
      {
        headers,
        timeout,
        endpointName: 'markAllNotificationsRead',
        body: {},
      },
    );
  }

  /**
   * Mark all notifications in a tab as seen.
   */
  markAllTabNotificationsSeen(
    body: ApiMarkAllTabNotificationsSeenRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiMarkAllTabNotificationsSeen200Response>(
      '/v2/mark-tab-notifications-seen',
      {
        headers,
        timeout,
        endpointName: 'markAllTabNotificationsSeen',
        body,
      },
    );
  }

  /**
   * Mark all warp transactions as read.
   */
  markAllWarpTransactionsRead({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiMarkAllWarpTransactionsRead200Response>(
      '/v2/mark-all-warp-transactions-read',
      {
        headers,
        timeout,
        endpointName: 'markAllWarpTransactionsRead',
        body: {},
      },
    );
  }

  /**
   * Mark direct cast key as dead. (deprecated)
   */
  markDirectCastKeyAsDead(
    body: ApiMarkDirectCastKeyAsDeadRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiMarkDirectCastKeyAsDead200Response>(
      '/v2/direct-cast-keys',
      {
        headers,
        timeout,
        endpointName: 'markDirectCastKeyAsDead',
        body,
      },
    );
  }

  /**
   * Mark direct cast keys as dead. (deprecated)
   */
  deleteDirectCastKeysByInbox(
    params: ApiDeleteDirectCastKeysByInboxQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteDirectCastKeysByInbox200Response>(
      '/v2/direct-cast-device-keys',
      {
        headers,
        timeout,
        endpointName: 'deleteDirectCastKeysByInbox',
        params,
      },
    );
  }

  /**
   * Mark prompt seen for user
   */
  markPromptedFor(
    body: ApiMarkPromptedForRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiMarkPromptedFor200Response>('/v2/mark-prompted-for', {
      headers,
      timeout,
      endpointName: 'markPromptedFor',
      body,
    });
  }

  /**
   * Mark suggested users as seen
   */
  setSuggestedUsersAsSeen(
    body: ApiSetSuggestedUsersAsSeenRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetSuggestedUsersAsSeen200Response>(
      '/v1/suggested-users-seen',
      {
        headers,
        timeout,
        endpointName: 'setSuggestedUsersAsSeen',
        body,
      },
    );
  }

  /**
   * Mark user nudged for contacts step during onboarding.
   */
  markNudgedForContacts({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiMarkNudgedForContacts200Response>(
      '/v2/mark-nudged-for-contacts',
      {
        headers,
        timeout,
        endpointName: 'markNudgedForContacts',
        body: {},
      },
    );
  }

  /**
   * Mark user nudged for interests step during onboarding.
   */
  markNudgedForInterests({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiMarkNudgedForInterests200Response>(
      '/v2/mark-nudged-for-interests',
      {
        headers,
        timeout,
        endpointName: 'markNudgedForInterests',
        body: {},
      },
    );
  }

  /**
   * Mark user nudged for push notifications step during onboarding.
   */
  markNudgedForPushNotifications({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiMarkNudgedForPushNotifications200Response>(
      '/v2/mark-nudged-for-push-notifications',
      {
        headers,
        timeout,
        endpointName: 'markNudgedForPushNotifications',
        body: {},
      },
    );
  }

  /**
   * Mark verification process start
   */
  markVerificationsStart({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiMarkVerificationsStart200Response>(
      '/v2/mark-verifications-start',
      {
        headers,
        timeout,
        endpointName: 'markVerificationsStart',
        body: {},
      },
    );
  }

  /**
   * Marks a conversation as read.
   */
  postDirectCastReadV3(
    body: ApiPostDirectCastReadV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostDirectCastReadV3200Response>(
      '/v2/direct-cast-mark-read',
      {
        headers,
        timeout,
        endpointName: 'postDirectCastReadV3',
        body,
      },
    );
  }

  /**
   * Marks the synchronization channel message as read for the requesting device.
   */
  markSyncChannelMessageRead(
    body: ApiMarkSyncChannelMessageReadRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiMarkSyncChannelMessageRead200Response>(
      '/v2/sync-channel-read',
      {
        headers,
        timeout,
        endpointName: 'markSyncChannelMessageRead',
        body,
      },
    );
  }

  /**
   * Moderate a cast
   */
  fcModerateCast(
    body: ApiFcModerateCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFcModerateCast200Response>('/fc/moderated-casts', {
      headers,
      timeout,
      endpointName: 'fcModerateCast',
      body,
    });
  }

  /**
   * Monitor a remote SIWF request
   */
  getRemoteSiwfRequest(
    params: ApiGetRemoteSiwfRequestQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetRemoteSiwfRequest200Response>(
      '/v1/remote-siwf',
      {
        headers,
        timeout,
        endpointName: 'getRemoteSiwfRequest',
        params,
      },
    );
  }

  /**
   * No longer limit the content visibility between users.
   */
  removeVisibilityRestrictions(
    body: ApiRemoveVisibilityRestrictionsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemoveVisibilityRestrictions200Response>(
      '/v2/limit-visibility',
      {
        headers,
        timeout,
        endpointName: 'removeVisibilityRestrictions',
        body,
      },
    );
  }

  /**
   * Notify a user about a campaign
   */
  notifyUsersAboutCampaign(
    body: ApiNotifyUsersAboutCampaignRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiNotifyUsersAboutCampaign200Response>(
      '/v2/campaign-admin/notify',
      {
        headers,
        timeout,
        endpointName: 'notifyUsersAboutCampaign',
        body,
      },
    );
  }

  /**
   * Opens a page that loads JavaScript to sign a recovery transaction hash.
   */
  signRecoveryTransactionHash(
    params: ApiSignRecoveryTransactionHashQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/sign-recovery-transaction-hash', {
      headers,
      timeout,
      endpointName: 'signRecoveryTransactionHash',
      params,
    });
  }

  /**
   * POST to cast action URL and return response message
   */
  postCastAction(
    body: ApiPostCastActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostCastAction200Response>('/v2/cast-action', {
      headers,
      timeout,
      endpointName: 'postCastAction',
      body,
    });
  }

  /**
   * POST to composer action URL and return response message
   */
  postComposerAction(
    body: ApiPostComposerActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostComposerAction200Response>('/v2/composer-action', {
      headers,
      timeout,
      endpointName: 'postComposerAction',
      body,
    });
  }

  /**
   * Pin a cast to its channel
   */
  fcPinCast(
    body: ApiFcPinCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiFcPinCast200Response>('/fc/pinned-casts', {
      headers,
      timeout,
      endpointName: 'fcPinCast',
      body,
    });
  }

  /**
   * Pin a direct cast conversation.
   */
  pinDirectCastConversation(
    body: ApiPinDirectCastConversationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPinDirectCastConversation200Response>(
      '/v2/direct-cast-pin-conversation',
      {
        headers,
        timeout,
        endpointName: 'pinDirectCastConversation',
        body,
      },
    );
  }

  /**
   * Pin cast on user profile.
   */
  pinCastOnUserProfile(
    body: ApiPinCastOnUserProfileRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPinCastOnUserProfile200Response>(
      '/v2/user-profile-pinned-casts',
      {
        headers,
        timeout,
        endpointName: 'pinCastOnUserProfile',
        body,
      },
    );
  }

  /**
   * Prepare a video upload
   */
  prepareVideoUpload(
    body: ApiPrepareVideoUploadRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPrepareVideoUpload200Response>(
      '/v1/prepare-video-upload',
      {
        headers,
        timeout,
        endpointName: 'prepareVideoUpload',
        body,
      },
    );
  }

  /**
   * Process results of Cloudflare Turnstile challenge
   */
  setTurnstileChallengeState(
    body: ApiSetTurnstileChallengeStateRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiSetTurnstileChallengeState200Response>(
      '/v1/turnstile',
      {
        headers,
        timeout,
        endpointName: 'setTurnstileChallengeState',
        body,
      },
    );
  }

  /**
   * Proxy an authentication request to the relay server
   */
  signInWithFarcaster(
    body: ApiSignInWithFarcasterRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiSignInWithFarcaster200Response>(
      '/v2/sign-in-with-farcaster',
      {
        headers,
        timeout,
        endpointName: 'signInWithFarcaster',
        body,
      },
    );
  }

  /**
   * Publicly return all blocked users
   */
  fcGetBlockedUsers(
    params: ApiFcGetBlockedUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiFcGetBlockedUsers200Response>(
      '/fc/blocked-users',
      {
        headers,
        timeout,
        endpointName: 'fcGetBlockedUsers',
        params,
      },
    );
  }

  /**
   * Recast a cast.
   */
  createRecast(
    body: ApiCreateRecastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiCreateRecast200Response>('/v2/recasts', {
      headers,
      timeout,
      endpointName: 'createRecast',
      body,
    });
  }

  /**
   * Receive App Store Server Notifications.
   */
  receiveAppStoreServerNotification(
    body: ApiReceiveAppStoreServerNotificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiReceiveAppStoreServerNotification200Response>(
      '/v2/app-store-server-notifications',
      {
        headers,
        timeout,
        endpointName: 'receiveAppStoreServerNotification',
        body,
      },
    );
  }

  /**
   * Receive Coinbase Commerce webhook events.
   */
  receiveCoinbaseCommerceWebhookEvent({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiReceiveCoinbaseCommerceWebhookEvent200Response>(
      '/coinbase-commerce-webhook-event',
      {
        headers,
        timeout,
        endpointName: 'receiveCoinbaseCommerceWebhookEvent',
        body: {},
      },
    );
  }

  /**
   * Receive sandbox App Store Server Notifications.
   */
  receiveAppStoreServerNotificationSandbox(
    body: ApiReceiveAppStoreServerNotificationSandboxRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiReceiveAppStoreServerNotificationSandbox200Response>(
      '/v2/app-store-server-notifications-sandbox',
      {
        headers,
        timeout,
        endpointName: 'receiveAppStoreServerNotificationSandbox',
        body,
      },
    );
  }

  /**
   * Record analytics events for storage in S3
   */
  recordAnalyticsEvents(
    body: ApiRecordAnalyticsEventsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiRecordAnalyticsEvents200Response>(
      '/v1/analytics-events',
      {
        headers,
        timeout,
        endpointName: 'recordAnalyticsEvents',
        body,
      },
    );
  }

  /**
   * Records a transaction from a wallet initiated in Warpcast
   */
  recordWalletTransaction(
    body: ApiRecordWalletTransactionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiRecordWalletTransaction200Response>(
      '/v2/wallet/record-transaction',
      {
        headers,
        timeout,
        endpointName: 'recordWalletTransaction',
        body,
      },
    );
  }

  /**
   * Redirect client to a presigned URL to upload a video directly to Cloudflare using the TUS protocol.
   */
  uploadVideoWithTus(
    params: ApiUploadVideoWithTusQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<void>('/v1/upload-video-with-tus', {
      headers,
      timeout,
      endpointName: 'uploadVideoWithTus',
      params,
    });
  }

  /**
   * Redirect to the specified URL, validating the signature.
   */
  redirectToLink(
    params: ApiRedirectToLinkQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/links', {
      headers,
      timeout,
      endpointName: 'redirectToLink',
      params,
    });
  }

  /**
   * Redirect to the specified URL, validating the signature. (FID based)
   */
  redirectToLinkV2(
    params: ApiRedirectToLinkV2QueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/links-v2', {
      headers,
      timeout,
      endpointName: 'redirectToLinkV2',
      params,
    });
  }

  /**
   * Redirect to the specified farcaster:// URL. Not signed.
   */
  redirectToDeepLink({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<void>('/deeplinks/*', {
      headers,
      timeout,
      endpointName: 'redirectToDeepLink',
    });
  }

  /**
   * Refresh a domain manifest
   */
  devToolsRefreshDomainManifest(
    body: ApiDevToolsRefreshDomainManifestRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiDevToolsRefreshDomainManifest200Response>(
      '/v1/dev-tools/refresh-domain-manifest',
      {
        headers,
        timeout,
        endpointName: 'devToolsRefreshDomainManifest',
        body,
      },
    );
  }

  /**
   * Refresh domain manifest
   */
  refreshDomainManifestState(
    body: ApiRefreshDomainManifestStateRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiRefreshDomainManifestState200Response>(
      '/v1/domain-manifest',
      {
        headers,
        timeout,
        endpointName: 'refreshDomainManifestState',
        body,
      },
    );
  }

  /**
   * Refresh the open graph metadata for a URL
   */
  devToolsRefreshOpenGraphMetadata(
    body: ApiDevToolsRefreshOpenGraphMetadataRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiDevToolsRefreshOpenGraphMetadata200Response>(
      '/v1/dev-tools/refresh-open-graph-metadata',
      {
        headers,
        timeout,
        endpointName: 'devToolsRefreshOpenGraphMetadata',
        body,
      },
    );
  }

  /**
   * Register an FID for the authenticated address.
   */
  registerFid(
    body: ApiRegisterFidRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiRegisterFid200Response>('/v2/register-fid', {
      headers,
      timeout,
      endpointName: 'registerFid',
      body,
    });
  }

  /**
   * Register an app domain
   */
  devToolsRegisterDomain(
    body: ApiDevToolsRegisterDomainRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiDevToolsRegisterDomain200Response>(
      '/v1/dev-tools/register-domain',
      {
        headers,
        timeout,
        endpointName: 'devToolsRegisterDomain',
        body,
      },
    );
  }

  /**
   * Register information about a user's device.
   */
  registerDevice(
    body: ApiRegisterDeviceRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiRegisterDevice200Response>('/v2/devices', {
      headers,
      timeout,
      endpointName: 'registerDevice',
      body,
    });
  }

  /**
   * Registers a composer action
   */
  addComposerAction(
    body: ApiAddComposerActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAddComposerAction200Response>(
      '/v2/add-composer-action',
      {
        headers,
        timeout,
        endpointName: 'addComposerAction',
        body,
      },
    );
  }

  /**
   * Registers a discovery app
   */
  addDiscoveryApp(
    body: ApiAddDiscoveryAppRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAddDiscoveryApp200Response>('/v2/discovery-app', {
      headers,
      timeout,
      endpointName: 'addDiscoveryApp',
      body,
    });
  }

  /**
   * Registers a discovery frame
   */
  addDiscoveryFrame(
    body: ApiAddDiscoveryFrameRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAddDiscoveryFrame200Response>('/v2/discovery-frame', {
      headers,
      timeout,
      endpointName: 'addDiscoveryFrame',
      body,
    });
  }

  /**
   * Reject a recovery
   */
  rejectRecovery({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiRejectRecovery200Response>('/v2/reject-recovery', {
      headers,
      timeout,
      endpointName: 'rejectRecovery',
      body: {},
    });
  }

  /**
   * Remember that a new user has seen the instructions interstitial
   */
  dismissNewUserFollowInstructions({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiDismissNewUserFollowInstructions200Response>(
      '/v1/dismiss-new-user-follow-instructions',
      {
        headers,
        timeout,
        endpointName: 'dismissNewUserFollowInstructions',
        body: {},
      },
    );
  }

  /**
   * Remove a ban from a user in a channel
   */
  fcUnbanUserFromChannel(
    body: ApiFcUnbanUserFromChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiFcUnbanUserFromChannel200Response>(
      '/fc/channel-bans',
      {
        headers,
        timeout,
        endpointName: 'fcUnbanUserFromChannel',
        body,
      },
    );
  }

  /**
   * Remove a ban from a user in a channel
   */
  unbanUserFromChannel(
    body: ApiUnbanUserFromChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiUnbanUserFromChannel200Response>(
      '/v1/channel-banned-users',
      {
        headers,
        timeout,
        endpointName: 'unbanUserFromChannel',
        body,
      },
    );
  }

  /**
   * Remove a direct cast group's members.
   */
  fcDeleteGroupMembers(
    body: ApiFcDeleteGroupMembersRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiFcDeleteGroupMembers200Response>(
      '/fc/group-members',
      {
        headers,
        timeout,
        endpointName: 'fcDeleteGroupMembers',
        body,
      },
    );
  }

  /**
   * Remove a favorite a feed
   */
  removeFavoriteFeed(
    body: ApiRemoveFavoriteFeedRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemoveFavoriteFeed200Response>('/v2/favorite-feeds', {
      headers,
      timeout,
      endpointName: 'removeFavoriteFeed',
      body,
    });
  }

  /**
   * Remove a featured app
   */
  removeFeaturedApp(
    body: ApiRemoveFeaturedAppRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiRemoveFeaturedApp200Response>(
      '/v1/featured-app-admin/remove',
      {
        headers,
        timeout,
        endpointName: 'removeFeaturedApp',
        body,
      },
    );
  }

  /**
   * Remove a keyword from a user's mute list
   */
  removeMuteKeyword(
    body: ApiRemoveMuteKeywordRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiRemoveMuteKeyword200Response>('/v2/unmute-keyword', {
      headers,
      timeout,
      endpointName: 'removeMuteKeyword',
      body,
    });
  }

  /**
   * Remove a reaction from a cast.
   */
  deleteCastLike(
    body: ApiDeleteCastLikeRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteCastLike200Response>('/v2/cast-likes', {
      headers,
      timeout,
      endpointName: 'deleteCastLike',
      body,
    });
  }

  /**
   * Remove a user from a channel as a member or moderator
   */
  removeChannelUserFromRole(
    body: ApiRemoveChannelUserFromRoleRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemoveChannelUserFromRole200Response>(
      '/v1/manage-channel-users',
      {
        headers,
        timeout,
        endpointName: 'removeChannelUserFromRole',
        body,
      },
    );
  }

  /**
   * Remove a user from a channel as a member or moderator (public signer version)
   */
  fcRemoveUserFromChannelRole(
    body: ApiFcRemoveUserFromChannelRoleRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiFcRemoveUserFromChannelRole200Response>(
      '/fc/channel-invites',
      {
        headers,
        timeout,
        endpointName: 'fcRemoveUserFromChannelRole',
        body,
      },
    );
  }

  /**
   * Remove boost from cast in a channel
   */
  removeCastBoost(
    body: ApiRemoveCastBoostRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemoveCastBoost200Response>('/v2/boost-cast', {
      headers,
      timeout,
      endpointName: 'removeCastBoost',
      body,
    });
  }

  /**
   * Remove cast bookmark.
   */
  removeCastBookmark(
    body: ApiRemoveCastBookmarkRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemoveCastBookmark200Response>(
      '/v2/bookmarked-casts',
      {
        headers,
        timeout,
        endpointName: 'removeCastBookmark',
        body,
      },
    );
  }

  /**
   * Remove info about connected account
   */
  removeConnectedAccount(
    body: ApiRemoveConnectedAccountRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemoveConnectedAccount200Response>(
      '/v2/connected-accounts',
      {
        headers,
        timeout,
        endpointName: 'removeConnectedAccount',
        body,
      },
    );
  }

  /**
   * Remove phone verification for a user
   */
  removePhoneVerificationForUser(
    body: ApiRemovePhoneVerificationForUserRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemovePhoneVerificationForUser200Response>(
      '/v2/quests/debug/remove-phone-verification',
      {
        headers,
        timeout,
        endpointName: 'removePhoneVerificationForUser',
        body,
      },
    );
  }

  /**
   * Removes a reaction to the specified message.
   */
  deleteDirectCastConversationReactionsV3(
    body: ApiDeleteDirectCastConversationReactionsV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteDirectCastConversationReactionsV3200Response>(
      '/v2/direct-cast-message-reaction',
      {
        headers,
        timeout,
        endpointName: 'deleteDirectCastConversationReactionsV3',
        body,
      },
    );
  }

  /**
   * Removes a signer.
   */
  removeSigner(
    body: ApiRemoveSignerRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemoveSigner200Response>('/v2/signers', {
      headers,
      timeout,
      endpointName: 'removeSigner',
      body,
    });
  }

  /**
   * Rent storage
   */
  rentStorage(
    body: ApiRentStorageRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiRentStorage201Response>('/v2/rent-storage', {
      headers,
      timeout,
      endpointName: 'rentStorage',
      body,
    });
  }

  /**
   * Rent transaction intent
   */
  rentTransactionData(
    params: ApiRentTransactionDataQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<void>('/v2/rent-transaction-data', {
      headers,
      timeout,
      endpointName: 'rentTransactionData',
      params,
    });
  }

  /**
   * Report a cast.
   */
  reportCast(
    body: ApiReportCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiReportCast200Response>('/v2/report-cast', {
      headers,
      timeout,
      endpointName: 'reportCast',
      body,
    });
  }

  /**
   * Report a user.
   */
  reportUser(
    body: ApiReportUserRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiReportUser200Response>('/v2/report-user', {
      headers,
      timeout,
      endpointName: 'reportUser',
      body,
    });
  }

  /**
   * Report information about a frame transaction
   */
  reportFrameTransaction(
    body: ApiReportFrameTransactionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiReportFrameTransaction200Response>(
      '/v2/report-frame-transaction',
      {
        headers,
        timeout,
        endpointName: 'reportFrameTransaction',
        body,
      },
    );
  }

  /**
   * Reports activity the user has performed
   */
  reportProfileActivity(
    body: ApiReportProfileActivityRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiReportProfileActivity200Response>(
      '/v2/report-activity',
      {
        headers,
        timeout,
        endpointName: 'reportProfileActivity',
        body,
      },
    );
  }

  /**
   * Request a deletion of an account.
   */
  requestAccountDelete({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiRequestAccountDelete200Response>(
      '/v2/request-account-delete',
      {
        headers,
        timeout,
        endpointName: 'requestAccountDelete',
        body: {},
      },
    );
  }

  /**
   * Request a magic link
   */
  initiateMagicLink(
    body: ApiInitiateMagicLinkRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiInitiateMagicLink200Response>('/v2/magic-link', {
      headers,
      timeout,
      endpointName: 'initiateMagicLink',
      body,
    });
  }

  /**
   * Request an invite email with an email address
   */
  signupForInvite(
    body: ApiSignupForInviteRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiSignupForInvite200Response>('/v2/signup-for-invite', {
      headers,
      timeout,
      endpointName: 'signupForInvite',
      body,
    });
  }

  /**
   * Reset onboarding state associated with an email. (Super-admins only)
   */
  resetOnboardingState(
    body: ApiResetOnboardingStateRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiResetOnboardingState200Response>(
      '/v2/reset-onboarding-state',
      {
        headers,
        timeout,
        endpointName: 'resetOnboardingState',
        body,
      },
    );
  }

  /**
   * Reset the invite code for a channel
   */
  resetChannelInviteCode(
    body: ApiResetChannelInviteCodeRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiResetChannelInviteCode200Response>(
      '/v1/reset-channel-invite-code',
      {
        headers,
        timeout,
        endpointName: 'resetChannelInviteCode',
        body,
      },
    );
  }

  /**
   * Reset the value of a dynamic config to its default
   */
  resetDynamicConfig(
    body: ApiResetDynamicConfigRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiResetDynamicConfig200Response>(
      '/v1/dynamic-configs',
      {
        headers,
        timeout,
        endpointName: 'resetDynamicConfig',
        body,
      },
    );
  }

  /**
   * Reset user to go through new user experience
   */
  resetToNewUserExperience({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiResetToNewUserExperience200Response>(
      '/v1/reset-to-new-user-experience',
      {
        headers,
        timeout,
        endpointName: 'resetToNewUserExperience',
        body: {},
      },
    );
  }

  /**
   * Resets cached open-graph preview and fetches a new one by scraping the URL again.
   */
  scrapeEmbed(
    body: ApiScrapeEmbedRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiScrapeEmbed200Response>('/v2/scrape-embed', {
      headers,
      timeout,
      endpointName: 'scrapeEmbed',
      body,
    });
  }

  /**
   * Resets cached open-graph preview and fetches and validates a frame open graph value set.
   */
  validateFrameEmbed(
    body: ApiValidateFrameEmbedRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiValidateFrameEmbed200Response>('/v2/validate-frame', {
      headers,
      timeout,
      endpointName: 'validateFrameEmbed',
      body,
    });
  }

  /**
   * Resets cached open-graph preview and fetches and validates a frame open graph value set.
   */
  validateFrameEmbedV2(
    body: ApiValidateFrameEmbedV2RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiValidateFrameEmbedV2200Response>(
      '/v2/validate-frame-embed',
      {
        headers,
        timeout,
        endpointName: 'validateFrameEmbedV2',
        body,
      },
    );
  }

  /**
   * Retrieve summary information about a given wallet
   */
  getWalletPortfolio(
    params: ApiGetWalletPortfolioQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWalletPortfolio200Response>(
      '/v2/wallet/portfolio',
      {
        headers,
        timeout,
        endpointName: 'getWalletPortfolio',
        params,
      },
    );
  }

  /**
   * Retrieve the synchronization channel messages for the requesting device.
   */
  getSyncChannel(
    params: ApiGetSyncChannelQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetSyncChannel200Response>('/v2/sync-channel', {
      headers,
      timeout,
      endpointName: 'getSyncChannel',
      params,
    });
  }

  /**
   * Retrieves NFT collections for a given wallet
   */
  getWalletNftCollections(
    params: ApiGetWalletNftCollectionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWalletNftCollections200Response>(
      '/v2/wallet/nft-collections',
      {
        headers,
        timeout,
        endpointName: 'getWalletNftCollections',
        params,
      },
    );
  }

  /**
   * Retrieves NFTs for a given wallet
   */
  getWalletNfts(
    params: ApiGetWalletNftsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWalletNfts200Response>('/v2/wallet/nfts', {
      headers,
      timeout,
      endpointName: 'getWalletNfts',
      params,
    });
  }

  /**
   * Retrieves fungible positions for a given wallet
   */
  getWalletPositions(
    params: ApiGetWalletPositionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetWalletPositions200Response>(
      '/v2/wallet/positions',
      {
        headers,
        timeout,
        endpointName: 'getWalletPositions',
        params,
      },
    );
  }

  /**
   * Retrieves the reactions to a specific message.
   */
  getDirectCastConversationReactionsV3(
    params: ApiGetDirectCastConversationReactionsV3QueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationReactionsV3200Response>(
      '/v2/direct-cast-message-reaction',
      {
        headers,
        timeout,
        endpointName: 'getDirectCastConversationReactionsV3',
        params,
      },
    );
  }

  /**
   * Return a few channels and users matching the given search query.
   */
  searchSummary(
    params: ApiSearchSummaryQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchSummary200Response>('/v2/search-summary', {
      headers,
      timeout,
      endpointName: 'searchSummary',
      params,
    });
  }

  /**
   * Return a robotos.txt that disallows crawling our APIs
   */
  getRobotsTxt({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<void>('/robots.txt', {
      headers,
      timeout,
      endpointName: 'getRobotsTxt',
    });
  }

  /**
   * Return actions matching the given search query.
   */
  searchActions(
    params: ApiSearchActionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchActions200Response>('/v2/search-actions', {
      headers,
      timeout,
      endpointName: 'searchActions',
      params,
    });
  }

  /**
   * Return actions matching the given search query.
   */
  searchComposerActions(
    params: ApiSearchComposerActionsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchComposerActions200Response>(
      '/v2/search-composer-actions',
      {
        headers,
        timeout,
        endpointName: 'searchComposerActions',
        params,
      },
    );
  }

  /**
   * Return all casts matching the given search query.
   */
  searchCasts(
    params: ApiSearchCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchCasts200Response>('/v2/search-casts', {
      headers,
      timeout,
      endpointName: 'searchCasts',
      params,
    });
  }

  /**
   * Return all channels
   */
  getAllChannelsPublic({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetAllChannelsPublic200Response>(
      '/v2/all-channels',
      {
        headers,
        timeout,
        endpointName: 'getAllChannelsPublic',
      },
    );
  }

  /**
   * Return all channels matching the given search query.
   */
  searchChannels(
    params: ApiSearchChannelsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchChannels200Response>('/v2/search-channels', {
      headers,
      timeout,
      endpointName: 'searchChannels',
      params,
    });
  }

  /**
   * Return all direct cast conversations matching the given search query.
   */
  searchDirectCasts(
    params: ApiSearchDirectCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchDirectCasts200Response>(
      '/v2/search-direct-casts',
      {
        headers,
        timeout,
        endpointName: 'searchDirectCasts',
        params,
      },
    );
  }

  /**
   * Return all inbox conversations matching the given search query.
   */
  searchDirectCastInbox(
    params: ApiSearchDirectCastInboxQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchDirectCastInbox200Response>(
      '/v2/search-direct-cast-inbox',
      {
        headers,
        timeout,
        endpointName: 'searchDirectCastInbox',
        params,
      },
    );
  }

  /**
   * Return all users matching the given search query.
   */
  searchUsers(
    params: ApiSearchUsersQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchUsers200Response>('/v2/search-users', {
      headers,
      timeout,
      endpointName: 'searchUsers',
      params,
    });
  }

  /**
   * Return channel information
   */
  getChannelPublic(
    params: ApiGetChannelPublicQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelPublic200Response>('/v1/channel', {
      headers,
      timeout,
      endpointName: 'getChannelPublic',
      params,
    });
  }

  /**
   * Return channels a user is following
   */
  getUserFollowingChannelsPublic(
    params: ApiGetUserFollowingChannelsPublicQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserFollowingChannelsPublic200Response>(
      '/v1/user-following-channels',
      {
        headers,
        timeout,
        endpointName: 'getUserFollowingChannelsPublic',
        params,
      },
    );
  }

  /**
   * Return success if API key is valid.
   */
  validateApiKey({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiValidateApiKey200Response>(
      '/v2/validate-api-key',
      {
        headers,
        timeout,
        endpointName: 'validateApiKey',
      },
    );
  }

  /**
   * Return success if DC auth token is valid
   */
  validateDCAuthToken({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiValidateDCAuthToken200Response>(
      '/v2/validate-dc-auth-token',
      {
        headers,
        timeout,
        endpointName: 'validateDCAuthToken',
      },
    );
  }

  /**
   * Return the status of a user potentially following a channel
   */
  getUserChannelPublic(
    params: ApiGetUserChannelPublicQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetUserChannelPublic200Response>(
      '/v1/user-channel',
      {
        headers,
        timeout,
        endpointName: 'getUserChannelPublic',
        params,
      },
    );
  }

  /**
   * Return users who follow a channel
   */
  getChannelFollowersPublic(
    params: ApiGetChannelFollowersPublicQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetChannelFollowersPublic200Response>(
      '/v1/channel-followers',
      {
        headers,
        timeout,
        endpointName: 'getChannelFollowersPublic',
        params,
      },
    );
  }

  /**
   * Returns common share targets for cast
   */
  shareCast(
    params: ApiShareCastQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiShareCast200Response>('/v2/share-cast', {
      headers,
      timeout,
      endpointName: 'shareCast',
      params,
    });
  }

  /**
   * Returns information about onboarding state. (Super-admins only)
   */
  lookupOnboardingState(
    params: ApiLookupOnboardingStateQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiLookupOnboardingState200Response>(
      '/v2/lookup-onboarding-state',
      {
        headers,
        timeout,
        endpointName: 'lookupOnboardingState',
        params,
      },
    );
  }

  /**
   * Returns rituals for channel or draft casts
   */
  getDraftCasts(
    params: ApiGetDraftCastsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDraftCasts200Response>('/v2/draft-casts', {
      headers,
      timeout,
      endpointName: 'getDraftCasts',
      params,
    });
  }

  /**
   * Returns rituals for channel or draft caststorms
   */
  getDraftCaststorms(
    params: ApiGetDraftCaststormsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetDraftCaststorms200Response>(
      '/v2/draft-caststorms',
      {
        headers,
        timeout,
        endpointName: 'getDraftCaststorms',
        params,
      },
    );
  }

  /**
   * Returns the active channel streak for the user
   */
  getActiveChannelStreak(
    params: ApiGetActiveChannelStreakQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetActiveChannelStreak200Response>(
      '/v2/channel-streaks',
      {
        headers,
        timeout,
        endpointName: 'getActiveChannelStreak',
        params,
      },
    );
  }

  /**
   * Returns the farcaster json from the given domain
   */
  devToolsFarcasterJson(
    params: ApiDevToolsFarcasterJsonQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/v1/dev-tools/farcaster-json', {
      headers,
      timeout,
      endpointName: 'devToolsFarcasterJson',
      params,
    });
  }

  /**
   * Returns the raw metatags from the given URL
   */
  devToolsMetaTags(
    params: ApiDevToolsMetaTagsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/v1/dev-tools/meta-tags', {
      headers,
      timeout,
      endpointName: 'devToolsMetaTags',
      params,
    });
  }

  /**
   * Returns whether the server is healthy and responding to requests.
   */
  getHealth({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetHealth200Response>('/healthcheck', {
      headers,
      timeout,
      endpointName: 'getHealth',
    });
  }

  /**
   * Reverse chrono feed of all casters in the starter pack
   */
  getStarterPackFeed(
    params: ApiGetStarterPackFeedQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiGetStarterPackFeed200Response>(
      '/v2/starter-packs/feed',
      {
        headers,
        timeout,
        endpointName: 'getStarterPackFeed',
        params,
      },
    );
  }

  /**
   * Revokes a verification for the authenticated user.
   */
  deleteVerification(
    body: ApiDeleteVerificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteVerification200Response>('/v2/verifications', {
      headers,
      timeout,
      endpointName: 'deleteVerification',
      body,
    });
  }

  /**
   * Revokes an API key.
   */
  revokeApiKey(
    body: ApiRevokeApiKeyRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiRevokeApiKey200Response>('/v2/revoke-api-key', {
      headers,
      timeout,
      endpointName: 'revokeApiKey',
      body,
    });
  }

  /**
   * Revokes an authentication token.
   */
  deleteAuthToken({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.delete<ApiDeleteAuthToken200Response>('/v2/auth', {
      headers,
      timeout,
      endpointName: 'deleteAuthToken',
      body: {},
    });
  }

  /**
   * Scan a transaction request from a mini app
   */
  postMiniAppTransaction(
    body: ApiPostMiniAppTransactionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostMiniAppTransaction200Response>(
      '/v2/mini-app-transaction',
      {
        headers,
        timeout,
        endpointName: 'postMiniAppTransaction',
        body,
      },
    );
  }

  /**
   * Scan an EVM wallet action
   */
  walletEvmScanAction(
    body: ApiWalletEvmScanActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiWalletEvmScanAction200Response>(
      '/v2/wallet/evm-scan-action',
      {
        headers,
        timeout,
        endpointName: 'walletEvmScanAction',
        body,
      },
    );
  }

  /**
   * Scrape CA to get a possible cast embed match
   */
  scrapeContractAddress(
    params: ApiScrapeContractAddressQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiScrapeContractAddress200Response>(
      '/v2/scrape-ca',
      {
        headers,
        timeout,
        endpointName: 'scrapeContractAddress',
        params,
      },
    );
  }

  /**
   * Search for targets to send to
   */
  searchWalletSendTargets(
    params: ApiSearchWalletSendTargetsQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchWalletSendTargets200Response>(
      '/v2/wallet/send-search',
      {
        headers,
        timeout,
        endpointName: 'searchWalletSendTargets',
        params,
      },
    );
  }

  /**
   * Send a direct cast message.
   */
  fcPutMessage(
    body: ApiFcPutMessageRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiFcPutMessage200Response>('/fc/message', {
      headers,
      timeout,
      endpointName: 'fcPutMessage',
      body,
    });
  }

  /**
   * Send a direct cast, for use by application developers.
   */
  putExtSendDirectCast(
    body: ApiPutExtSendDirectCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPutExtSendDirectCast200Response>(
      '/v2/ext-send-direct-cast',
      {
        headers,
        timeout,
        endpointName: 'putExtSendDirectCast',
        body,
      },
    );
  }

  /**
   * Send a notification to a user from a frame
   */
  sendFrameNotification(
    body: ApiSendFrameNotificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiSendFrameNotification200Response>(
      '/v1/frame-notifications',
      {
        headers,
        timeout,
        endpointName: 'sendFrameNotification',
        body,
      },
    );
  }

  /**
   * Send a recovery transaction signature representing an admin's approval of the recovery.
   */
  approveRecoveryTransaction(
    body: ApiApproveRecoveryTransactionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiApproveRecoveryTransaction200Response>(
      '/v2/approve-recovery-transaction',
      {
        headers,
        timeout,
        endpointName: 'approveRecoveryTransaction',
        body,
      },
    );
  }

  /**
   * Send a test push notification
   */
  sendPushNotification(
    body: ApiSendPushNotificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiSendPushNotification200Response>(
      '/v2/send-push-notification',
      {
        headers,
        timeout,
        endpointName: 'sendPushNotification',
        body,
      },
    );
  }

  /**
   * Send a verification email to the specified email address, associating it with the given custody address.
   */
  sendVerificationEmail(
    body: ApiSendVerificationEmailRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiSendVerificationEmail200Response>(
      '/v2/send-verification-email',
      {
        headers,
        timeout,
        endpointName: 'sendVerificationEmail',
        body,
      },
    );
  }

  /**
   * Send a verification email to the specified email address, will be associated to authenticated FID.
   */
  updateEmail(
    body: ApiUpdateEmailRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiUpdateEmail200Response>('/v2/update-email', {
      headers,
      timeout,
      endpointName: 'updateEmail',
      body,
    });
  }

  /**
   * Send an email to users about buying warps
   */
  sendBuyWarpsInfoEmail({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiSendBuyWarpsInfoEmail200Response>(
      '/v2/send-buy-warps-info-email',
      {
        headers,
        timeout,
        endpointName: 'sendBuyWarpsInfoEmail',
        body: {},
      },
    );
  }

  /**
   * Send an email with a link to verify flow on web.
   */
  sendConnectAddressLinkEmail({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiSendConnectAddressLinkEmail200Response>(
      '/v2/send-connect-address-link-email',
      {
        headers,
        timeout,
        endpointName: 'sendConnectAddressLinkEmail',
        body: {},
      },
    );
  }

  /**
   * Send warps.
   */
  sendWarps(
    body: ApiSendWarpsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSendWarps200Response>('/v2/send-warps', {
      headers,
      timeout,
      endpointName: 'sendWarps',
      body,
    });
  }

  /**
   * Sends a direct cast.
   */
  putDirectCastV3(
    body: ApiPutDirectCastV3RequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPutDirectCastV3200Response>('/v2/direct-cast-send', {
      headers,
      timeout,
      endpointName: 'putDirectCastV3',
      body,
    });
  }

  /**
   * Set a user's warpcast wallet address
   */
  putWarpcastWalletAddress(
    body: ApiPutWarpcastWalletAddressRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPutWarpcastWalletAddress200Response>(
      '/v2/warpcast-wallet-address',
      {
        headers,
        timeout,
        endpointName: 'putWarpcastWalletAddress',
        body,
      },
    );
  }

  /**
   * Set channel distribution
   */
  setChannelDistribution(
    body: ApiSetChannelDistributionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetChannelDistribution200Response>(
      '/v2/channel-distribution',
      {
        headers,
        timeout,
        endpointName: 'setChannelDistribution',
        body,
      },
    );
  }

  /**
   * Set the active username on the authenticated user
   */
  setUserUsername(
    body: ApiSetUserUsernameRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetUserUsername200Response>('/v2/set-user-username', {
      headers,
      timeout,
      endpointName: 'setUserUsername',
      body,
    });
  }

  /**
   * Set the badness of a low quality user.
   */
  setLowQualityUserBadness(
    body: ApiSetLowQualityUserBadnessRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetLowQualityUserBadness200Response>(
      '/v1/set-low-quality-user-badness',
      {
        headers,
        timeout,
        endpointName: 'setLowQualityUserBadness',
        body,
      },
    );
  }

  /**
   * Set the creator label of a user.
   */
  setCreatorLabel(
    body: ApiSetCreatorLabelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetCreatorLabel200Response>('/v2/set-creator-label', {
      headers,
      timeout,
      endpointName: 'setCreatorLabel',
      body,
    });
  }

  /**
   * Set the last checked timestamp for the authenticated user.
   */
  setLastCheckedTimestamp({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiSetLastCheckedTimestamp200Response>(
      '/v2/set-last-checked-timestamp',
      {
        headers,
        timeout,
        endpointName: 'setLastCheckedTimestamp',
        body: {},
      },
    );
  }

  /**
   * Set the position of a favorite feed
   */
  setFavoriteFeedPosition(
    body: ApiSetFavoriteFeedPositionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiSetFavoriteFeedPosition200Response>(
      '/v2/favorite-feeds',
      {
        headers,
        timeout,
        endpointName: 'setFavoriteFeedPosition',
        body,
      },
    );
  }

  /**
   * Set the quality of a user.
   */
  setUserQuality(
    body: ApiSetUserQualityRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetUserQuality200Response>('/v2/set-user-quality', {
      headers,
      timeout,
      endpointName: 'setUserQuality',
      body,
    });
  }

  /**
   * Set the quality of many users
   */
  setBulkUserQuality(
    body: ApiSetBulkUserQualityRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetBulkUserQuality200Response>(
      '/v2/set-bulk-user-quality',
      {
        headers,
        timeout,
        endpointName: 'setBulkUserQuality',
        body,
      },
    );
  }

  /**
   * Set the quality of many users via automated detection
   */
  setBulkAutoUserQuality(
    body: ApiSetBulkAutoUserQualityRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetBulkAutoUserQuality200Response>(
      '/v2/set-bulk-auto-user-quality',
      {
        headers,
        timeout,
        endpointName: 'setBulkAutoUserQuality',
        body,
      },
    );
  }

  /**
   * Set the referrer for the authenticated user.
   */
  setUserReferrer(
    body: ApiSetUserReferrerRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetUserReferrer200Response>('/v2/user-referrer', {
      headers,
      timeout,
      endpointName: 'setUserReferrer',
      body,
    });
  }

  /**
   * Set the value of a dynamic config
   */
  setDynamicConfig(
    body: ApiSetDynamicConfigRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetDynamicConfig200Response>('/v1/dynamic-configs', {
      headers,
      timeout,
      endpointName: 'setDynamicConfig',
      body,
    });
  }

  /**
   * Set time feed was last seen by providing the timestamp of the latest feed item
   */
  setFeedSeen(
    body: ApiSetFeedSeenRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetFeedSeen200Response>('/v2/feed-seen', {
      headers,
      timeout,
      endpointName: 'setFeedSeen',
      body,
    });
  }

  /**
   * Sets an action's position
   */
  setUserCastActionPosition(
    body: ApiSetUserCastActionPositionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiSetUserCastActionPosition200Response>(
      '/v2/user-cast-actions',
      {
        headers,
        timeout,
        endpointName: 'setUserCastActionPosition',
        body,
      },
    );
  }

  /**
   * Signer related features metadata
   */
  getSignersMetadata({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiGetSignersMetadata200Response>(
      '/v2/signers-metadata',
      {
        headers,
        timeout,
        endpointName: 'getSignersMetadata',
      },
    );
  }

  /**
   * Simulate a rent storage transaction
   */
  simulateRentStorage(
    params: ApiSimulateRentStorageQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSimulateRentStorage200Response>(
      '/v2/simulate-rent-storage',
      {
        headers,
        timeout,
        endpointName: 'simulateRentStorage',
        params,
      },
    );
  }

  /**
   * Simulate create signed key request.
   */
  simulateCreateSignedKeyRequest(
    body: ApiSimulateCreateSignedKeyRequestRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSimulateCreateSignedKeyRequest200Response>(
      '/v2/simulate-create-signed-key-request',
      {
        headers,
        timeout,
        endpointName: 'simulateCreateSignedKeyRequest',
        body,
      },
    );
  }

  /**
   * Simulate registering an FID for the authenticated address
   */
  simulateRegisterFid(
    body: ApiSimulateRegisterFidRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSimulateRegisterFid200Response>(
      '/v2/simulate-register-fid',
      {
        headers,
        timeout,
        endpointName: 'simulateRegisterFid',
        body,
      },
    );
  }

  /**
   * Simulate remove signed key request.
   */
  simulateRemoveSignedKeyRequest(
    body: ApiSimulateRemoveSignedKeyRequestRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSimulateRemoveSignedKeyRequest200Response>(
      '/v2/simulate-remove-signed-key-request',
      {
        headers,
        timeout,
        endpointName: 'simulateRemoveSignedKeyRequest',
        body,
      },
    );
  }

  /**
   * Skip connect address during onboarding.
   */
  skipConnectAddress({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiSkipConnectAddress200Response>(
      '/v2/skip-connect-address',
      {
        headers,
        timeout,
        endpointName: 'skipConnectAddress',
        body: {},
      },
    );
  }

  /**
   * Skip phone verification during onboarding.
   */
  setupAdvancedProtection(
    body: ApiSetupAdvancedProtectionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSetupAdvancedProtection200Response>(
      '/v2/setup-advanced-protection',
      {
        headers,
        timeout,
        endpointName: 'setupAdvancedProtection',
        body,
      },
    );
  }

  /**
   * Skip phone verification during onboarding.
   */
  skipPhone(
    body: ApiSkipPhoneRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSkipPhone200Response>('/v2/skip-phone', {
      headers,
      timeout,
      endpointName: 'skipPhone',
      body,
    });
  }

  /**
   * Specific search users query for starter packs
   */
  searchUsersForStarterPacks(
    params: ApiSearchUsersForStarterPacksQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<ApiSearchUsersForStarterPacks200Response>(
      '/v2/search-users-for-starter-packs',
      {
        headers,
        timeout,
        endpointName: 'searchUsersForStarterPacks',
        params,
      },
    );
  }

  /**
   * Start a channel streak
   */
  startChannelStreak(
    body: ApiStartChannelStreakRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiStartChannelStreak200Response>('/v2/channel-streaks', {
      headers,
      timeout,
      endpointName: 'startChannelStreak',
      body,
    });
  }

  /**
   * Start a phone verification
   */
  startPhoneVerification(
    body: ApiStartPhoneVerificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiStartPhoneVerification200Response>(
      '/v2/start-phone-verification',
      {
        headers,
        timeout,
        endpointName: 'startPhoneVerification',
        body,
      },
    );
  }

  /**
   * Start in-app purchase flow for authenticated user.
   */
  startInAppPurchase(
    body: ApiStartInAppPurchaseRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiStartInAppPurchase200Response>(
      '/v2/start-in-app-purchase',
      {
        headers,
        timeout,
        endpointName: 'startInAppPurchase',
        body,
      },
    );
  }

  /**
   * Start in-app purchase flow for custody-token auth user.
   */
  startInAppPurchaseWithCustody(
    body: ApiStartInAppPurchaseWithCustodyRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiStartInAppPurchaseWithCustody200Response>(
      '/v2/start-in-app-purchase-with-custody',
      {
        headers,
        timeout,
        endpointName: 'startInAppPurchaseWithCustody',
        body,
      },
    );
  }

  /**
   * Start verification and generate token
   */
  startVerification({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiStartVerification200Response>('/v2/start-verification', {
      headers,
      timeout,
      endpointName: 'startVerification',
      body: {},
    });
  }

  /**
   * Stop active channel streaks
   */
  stopActiveChannelStreaks({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.delete<ApiStopActiveChannelStreaks200Response>(
      '/v2/channel-streaks',
      {
        headers,
        timeout,
        endpointName: 'stopActiveChannelStreaks',
        body: {},
      },
    );
  }

  /**
   * Stop showing banner that counts number of followed users
   */
  dismissNewUserFollowsBanner({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.put<ApiDismissNewUserFollowsBanner200Response>(
      '/v1/dismiss-new-user-follows-banners',
      {
        headers,
        timeout,
        endpointName: 'dismissNewUserFollowsBanner',
        body: {},
      },
    );
  }

  /**
   * Store a draft cast
   */
  storeDraftCast(
    body: ApiStoreDraftCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiStoreDraftCast200Response>('/v2/draft-casts', {
      headers,
      timeout,
      endpointName: 'storeDraftCast',
      body,
    });
  }

  /**
   * Store a draft caststorm
   */
  storeDraftCaststorm(
    body: ApiStoreDraftCaststormRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiStoreDraftCaststorm200Response>(
      '/v2/draft-caststorms',
      {
        headers,
        timeout,
        endpointName: 'storeDraftCaststorm',
        body,
      },
    );
  }

  /**
   * Store a temporary account association
   */
  devToolsStoreTempAccountAssociation(
    body: ApiDevToolsStoreTempAccountAssociationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiDevToolsStoreTempAccountAssociation200Response>(
      '/v1/dev-tools/temp-account-association',
      {
        headers,
        timeout,
        endpointName: 'devToolsStoreTempAccountAssociation',
        body,
      },
    );
  }

  /**
   * Submit a message from signed message data
   */
  submitSignedMessageData(
    body: ApiSubmitSignedMessageDataRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiSubmitSignedMessageData200Response>(
      '/v2/submit-signed-message-data',
      {
        headers,
        timeout,
        endpointName: 'submitSignedMessageData',
        body,
      },
    );
  }

  /**
   * Submit feedback for a cast
   */
  recordCastFeedback(
    body: ApiRecordCastFeedbackRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiRecordCastFeedback200Response>('/v1/cast-feedback', {
      headers,
      timeout,
      endpointName: 'recordCastFeedback',
      body,
    });
  }

  /**
   * Submit user selected onboarding interests.
   */
  submitSelectedOnboardingInterests(
    body: ApiSubmitSelectedOnboardingInterestsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiSubmitSelectedOnboardingInterests200Response>(
      '/v2/onboarding-interests',
      {
        headers,
        timeout,
        endpointName: 'submitSelectedOnboardingInterests',
        body,
      },
    );
  }

  /**
   * Suggestions for target to send to
   */
  walletSendSuggestions({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<ApiWalletSendSuggestions200Response>(
      '/v2/wallet/send-suggestions',
      {
        headers,
        timeout,
        endpointName: 'walletSendSuggestions',
      },
    );
  }

  /**
   * Tip a cast
   */
  tipCast(
    body: ApiTipCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiTipCast200Response>('/v2/cast-tips', {
      headers,
      timeout,
      endpointName: 'tipCast',
      body,
    });
  }

  /**
   * Trigger admin actions
   */
  adminAction(
    body: ApiAdminActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAdminAction200Response>('/v1/admin-action', {
      headers,
      timeout,
      endpointName: 'adminAction',
      body,
    });
  }

  /**
   * Trigger updates to multiple channel threads
   */
  adminUpdateChannelThreads(
    body: ApiAdminUpdateChannelThreadsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAdminUpdateChannelThreads200Response>(
      '/v1/admin-update-channel-threads',
      {
        headers,
        timeout,
        endpointName: 'adminUpdateChannelThreads',
        body,
      },
    );
  }

  /**
   * Trigger updates to multiple channel threads
   */
  adminUpdateChannelsAllThreads(
    body: ApiAdminUpdateChannelsAllThreadsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiAdminUpdateChannelsAllThreads200Response>(
      '/v1/admin-update-channels-all-threads',
      {
        headers,
        timeout,
        endpointName: 'adminUpdateChannelsAllThreads',
        body,
      },
    );
  }

  /**
   * Unblock a user
   */
  fcUnblockUser(
    body: ApiFcUnblockUserRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiFcUnblockUser200Response>('/fc/blocked-users', {
      headers,
      timeout,
      endpointName: 'fcUnblockUser',
      body,
    });
  }

  /**
   * Unfavorite a frame
   */
  removeFavoriteFrame(
    body: ApiRemoveFavoriteFrameRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiRemoveFavoriteFrame200Response>(
      '/v1/favorite-frames',
      {
        headers,
        timeout,
        endpointName: 'removeFavoriteFrame',
        body,
      },
    );
  }

  /**
   * Unfollow a channel
   */
  fcUnfollowChannel(
    body: ApiFcUnfollowChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiFcUnfollowChannel200Response>('/fc/channel-follows', {
      headers,
      timeout,
      endpointName: 'fcUnfollowChannel',
      body,
    });
  }

  /**
   * Unfollow a feed.
   */
  deleteFeedFollow(
    body: ApiDeleteFeedFollowRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteFeedFollow200Response>('/v2/feed-follows', {
      headers,
      timeout,
      endpointName: 'deleteFeedFollow',
      body,
    });
  }

  /**
   * Unfollow a user.
   */
  deleteFollow(
    body: ApiDeleteFollowRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiDeleteFollow200Response>('/v2/follows', {
      headers,
      timeout,
      endpointName: 'deleteFollow',
      body,
    });
  }

  /**
   * Unfollow all least interacted with following users.
   */
  unfollowLeastInteractedWithFollowing({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.delete<ApiUnfollowLeastInteractedWithFollowing200Response>(
      '/v2/unfollow-least-interacted-with-following',
      {
        headers,
        timeout,
        endpointName: 'unfollowLeastInteractedWithFollowing',
        body: {},
      },
    );
  }

  /**
   * Unpin a cast
   */
  fcUnpinCast(
    body: ApiFcUnpinCastRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiFcUnpinCast200Response>('/fc/pinned-casts', {
      headers,
      timeout,
      endpointName: 'fcUnpinCast',
      body,
    });
  }

  /**
   * Unpin a direct cast conversation.
   */
  unpinDirectCastConversation(
    body: ApiUnpinDirectCastConversationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiUnpinDirectCastConversation200Response>(
      '/v2/direct-cast-pin-conversation',
      {
        headers,
        timeout,
        endpointName: 'unpinDirectCastConversation',
        body,
      },
    );
  }

  /**
   * Unpin cast on user profile.
   */
  unpinCastOnUserProfile(
    body: ApiUnpinCastOnUserProfileRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.delete<ApiUnpinCastOnUserProfile200Response>(
      '/v2/user-profile-pinned-casts',
      {
        headers,
        timeout,
        endpointName: 'unpinCastOnUserProfile',
        body,
      },
    );
  }

  /**
   * Unregister an app domain
   */
  devToolsUnregisterDomain(
    body: ApiDevToolsUnregisterDomainRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiDevToolsUnregisterDomain200Response>(
      '/v1/dev-tools/unregister-domain',
      {
        headers,
        timeout,
        endpointName: 'devToolsUnregisterDomain',
        body,
      },
    );
  }

  /**
   * Unsubscribes email address per FID. Duplicate of GET above, but for Gmail one-click unsubscribe.
   */
  unsubscribeFidOneClick(
    params: ApiUnsubscribeFidOneClickQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<void>('/unsubscribe-v2', {
      headers,
      timeout,
      endpointName: 'unsubscribeFidOneClick',
      params,
    });
  }

  /**
   * Unsubscribes email address per FID. Intended to be loaded via browser.
   */
  unsubscribeFid(
    params: ApiUnsubscribeFidQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/unsubscribe-v2', {
      headers,
      timeout,
      endpointName: 'unsubscribeFid',
      params,
    });
  }

  /**
   * Unsubscribes email address. Duplicate of GET above, but for Gmail one-click unsubscribe.
   */
  unsubscribeEmailOneClick(
    params: ApiUnsubscribeEmailOneClickQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<void>('/unsubscribe', {
      headers,
      timeout,
      endpointName: 'unsubscribeEmailOneClick',
      params,
    });
  }

  /**
   * Unsubscribes email address. Intended to be loaded via browser.
   */
  unsubscribeEmail(
    params: ApiUnsubscribeEmailQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/unsubscribe', {
      headers,
      timeout,
      endpointName: 'unsubscribeEmail',
      params,
    });
  }

  /**
   * Update (pause/unpause) job queue or queue shard
   */
  updateJobQueue(
    body: ApiUpdateJobQueueRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiUpdateJobQueue200Response>('/v1/job-queue', {
      headers,
      timeout,
      endpointName: 'updateJobQueue',
      body,
    });
  }

  /**
   * Update a channel
   */
  updateChannel(
    body: ApiUpdateChannelRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiUpdateChannel200Response>('/v2/channels-owned', {
      headers,
      timeout,
      endpointName: 'updateChannel',
      body,
    });
  }

  /**
   * Update a direct cast conversation.
   */
  fcPostConversation(
    body: ApiFcPostConversationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFcPostConversation200Response>('/fc/conversation', {
      headers,
      timeout,
      endpointName: 'fcPostConversation',
      body,
    });
  }

  /**
   * Update a direct cast group member's role.
   */
  fcPostGroupMembers(
    body: ApiFcPostGroupMembersRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFcPostGroupMembers200Response>('/fc/group-members', {
      headers,
      timeout,
      endpointName: 'fcPostGroupMembers',
      body,
    });
  }

  /**
   * Update a direct cast group.
   */
  fcPostGroup(
    body: ApiFcPostGroupRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiFcPostGroup200Response>('/fc/group', {
      headers,
      timeout,
      endpointName: 'fcPostGroup',
      body,
    });
  }

  /**
   * Update a favorite frame
   */
  updateFavoriteFrame(
    body: ApiUpdateFavoriteFrameRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiUpdateFavoriteFrame200Response>(
      '/v1/favorite-frames',
      {
        headers,
        timeout,
        endpointName: 'updateFavoriteFrame',
        body,
      },
    );
  }

  /**
   * Update a featured app
   */
  updateFeaturedApp(
    body: ApiUpdateFeaturedAppRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiUpdateFeaturedApp200Response>(
      '/v1/featured-app-admin/update',
      {
        headers,
        timeout,
        endpointName: 'updateFeaturedApp',
        body,
      },
    );
  }

  /**
   * Update a paid invite
   */
  updatePaidInvite(
    body: ApiUpdatePaidInviteRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiUpdatePaidInvite200Response>('/v2/invite', {
      headers,
      timeout,
      endpointName: 'updatePaidInvite',
      body,
    });
  }

  /**
   * Update a remote SIWF request
   */
  updateRemoteSiwfRequest(
    body: ApiUpdateRemoteSiwfRequestRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiUpdateRemoteSiwfRequest200Response>('/v1/remote-siwf', {
      headers,
      timeout,
      endpointName: 'updateRemoteSiwfRequest',
      body,
    });
  }

  /**
   * Update preferences for direct cast group.
   */
  updateDirectCastGroupPreferences(
    body: ApiUpdateDirectCastGroupPreferencesRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiUpdateDirectCastGroupPreferences200Response>(
      '/v2/direct-cast-group-preferences',
      {
        headers,
        timeout,
        endpointName: 'updateDirectCastGroupPreferences',
        body,
      },
    );
  }

  /**
   * Update starter pack
   */
  updateStarterPack(
    body: ApiUpdateStarterPackRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiUpdateStarterPack200Response>('/v2/starter-pack', {
      headers,
      timeout,
      endpointName: 'updateStarterPack',
      body,
    });
  }

  /**
   * Update the authenticated user's recovery address
   */
  updateRecoveryAddress(
    body: ApiUpdateRecoveryAddressRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiUpdateRecoveryAddress200Response>(
      '/v2/recovery-address',
      {
        headers,
        timeout,
        endpointName: 'updateRecoveryAddress',
        body,
      },
    );
  }

  /**
   * Update the primary address for a Farcaster user.
   */
  putPrimaryVerification(
    body: ApiPutPrimaryVerificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPutPrimaryVerification200Response>(
      '/v2/verifications/primary',
      {
        headers,
        timeout,
        endpointName: 'putPrimaryVerification',
        body,
      },
    );
  }

  /**
   * Update user's device contact book state
   */
  setContactsDeviceState(
    body: ApiSetContactsDeviceStateRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiSetContactsDeviceState200Response>(
      '/v2/update-contacts-device-state',
      {
        headers,
        timeout,
        endpointName: 'setContactsDeviceState',
        body,
      },
    );
  }

  /**
   * Updates
   */
  setUserPreferences(
    body: ApiSetUserPreferencesRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiSetUserPreferences200Response>(
      '/v2/user-preferences',
      {
        headers,
        timeout,
        endpointName: 'setUserPreferences',
        body,
      },
    );
  }

  /**
   * Updates attributes for the currently authenticated user.
   */
  updateUser(
    body: ApiUpdateUserRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiUpdateUser200Response>('/v2/me', {
      headers,
      timeout,
      endpointName: 'updateUser',
      body,
    });
  }

  /**
   * Updates the location attribute for the currently authenticated user.
   */
  updateUserLocation(
    body: ApiUpdateUserLocationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.patch<ApiUpdateUserLocation200Response>('/v2/my-location', {
      headers,
      timeout,
      endpointName: 'updateUserLocation',
      body,
    });
  }

  /**
   * Upgrades the auth token
   */
  postStepUpMessage(
    body: ApiPostStepUpMessageRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiPostStepUpMessage200Response>('/v2/auth/step-up', {
      headers,
      timeout,
      endpointName: 'postStepUpMessage',
      body,
    });
  }

  /**
   * Upload user's contact book
   */
  uploadContacts(
    body: ApiUploadContactsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiUploadContacts200Response>('/v2/contacts', {
      headers,
      timeout,
      endpointName: 'uploadContacts',
      body,
    });
  }

  /**
   * Uploads text to be scraped to generate a preview.
   */
  processCastAttachments(
    body: ApiProcessCastAttachmentsRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiProcessCastAttachments200Response>(
      '/v2/cast-attachments',
      {
        headers,
        timeout,
        endpointName: 'processCastAttachments',
        body,
      },
    );
  }

  /**
   * Validate composer action metadata and POST to composer action URL
   */
  validateComposerAction(
    body: ApiValidateComposerActionRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiValidateComposerAction200Response>(
      '/v2/validate-composer-action',
      {
        headers,
        timeout,
        endpointName: 'validateComposerAction',
        body,
      },
    );
  }

  /**
   * Verifies email address. Intended to be loaded via browser.
   */
  verifyEmail(
    params: ApiVerifyEmailQueryParams,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.authedGet<void>('/v2/emails/verify', {
      headers,
      timeout,
      endpointName: 'verifyEmail',
      params,
    });
  }

  /**
   * Verify a TOTP code for a user
   */
  verifyTotpCode(
    body: ApiVerifyTotpCodeRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiVerifyTotpCode200Response>('/v2/totp/verify', {
      headers,
      timeout,
      endpointName: 'verifyTotpCode',
      body,
    });
  }

  /**
   * Verify a TOTP code for a user
   */
  verifyTotpCodeForEmail(
    body: ApiVerifyTotpCodeForEmailRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiVerifyTotpCodeForEmail200Response>(
      '/v2/totp/verify-for-email',
      {
        headers,
        timeout,
        endpointName: 'verifyTotpCodeForEmail',
        body,
      },
    );
  }

  /**
   * Verify an address for a Farcaster user.
   */
  putVerification(
    body: ApiPutVerificationRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.put<ApiPutVerification200Response>('/v2/verifications', {
      headers,
      timeout,
      endpointName: 'putVerification',
      body,
    });
  }

  /**
   * Vote on a poll
   */
  recordPollVote(
    body: ApiRecordPollVoteRequestBody,
    { headers, timeout }: { headers?: RequestHeaders; timeout?: number } = {},
  ) {
    return this.post<ApiRecordPollVote200Response>('/v2/record-poll-vote', {
      headers,
      timeout,
      endpointName: 'recordPollVote',
      body,
    });
  }

  /**
   * Webhook for creator rewards frame
   */
  creatorRewardsFrameWebhook({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiCreatorRewardsFrameWebhook200Response>(
      '/v1/creator-rewards-frame-webhook',
      {
        headers,
        timeout,
        endpointName: 'creatorRewardsFrameWebhook',
        body: {},
      },
    );
  }

  /**
   * Webhook for featured mint frame
   */
  featuredMintFrameWebhook({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.post<ApiFeaturedMintFrameWebhook200Response>(
      '/v1/featured-mint-frame-webhook',
      {
        headers,
        timeout,
        endpointName: 'featuredMintFrameWebhook',
        body: {},
      },
    );
  }

  /**
   * X auth callbacks
   */
  authenticateX({
    headers,
    timeout,
  }: { headers?: RequestHeaders; timeout?: number } = {}) {
    return this.authedGet<void>('/auth/x', {
      headers,
      timeout,
      endpointName: 'authenticateX',
    });
  }
}
