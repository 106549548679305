import { PersonAddIcon, SignInIcon } from '@primer/octicons-react';
import { FC, memo, useState } from 'react';

import { DefaultButton } from '~/components/forms/buttons/DefaultButton';
import { LoginModal } from '~/components/modals/LoginModal';
import { Search } from '~/components/search/Search';
import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { useCurrentRoute } from '~/hooks/navigation/useCurrentRoute';
import { useNavigate } from '~/hooks/navigation/useNavigate';
import { RightSideBar } from '~/layouts/RightSideBar';
import { SupportAndTermsLinks } from '~/layouts/SupportAndTermsLinks';
import { WebAmpEvent } from '~/types';

const UnauthedRightSideBar: FC = memo(() => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const currentRoute = useCurrentRoute();
  const routeFamily = currentRoute?.routeDefinition.family;

  return (
    <RightSideBar className="pt-3">
      {routeFamily === 'search' ? <></> : <Search showClearIcon={false} />}
      <div className="mt-3 grid w-full grow grid-cols-2 flex-row gap-2">
        <DefaultButton
          className="min-w-0 mdlg:min-w-[110px]"
          onClick={() => {
            trackEvent(WebAmpEvent.ClickedLogin, undefined);
            setIsLoggingIn(true);
          }}
        >
          <div className="hidden mdlg:block">Login</div>
          <div className="mdlg:hidden">
            <SignInIcon />
          </div>
        </DefaultButton>
        <DefaultButton
          className="min-w-0 mdlg:min-w-[110px]"
          onClick={() => {
            trackEvent(WebAmpEvent.ClickedCreateNewAccount, undefined);
            navigate({ to: 'signup', params: {} });
          }}
        >
          <div className="hidden mdlg:block">Create account</div>
          <div className="mdlg:hidden">
            <PersonAddIcon />
          </div>
        </DefaultButton>
      </div>
      <SupportAndTermsLinks />
      {isLoggingIn && <LoginModal onClose={() => setIsLoggingIn(false)} />}
    </RightSideBar>
  );
});

UnauthedRightSideBar.displayName = 'UnauthedRightSideBar';

export { UnauthedRightSideBar };
