import { ApiFrame } from 'farcaster-client-data';
import { useCallback } from 'react';

import { useFarcasterApiClient, useTrackEvent } from '../../../providers';
import { SharedAmpEvent } from '../../../utils';
import { frameAnalyticsProperties } from '../../generic';
import {
  useInvalidateFavoriteFrames,
  useInvalidateFeaturedFrames,
  useInvalidateFrameDetails,
  useOptimisticallyUpdateFrame,
} from '../queries';

export function useUpdateFavoriteFrame() {
  const { apiClient } = useFarcasterApiClient();
  const { trackEvent } = useTrackEvent();

  const optimisticallyUpdateFrame = useOptimisticallyUpdateFrame();
  const invalidateFavoriteFrames = useInvalidateFavoriteFrames();
  const invalidateFeaturedFrames = useInvalidateFeaturedFrames();
  const invalidateFrameDetails = useInvalidateFrameDetails();

  return useCallback(
    async ({
      frame,
      disableNotifications,
      position,
    }: {
      frame: ApiFrame;
      disableNotifications?: boolean;
      position?: number;
    }) => {
      let revertOptimisticUpdate;
      if (disableNotifications) {
        revertOptimisticUpdate = optimisticallyUpdateFrame({
          domain: frame.domain,
          viewerContext: {
            notificationsEnabled: false,
            notificationDetails: undefined,
          },
        });
      }

      try {
        await apiClient.updateFavoriteFrame({
          domain: frame.domain,
          disableNotifications,
          position,
        });

        trackEvent(
          SharedAmpEvent.EnableFrameNotifications,
          frameAnalyticsProperties({
            frameName: frame.name,
            frameUrl: frame.homeUrl,
            author: frame.author,
          }),
        );

        setTimeout(() => {
          invalidateFavoriteFrames();
          invalidateFeaturedFrames();
          invalidateFrameDetails({ domain: frame.domain });
        }, 3000);
      } catch (e: unknown) {
        if (revertOptimisticUpdate) {
          revertOptimisticUpdate();
        }
        invalidateFavoriteFrames();
        invalidateFeaturedFrames();
        invalidateFrameDetails({ domain: frame.domain });
        throw e;
      }
    },
    [
      apiClient,
      invalidateFavoriteFrames,
      invalidateFeaturedFrames,
      invalidateFrameDetails,
      optimisticallyUpdateFrame,
      trackEvent,
    ],
  );
}
