import { SignIn, SignInOptions } from '@farcaster/frame-host';
import * as Dialog from '@radix-ui/react-dialog';
import {
  useCreateRemoteSiwfRequest,
  useRemoteSiwfRequestQuery,
  useUpdateRemoteSiwfRequest,
  useUserByFid,
} from 'farcaster-client-hooks';
import { Siwe } from 'ox';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getAddress } from 'viem';

import { Image } from '~/components/images/Image';
import { useCurrentUser } from '~/hooks/data/useCurrentUser';

import phone from './phone.png';
import phoneDark from './phoneDark.png';

const useRemoteSiwf = ({
  domain,
  targetUrl,
  options,
}: {
  domain: string;
  targetUrl: string;
  options: SignInOptions;
}) => {
  const [token, setToken] = useState<string>();
  const createRequest = useCreateRemoteSiwfRequest();
  const user = useCurrentUser();
  const { result: userProfile } = useUserByFid({ fid: user.fid }).data!;

  const runOnce = useRef(false);

  useEffect(() => {
    (async () => {
      if (runOnce.current === false) {
        runOnce.current = true;

        const data = {
          version: '1',
          address: getAddress(userProfile.extras.custodyAddress),
          statement: 'Farcaster Auth',
          chainId: 10,
          resources: [`farcaster://fid/${user.fid}`] as string[],
          domain,
          // ensure valid RFC 3986 resource URI, a bit surprised this is needed
          // but URLs of origins without trailing slashes were throwing from ox
          uri: new URL(targetUrl).href,
          nonce: options.nonce,
          notBefore: options.notBefore
            ? new Date(options.notBefore)
            : undefined,
          expirationTime: options.expirationTime
            ? new Date(options.expirationTime)
            : undefined,
        } as const satisfies Siwe.Message;

        const message = Siwe.createMessage(data);
        const res = await createRequest({
          source: {
            type: 'frame',
            domain,
          },
          message,
        });

        setToken(res.token);
      }
    })();
  }, [
    createRequest,
    domain,
    options.expirationTime,
    options.nonce,
    options.notBefore,
    targetUrl,
    user.fid,
    userProfile.extras.custodyAddress,
  ]);

  const { data } = useRemoteSiwfRequestQuery(
    {
      token,
    },
    {
      refetchInterval: 250,
      enabled: !!token,
    },
  );

  const updateRequest = useUpdateRemoteSiwfRequest();
  const onDismiss = useCallback(async () => {
    if (token) {
      void updateRequest({ token, error: 'dismissed' });
    }
  }, [token, updateRequest]);

  return {
    onDismiss,
    token,
    request: data?.result.request,
  };
};

interface SignInDialogProps {
  domain: string;
  targetUrl: string;
  options: SignInOptions;
  name?: string;
  onDismiss: () => void;
  onSignIn: (result: SignIn.SignInResult) => void;
  renderInPortal?: boolean;
}

export const SignInDialog: React.FC<SignInDialogProps> = ({
  domain,
  targetUrl,
  options,
  name,
  onDismiss,
  onSignIn,
  renderInPortal = true,
}) => {
  const { request, onDismiss: handleDismiss } = useRemoteSiwf({
    domain,
    targetUrl,
    options,
  });

  useEffect(() => {
    if (request) {
      if (request.error === 'rejected') {
        onDismiss();
        return;
      }

      if (request.signature) {
        onSignIn({
          message: request.message,
          signature: request.signature,
        });
      }
    }
  }, [onDismiss, onSignIn, request]);

  if (renderInPortal) {
    return (
      <Dialog.Root
        defaultOpen
        onOpenChange={(open) => {
          if (!open) {
            handleDismiss();
            onDismiss();
          }
        }}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-10 animate-overlay-show bg-overlay" />
          <Dialog.Content className="fixed left-[50%] top-[50%] z-20 translate-x-[-50%] translate-y-[-50%] animate-content-show focus:outline-none">
            <SignInDialogInner name={name ?? domain} />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  return (
    <>
      <div
        className="absolute inset-x-0 bottom-0 top-[60px] animate-overlay-show bg-black/30 dark:bg-white/30"
        onClick={(e) => {
          e.stopPropagation();
          handleDismiss();
          onDismiss();
        }}
      />

      <div
        className="absolute inset-x-4 bottom-4 z-50"
        onClick={(e) => e.stopPropagation()}
      >
        <SignInDialogInner name={name ?? domain} />
      </div>
    </>
  );
};

interface SignInDialogInnerProps {
  name: string;
}

const SignInDialogInner: React.FC<SignInDialogInnerProps> = ({ name }) => {
  return (
    <div className="mx-auto w-full max-w-[424px] animate-frame-action-content-show space-y-3 rounded-xl px-4 py-8 bg-app border-default">
      <div className="relative mx-auto flex h-[144px] w-[144px] items-center justify-center">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="h-[63px] w-[163px] rounded-lg bg-faint" />
        </div>
        <Image
          src={phone}
          alt="phone"
          className="z-0 block h-[98px] w-[53px] dark:hidden"
        />
        <Image
          src={phoneDark}
          alt="phone"
          className="z-0 hidden h-[98px] w-[53px] dark:block"
        />
      </div>

      <h3 className="text-xl font-semibold">
        Open Warpcast on your phone to
        <br />
        Sign in with Farcaster
      </h3>
      <div className="text-muted">
        Tap "continue" in your Warpcast mobile app to continue using {name}.
      </div>
    </div>
  );
};
