// eslint-disable-next-line no-restricted-imports
import { useFeatureGate } from 'farcaster-client-hooks';
import { createContext, FC, memo, ReactNode, useContext, useMemo } from 'react';

type GlobalGateContextValue = {
  scheduledCasts: boolean;
  invitesLeaderboard: boolean;
  starterPackFeeds: boolean;
  creatorLabels: boolean;
  earningsPage: boolean;
  sentFromEarth: boolean;
};

const GlobalGateContext = createContext<GlobalGateContextValue>({
  scheduledCasts: false,
  invitesLeaderboard: false,
  starterPackFeeds: false,
  creatorLabels: false,
  earningsPage: false,
  sentFromEarth: false,
});

type GlobalGateProviderProps = {
  children: ReactNode;
};

// This provider lets us check a gate value globally and share the
// result to many children. Otherwise Statsig will send a new
// exposure event each time the gate is checked which makes calling
// it in things like feed items impractical.
const GlobalGateProvider: FC<GlobalGateProviderProps> = memo(({ children }) => {
  const { value: scheduledCasts } = useFeatureGate('scheduled_casts');
  const { value: invitesLeaderboard } = useFeatureGate('invites_leaderboard');
  const { value: starterPackFeeds } = useFeatureGate('starter_pack_feeds');
  const { value: creatorLabels } = useFeatureGate('creator_labels');
  const { value: earningsPage } = useFeatureGate('earnings_page');
  const { value: sentFromEarth } = useFeatureGate('sent_from_earth');

  const contextValue = useMemo(
    () => ({
      creatorLabels,
      invitesLeaderboard,
      scheduledCasts,
      starterPackFeeds,
      earningsPage,
      sentFromEarth,
    }),

    [
      creatorLabels,
      invitesLeaderboard,
      scheduledCasts,
      starterPackFeeds,
      earningsPage,
      sentFromEarth,
    ],
  );

  return (
    <GlobalGateContext.Provider value={contextValue}>
      {children}
    </GlobalGateContext.Provider>
  );
});

const useGlobalGate = () => useContext(GlobalGateContext);

export { GlobalGateProvider, useGlobalGate };
