import { useSuspenseQuery } from '@tanstack/react-query';

import { useFarcasterApiClient } from '../../../../providers/FarcasterApiClientProvider';
import { useMergeIntoGloballyCachedUser } from '../globallyCachedUser/useMergeIntoGloballyCachedUser';
import { buildUserFetcher } from './buildUserFetcher';
import { buildUserKey } from './buildUserKey';

const useUser = ({ fid }: { fid: number }) => {
  const { apiClient } = useFarcasterApiClient();
  const mergeIntoGloballyCachedUser = useMergeIntoGloballyCachedUser();

  const result = useSuspenseQuery({
    queryKey: buildUserKey({ fid }),

    queryFn: buildUserFetcher({
      apiClient,
      fid,
      mergeIntoGloballyCachedUser,
    }),
  });

  return result;
};

export { useUser };
