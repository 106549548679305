import { ApiFrame } from 'farcaster-client-data';
import { useCallback } from 'react';

import { useFarcasterApiClient, useTrackEvent } from '../../../providers';
import { SharedAmpEvent } from '../../../utils';
import { frameAnalyticsProperties } from '../../generic';
import {
  useInvalidateFavoriteFrames,
  useInvalidateFeaturedFrames,
  useInvalidateFrameDetails,
  useOptimisticallyUpdateFrame,
} from '../queries';

export function useEnableFrameNotifications() {
  const { apiClient } = useFarcasterApiClient();
  const { trackEvent } = useTrackEvent();

  const optimisticallyUpdateFrame = useOptimisticallyUpdateFrame();
  const invalidateFavoriteFrames = useInvalidateFavoriteFrames();
  const invalidateFeaturedFrames = useInvalidateFeaturedFrames();
  const invalidateFrameDetails = useInvalidateFrameDetails();

  return useCallback(
    async ({ domain, name, homeUrl, author }: ApiFrame) => {
      const revertOptimisticUpdate = optimisticallyUpdateFrame({
        domain,
        viewerContext: {
          notificationsEnabled: true,
        },
      });

      try {
        const result = await apiClient.enableFrameNotifications({ domain });

        trackEvent(
          SharedAmpEvent.EnableFrameNotifications,
          frameAnalyticsProperties({
            frameName: name,
            frameUrl: homeUrl,
            author,
          }),
        );

        // setTimeout(() => {
        //   invalidateFavoriteFrames();
        //   invalidateFeaturedFrames();
        //   invalidateFrameDetails({ domain });
        // }, 3000);

        return result.data.result.notificationDetails;
      } catch (e: unknown) {
        revertOptimisticUpdate();
        invalidateFavoriteFrames();
        invalidateFeaturedFrames();
        invalidateFrameDetails({ domain });
        throw e;
      }
    },
    [
      apiClient,
      invalidateFavoriteFrames,
      invalidateFeaturedFrames,
      invalidateFrameDetails,
      optimisticallyUpdateFrame,
      trackEvent,
    ],
  );
}
