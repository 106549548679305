import { useQueryClient } from '@tanstack/react-query';
import {
  ApiGetUserPreferences200Response,
  ApiUserPreferences,
} from 'farcaster-client-data';
import { useCallback } from 'react';

import { useFarcasterApiClient } from '../../../providers';
import {
  buildUserPreferencesKey,
  useInvalidateUserPreferences,
} from '../queries';

const useOptimisticSetUserPreferences = () => {
  const queryClient = useQueryClient();

  return useCallback(
    async ({ preferences }: { preferences: Partial<ApiUserPreferences> }) => {
      queryClient.setQueryData(
        buildUserPreferencesKey(),
        (current: ApiGetUserPreferences200Response) => {
          return {
            result: {
              preferences: {
                ...current.result.preferences,
                ...preferences,
              },
            },
          };
        },
      );
    },
    [queryClient],
  );
};

const useSetUserPreferences = () => {
  const { apiClient } = useFarcasterApiClient();
  const invalidateUserPreferences = useInvalidateUserPreferences();

  return useCallback(
    async ({ preferences }: { preferences: ApiUserPreferences }) => {
      const response = await apiClient.setUserPreferences({ preferences });

      invalidateUserPreferences();

      return response.data;
    },
    [apiClient, invalidateUserPreferences],
  );
};

export { useOptimisticSetUserPreferences, useSetUserPreferences };
