export enum AmpEvent {
  // Should likely deprecate this
  AddCastModalShown = 'add cast modal shown',
  VisitingExternalURL = 'visiting external url',
  CastMessage = 'cast message',
  ViewProfileFeed = 'view profile feed',
  ViewProfileLikes = 'view profile likes',
  ViewProfileStarterPacks = 'view profile starter packs',
  ViewProfileChannels = 'view profile channels',
  ViewSuggestedUsers = 'view for you users',
  ViewRecentUsers = 'view recent users',
  ViewPrimaryCast = 'view primary cast',
  ViewInvitersLeaderboard = 'view inviters leaderboard',
  ClickLocation = 'click location',
  ClickInviter = 'click inviter',
  UpdatePfp = 'update pfp',
  UpdateEmail = 'update email',
  UpdatedBio = 'updated bio',
  UpdateDisplayName = 'update display name',
  SetLocation = 'set location',
  ClickShowMoreNftMint = 'click show more nft mint',
  ClickProfileCollectionBadge = 'click profile collection badge',
  ExpandActiveBadge = 'expand active badge',
  ExpandTopInChannelBadge = 'expand top in channel badge',
  ComposeCast = 'compose cast',
  ShowFollowsDisabledPrompt = 'show follows disabled prompt',
  EnabledInAppNotificationForType = 'enabled in-app notification for type',
  DisabledInAppNotificationForType = 'disabled in-app notification for type',
  EnabledEmailNotificationForCategory = 'enabled email notification for category',
  DisabledEmailNotificationForCategory = 'disabled email notification for category',
  ViewAllChannels = 'view all channels',
  CreateDirectCast = 'create direct cast',
  SendDirectCastWithImage = 'send direct cast with image',
  DirectCastFailedToSend = 'direct cast failed to send shown',
  ClickProfileDirectCast = 'click profile direct cast',
  ViewDirectCastsInbox = 'view direct cast inbox',
  ViewDirectCastsConversation = 'view direct cast conversation',
  EnabledDirectCastsFromEveryone = 'enabled direct casts from everyone',
  DisabledDirectCastsFromEveryone = 'disabled direct casts from everyone',
  ClickDirectCastInviteCreateAccount = 'click direct cast invite create account',
  ViewDirectCastInvite = 'view direct cast invite',
  ClientDirectCastInviteJoin = 'click direct cast invite join',
  JoinDirectCastsGroup = 'join member direct casts group',
  ClickUserCastNotification = 'click user cast notification',
  BuyWarps = 'buy warps',
  ViewAMA = 'view ama',
  ClickAMACreateAccount = 'click ama create account',
  ClickAMAOpenWarpcast = 'click ama open warpcast',
  ClickSidebarChannelCompose = 'click sidebar channel compose',
  ClickMintOnCastAttachment = 'click mint with warps on cast attachment',
  ClickMintWithWarpsInModal = 'click mint with warps in modal',
  MintWithWarpsFailed = 'mint with warps failed',
  ViewWarpsScreen = 'view warps screen',
  ClickGiftWarpsOnProfile = 'click gift warps on profile',
  ClickGiftWarpsInModal = 'click gift warps in modal',
  GiftWarpsFailed = 'gift warps failed',
  MintWithWarpsDeepLinked = 'mint with warps deep linked',
  ComposerActionDeepLinked = 'composer action deep linked',
  CreateGroupDirectCasts = 'create group direct casts',
  MuteDirectCastsGroup = 'mute direct casts group',
  LeaveDirectCastsGroup = 'leave direct casts group',
  ArchiveDirectCastsGroup = 'archive direct casts group',
  UnarchiveDirectCastsGroup = 'unarchive direct casts group',
  AddMemberDirectCastsGroup = 'add member direct casts group',
  RemoveMemberDirectCastsGroup = 'remove member direct casts group',
  PromoteMemberDirectCastsGroup = 'promote member direct casts group',
  DemoteMemberDirectCastsGroup = 'demote member direct casts group',
  ClickTipReplyWithDirectCast = 'click tip reply with direct cast on warps',
  ClickMintOpenInBrowser = 'click mint open in browser on warps',
  ClickAd = 'click ad',
  WebsocketFailedOver = 'websocket failed over',
  ClickSearchDirectCastUser = 'click direct cast user from search',
  MarkConversationAsUnread = 'mark conversation as unread',
  ClickCreateAccountFromJoinChannel = 'click create account from join channel',
  ClickOpenWarpcastStarterPack = 'click open warpcast starter pack',
  ClickOpenWarpcastToken = 'click open warpcast token',
}

// Keeping a separate enum for web-only events for maintenance ease.
export enum WebAmpEvent {
  ViewSignUpLandingPage = 'view sign up landing page',
  ClickedLogin = 'clicked login',
  ClickedSendMagicLink = 'clicked send magic link',
  ClickedMagicLink = 'clicked login with magic link',
  ClickedCreateNewAccount = 'clicked create new account',
  ViewLoginWithMobileScreen = 'view login with mobile modal',
  ViewLoginWithMobilePasskeysScreen = 'view login with mobile passkeys modal',
  ViewLoginWithMagicLinkScreen = 'view login with magic link modal',
  ViewLoginConfirmCodeScreen = 'view login confirm code screen',
  LoggedInToWebUsingMobile = 'logged in to web using mobile',
  LoggedInToWebUsingMagicLink = 'logged in to web using magic link',
  LoggedInToWebUsingCompanion = 'logged in to web using companion',
}

// primarily used for anonymous users on web
export enum FathomEvent {
  ClickedLogin = 'clicked login',
  ClickedDownloadApple = 'clicked download apple',
  ClickedDownloadGoogle = 'clicked download google',
}

export type AmpEvents = AmpEvent | WebAmpEvent;

export type AmpEventData =
  | Record<string, string | boolean | number | undefined>
  | undefined;
