import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

interface IFrameLoaderProps
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  fallback?: React.ReactNode;
  width: number;
  height: number;
  transition?: boolean;
}

export interface IFrameLoaderRef {
  postMessage: (message: unknown, origin: string) => void;
  refresh: () => void;
  iframe: HTMLIFrameElement | null;
}

export const IFrameLoader = forwardRef<
  IFrameLoaderRef,
  PropsWithChildren<IFrameLoaderProps>
>(({ width, height, transition, ...iframeProps }, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setIsLoaded(false);
  }, [iframeProps.src]);

  const handleLoad = (
    event: React.SyntheticEvent<HTMLIFrameElement, Event>,
  ) => {
    setIsLoaded(true);
    iframeProps.onLoad?.(event);
  };

  useImperativeHandle(ref, () => ({
    postMessage: (message: unknown, origin: string) => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(message, origin);
      }
    },
    refresh: () => {
      if (iframeRef.current) {
        iframeRef.current.src = iframeRef.current.src;
      }
    },
    iframe: iframeRef.current,
  }));

  return (
    <div className="relative" style={{ width, height }}>
      <iframe
        {...iframeProps}
        ref={iframeRef}
        className={`scrollbar-hide h-full w-full ${isLoaded ? 'opacity-100' : 'opacity-0'} ${transition ? 'transition-opacity duration-1000' : ''} ${iframeProps.className || ''}`}
        onLoad={handleLoad}
      />
    </div>
  );
});
