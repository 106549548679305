import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useFarcasterApiClient } from '../../../../providers';
import { buildFrameDetailsFetcher } from './buildFrameDetailsFetcher';
import { buildFrameDetailsKey } from './buildFrameDetailsKey';
import { useMergeIntoGloballyCachedFrame } from './framesGlobalCache';

export const useFrameDetails = ({ domain }: { domain: string }) => {
  const { apiClient } = useFarcasterApiClient();
  const mergeIntoGlobalCache = useMergeIntoGloballyCachedFrame();

  const result = useSuspenseQuery({
    queryKey: buildFrameDetailsKey({ domain }),
    queryFn: buildFrameDetailsFetcher({
      domain,
      apiClient,
      mergeIntoGlobalCache,
    }),
  });

  const frame = result.data;
  return useMemo(
    () => ({
      ...result,
      data: frame,
    }),
    [frame, result],
  );
};

export const useNonSuspenseFrameDetails = ({
  domain,
  enabled = true,
}: {
  domain: string;
  enabled?: boolean;
}) => {
  const { apiClient } = useFarcasterApiClient();
  const mergeIntoGlobalCache = useMergeIntoGloballyCachedFrame();

  const result = useQuery({
    queryKey: buildFrameDetailsKey({ domain }),
    queryFn: buildFrameDetailsFetcher({
      domain,
      apiClient,
      mergeIntoGlobalCache,
    }),
    enabled,
  });

  const frame = result.data || undefined;
  return useMemo(
    () => ({
      ...result,
      data: frame,
    }),
    [frame, result],
  );
};
