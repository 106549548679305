import { formatDistanceStrict, setDefaultOptions } from 'date-fns';
import locale from 'date-fns/locale/en-US';

export const MILLIS_PER_SECOND = 1000;
export const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SECOND;
export const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
export const MILLIS_PER_DAY = 24 * MILLIS_PER_HOUR;

export const formatDuration = (
  msec: number,
  addMore: boolean = false,
): string => {
  const more = addMore ? 'more ' : '';

  if (msec > MILLIS_PER_DAY) {
    return `${Math.ceil(msec / MILLIS_PER_DAY)} ${more}days`;
  }
  if (msec > MILLIS_PER_HOUR) {
    return `${Math.ceil(msec / MILLIS_PER_HOUR)} ${more}hours`;
  }
  const numMinutes = Math.ceil(msec / MILLIS_PER_MINUTE);
  return `${numMinutes} ${more}minute${numMinutes > 1 ? 's' : ''}`;
};

export const formatTimeAgo = (
  timestamp: number,
  roundingMethod: 'floor' | 'ceil' | 'round' = 'round',
) => {
  return formatDistanceStrict(new Date(timestamp), new Date(), {
    roundingMethod,
  });
};

const formatDistanceLocale: { [key: string]: string } = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}mo',
  xMonths: '{{count}}mo',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
};

const formatDistance = (token: string, count: number) => {
  const result = formatDistanceLocale[token].replace(
    '{{count}}',
    count.toString(),
  );

  return result;
};

export const initDateFns = () =>
  setDefaultOptions({
    locale: {
      ...locale,
      formatDistance,
    },
  });
