import cn from 'classnames';
import { FC, memo, useMemo } from 'react';

import { Image } from '~/components/images/Image';
import {
  defaultAvatarUrl,
  lg2AvatarDiameter,
  lgAvatarDiameter,
  mdAvatarDiameter,
  sm2AvatarDiamater,
  smAvatarDiameter,
  xl2AvatarDiameter,
  xlAvatarDiameter,
  xs2AvatarDiameter,
  xsAvatarDiameter,
} from '~/constants/avatar';
import { applyCloudflarePath } from '~/utils/images';

type AvatarSize =
  | 'xs2'
  | 'xs'
  | '24'
  | 'sm'
  | 'sm2'
  | 'md'
  | 'lg'
  | 'lg2'
  | 'xl'
  | 'xlx'
  | 'xl2';

type AvatarImageProps = {
  className?: string;
  style?: React.CSSProperties;
  size?: AvatarSize;
  imgUrl?: string;
  imgAlt: string;
  loading?: HTMLImageElement['loading'];
  halo?: 'purple' | 'green';
};

const AvatarImage: FC<AvatarImageProps> = memo(
  ({ className, size = 'md', imgUrl, imgAlt, loading, style, halo }) => {
    const { length } = useMemo(() => {
      switch (size) {
        case 'xs2':
          return {
            length: xs2AvatarDiameter,
          };
        case 'xs':
          return {
            length: xsAvatarDiameter,
          };
        case 'sm':
          return {
            length: smAvatarDiameter,
          };
        case 'sm2':
          return {
            length: sm2AvatarDiamater,
          };
        case 'md':
          return {
            length: mdAvatarDiameter,
          };
        case 'lg':
          return {
            length: lgAvatarDiameter,
          };
        case 'lg2':
          return {
            length: lg2AvatarDiameter,
          };
        case 'xl2':
          return {
            length: xl2AvatarDiameter,
          };
        default:
          return {
            length: xlAvatarDiameter,
          };
      }
    }, [size]);

    const src = useMemo(() => {
      if (typeof imgUrl === 'undefined') {
        return applyCloudflarePath(defaultAvatarUrl, length);
      }

      return applyCloudflarePath(imgUrl, length * 2);
    }, [imgUrl, length]);

    if (!halo) {
      return (
        <Image
          src={src}
          className={cn(
            'aspect-square shrink-0 rounded-full border object-cover bg-app border-default',
            className,
          )}
          style={{
            width: length,
            height: length,
            minWidth: length,
            minHeight: length,
            ...style,
          }}
          alt={imgAlt}
          fallback={defaultAvatarUrl}
          loading={loading}
        />
      );
    }

    return (
      <div
        className={cn(
          'relative flex flex-none items-center justify-center rounded-full',
          halo === 'purple' && 'bg-gradient-to-b from-[#DEAEEB] to-[#6944BA]',
          halo === 'green' && 'bg-gradient-to-b from-[#32CD32] to-[#00FF7F]',
          className,
        )}
        style={{
          width: length,
          height: length,
          minWidth: length,
          minHeight: length,
        }}
      >
        <Image
          src={src}
          className={cn(
            'aspect-square shrink-0 rounded-full object-cover bg-app',
          )}
          style={{
            width: length - 4,
            height: length - 4,
            minWidth: length - 4,
            minHeight: length - 4,
          }}
          alt={imgAlt}
          fallback={defaultAvatarUrl}
          loading={loading}
        />
      </div>
    );
  },
);

AvatarImage.displayName = 'AvatarImage';

export { AvatarImage, type AvatarImageProps };
