import { Context } from '@farcaster/frame-host';
import { ApiUser } from 'farcaster-client-data';
import React, { useCallback, useMemo } from 'react';

import {
  AppFrameModal,
  FrameLaunchConfig,
} from '~/components/modals/AppFrameModal';

type DevPreviewLaunchContext = {
  type: 'dev_preview';
};

export type LaunchContext = DevPreviewLaunchContext | Context.LocationContext;

type AppFrameContextValue = {
  launchFrame: (params: {
    launchConfig: FrameLaunchConfig;
    context: LaunchContext;
    debug?: boolean;
  }) => void;
  dismiss: () => void;
};

const AppFrameContext = React.createContext<AppFrameContextValue>({
  launchFrame: () => {
    throw new Error('No AppFrameProvider');
  },
  dismiss: () => {
    throw new Error('No AppFrameProvider');
  },
});

type AppFrameProviderProps = {
  children: React.ReactNode;
};

export const AppFrameProvider: React.FC<AppFrameProviderProps> = ({
  children,
}) => {
  const [params, setParams] = React.useState<{
    launchConfig: FrameLaunchConfig;
    context: LaunchContext;
    debug?: boolean;
  } | null>(null);

  const launchFrame = useCallback(
    ({
      launchConfig,
      debug,
      context,
    }: {
      launchConfig: FrameLaunchConfig;
      context: LaunchContext;
      author?: ApiUser;
      debug?: boolean;
    }) => {
      setParams({
        launchConfig,
        debug,
        context,
      });
    },
    [],
  );

  const dismiss = useCallback(() => {
    setParams(null);
  }, []);

  const contextValue = useMemo(
    () => ({
      launchFrame,
      dismiss,
    }),
    [launchFrame, dismiss],
  );

  return (
    <AppFrameContext.Provider value={contextValue}>
      {children}
      {params && (
        <AppFrameModal
          launchConfig={params.launchConfig}
          context={params.context}
          debug={params.debug}
          onClose={dismiss}
        />
      )}
    </AppFrameContext.Provider>
  );
};

export const useLaunchFrame = () => React.useContext(AppFrameContext);
