import {
  darkTheme,
  getDefaultConfig,
  lightTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { ThemeOptions } from '@rainbow-me/rainbowkit/dist/themes/baseTheme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { degen, unichain } from 'viem/chains';
import { WagmiProvider } from 'wagmi';
import {
  arbitrum,
  arbitrumSepolia,
  base,
  baseSepolia,
  gnosis,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  sepolia,
  zora,
} from 'wagmi/chains';

import { useIsDarkMode } from '~/hooks/useIsDarkMode';

const config = getDefaultConfig({
  appName: 'Warpcast',
  projectId: '1b99af504ddb38a0f6dc1b85e16ef5c9',
  appIcon: 'https://warpcast.com/1024x1024.png',
  chains: [
    arbitrum,
    arbitrumSepolia,
    base,
    baseSepolia,
    degen,
    gnosis,
    optimism,
    optimismSepolia,
    polygon,
    zora,
    unichain,
    sepolia,
    mainnet,
  ],
});

const themeOptions: ThemeOptions = {
  accentColor: '#8565CB',
  accentColorForeground: 'white',
  fontStack: 'system',
  borderRadius: 'small',
};

const rainbowKitDarkTheme = darkTheme(themeOptions);
const rainbowKitLightTheme = lightTheme(themeOptions);

const queryClient = new QueryClient();

interface FrameTransactionsWalletProviderProps {
  children: ReactNode;
}

/**
 * Includes a plan QueryClient that Rainbow will use that doesn't include
 * suspense. This needs to rendered outside of our normal QueryClientProvider
 * so that our normal queryClient will take precedent within our app.
 */
const FrameTransactionsWalletProvider: FC<
  FrameTransactionsWalletProviderProps
> = ({ children }) => {
  const [theme, setTheme] = useState(rainbowKitLightTheme);
  const isDarkMode = useIsDarkMode();

  useEffect(() => {
    if (isDarkMode) {
      setTheme(rainbowKitDarkTheme);
    }
  }, [isDarkMode]);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={theme} modalSize="compact">
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

FrameTransactionsWalletProvider.displayName = 'FrameTransactionsWalletProvider';

export { FrameTransactionsWalletProvider };
