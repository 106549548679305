import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { buildFeaturedFramesKey } from './buildFeaturedFramesKey';

export function useInvalidateFeaturedFrames() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: buildFeaturedFramesKey(),
    });
  }, [queryClient]);
}
