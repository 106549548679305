import { AuthToken } from 'farcaster-client-data';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AuthedRoutes } from '~/components/routing/AuthedRoutes';
import { Redirect } from '~/components/routing/Redirect';
import { defaultRoute, redirects, routes } from '~/constants/routes';
import { authTokenKey } from '~/constants/storage';
import { DebugProvider } from '~/contexts/DebugProvider';
import { OnCurrentNavLinkClickedProvider } from '~/contexts/OnCurrentNavLinkClickedProvider';
import { useIsSignedIn } from '~/hooks/data/useIsSignedIn';
import { AuthedLayout } from '~/layouts/AuthedLayout';
import { UnauthedLayout } from '~/layouts/UnauthedLayout';
// eslint-disable-next-line no-restricted-imports
import { HomeFeedPage, HomeLandingPage } from '~/lazy/pages';
import { trackError } from '~/utils/errorUtils';
import { getItem } from '~/utils/storageUtils';

const Router: React.FC = () => {
  const isSignedIn = useIsSignedIn();

  const checkAuthTokenAndSignedInState = React.useCallback(async () => {
    const persistedAuthToken = await getItem<AuthToken | undefined>({
      key: authTokenKey,
      fallback: undefined,
    });

    if (typeof persistedAuthToken !== 'undefined' && !isSignedIn) {
      trackError('[Web] User deemed not signed in even though token exits');
    }
  }, [isSignedIn]);

  React.useEffect(() => {
    checkAuthTokenAndSignedInState();
  }, [checkAuthTokenAndSignedInState]);

  return (
    <BrowserRouter>
      {/* OnCurrentNavLinkClickedProvider must be a child of BrowserRouter */}
      <OnCurrentNavLinkClickedProvider>
        {/* DebugProvider must be a child of BrowserRouter */}
        <DebugProvider>
          <Routes>
            {redirects.map((redirect) => (
              <Route
                key={redirect.path}
                path={redirect.path}
                element={<Redirect url={redirect.url} />}
              />
            ))}
            <Route element={isSignedIn ? <AuthedLayout /> : <UnauthedLayout />}>
              {/* Authed Routes */}
              <Route element={<AuthedRoutes />}>
                <Route
                  path={routes.compose.path}
                  element={<routes.compose.Component />}
                />
                <Route
                  path={routes.following.path}
                  element={<routes.following.Component />}
                />
                <Route
                  path={routes.trending.path}
                  element={<routes.trending.Component />}
                />
                <Route
                  path={routes.notifications.path}
                  element={<routes.notifications.Component />}
                />
                <Route
                  path={routes.notificationsWithTabs.path}
                  element={<routes.notificationsWithTabs.Component />}
                />
                <Route
                  path={routes.notificationGroupUsers.path}
                  element={<routes.notificationGroupUsers.Component />}
                />
                <Route
                  path={routes.notificationGroupCasts.path}
                  element={<routes.notificationGroupCasts.Component />}
                />
                <Route
                  path={routes.notificationGroupChannelRoleInvites.path}
                  element={
                    <routes.notificationGroupChannelRoleInvites.Component />
                  }
                />
                <Route
                  path={routes.notificationGroupMiniApps.path}
                  element={<routes.notificationGroupMiniApps.Component />}
                />
                <Route
                  path={routes.warps.path}
                  element={<routes.warps.Component />}
                />
                <Route
                  path={routes.miniApps.path}
                  element={<routes.miniApps.Component />}
                />
                <Route
                  path={routes.bookmarks.path}
                  element={<routes.bookmarks.Component />}
                />
                <Route
                  path={routes.users.path}
                  element={<routes.users.Component />}
                />
                <Route
                  path={routes.settingsProfile.path}
                  element={<routes.settingsProfile.Component />}
                />
                <Route
                  path={routes.settingsNotifications.path}
                  element={<routes.settingsNotifications.Component />}
                />
                <Route
                  path={routes.settingsConnectedAddresses.path}
                  element={<routes.settingsConnectedAddresses.Component />}
                />
                <Route
                  path={routes.settingsDirectCasts.path}
                  element={<routes.settingsDirectCasts.Component />}
                />
                <Route
                  path={routes.settingsDirectCastsRecommended.path}
                  element={<routes.settingsDirectCastsRecommended.Component />}
                />
                <Route
                  path={routes.settingsDirectCastsOthers.path}
                  element={<routes.settingsDirectCastsOthers.Component />}
                />
                <Route
                  path={routes.settingsActions.path}
                  element={<routes.settingsActions.Component />}
                />
                <Route
                  path={routes.settingsFrames.path}
                  element={<routes.settingsFrames.Component />}
                />
                <Route
                  path={routes.settingsFeeds.path}
                  element={<routes.settingsFeeds.Component />}
                />
                <Route
                  path={routes.settingsAdvanced.path}
                  element={<routes.settingsAdvanced.Component />}
                />
                <Route
                  path={routes.settingsCastsAndUsers.path}
                  element={<routes.settingsCastsAndUsers.Component />}
                />
                <Route
                  path={routes.settingsMutedKeywords.path}
                  element={<routes.settingsMutedKeywords.Component />}
                />
                <Route
                  path={routes.settingsStarterPacks.path}
                  element={<routes.starterPacks.Component />}
                />
                <Route
                  path={routes.starterPacks.path}
                  element={<routes.starterPacks.Component />}
                />
                <Route
                  path={routes.suggestedStarterPacks.path}
                  element={<routes.suggestedStarterPacks.Component />}
                />
                <Route
                  path={routes.addCastAction.path}
                  element={<routes.addCastAction.Component />}
                />
                <Route
                  path={routes.discoverActions.path}
                  element={<routes.discoverActions.Component />}
                />
                <Route
                  path={routes.profileLikesWithoutUsername.path}
                  element={<routes.profileLikesWithoutUsername.Component />}
                />
                <Route
                  path={routes.profileLikesWithUsername.path}
                  element={<routes.profileLikesWithUsername.Component />}
                />
                <Route
                  path={routes.profileStarterPacksWithoutUsername.path}
                  element={
                    <routes.profileStarterPacksWithoutUsername.Component />
                  }
                />
                <Route
                  path={routes.profileStarterPacksWithUsername.path}
                  element={<routes.profileStarterPacksWithUsername.Component />}
                />
                <Route
                  path={routes.debugAdminToken.path}
                  element={<routes.debugAdminToken.Component />}
                />
                <Route
                  path={routes.adminReviews.path}
                  element={<routes.adminReviews.Component />}
                />
                <Route
                  path={routes.developers.path}
                  element={<routes.developers.Component />}
                />
                <Route
                  path={routes.miniAppsRewards.path}
                  element={<routes.miniAppsRewards.Component />}
                />
                <Route
                  path={routes.developersApiKeys.path}
                  element={<routes.developersApiKeys.Component />}
                />
                <Route
                  path={routes.developersEmbeds.path}
                  element={<routes.developersEmbeds.Component />}
                />
                <Route
                  path={routes.developersFrameValidator.path}
                  element={<routes.developersFrameValidator.Component />}
                />
                <Route
                  path={routes.developersNewApp.path}
                  element={<routes.developersNewApp.Component />}
                />
                <Route
                  path={routes.developersManageApp.path}
                  element={<routes.developersManageApp.Component />}
                />
                <Route
                  path={routes.developersDebugMiniApp.path}
                  element={<routes.developersDebugMiniApp.Component />}
                />
                <Route
                  path={routes.developersDebugMiniAppEmbed.path}
                  element={<routes.developersDebugMiniAppEmbed.Component />}
                />
                <Route
                  path={routes.developersCastActionPlayground.path}
                  element={<routes.developersCastActionPlayground.Component />}
                />
                <Route
                  path={routes.developersComposerActionPlayground.path}
                  element={
                    <routes.developersComposerActionPlayground.Component />
                  }
                />
                <Route
                  path={routes.channelSettingsSection.path}
                  element={
                    <routes.channelSettings.Component openSettings={true} />
                  }
                />
                <Route
                  path={routes.channelSettings.path}
                  element={
                    <routes.channelSettings.Component openSettings={true} />
                  }
                />
                <Route
                  path={routes.channelFollowersYouKnow.path}
                  element={<routes.channelFollowersYouKnow.Component />}
                />
                <Route
                  path={routes.globalFrameAnalytics.path}
                  element={<routes.globalFrameAnalytics.Component />}
                />
                <Route
                  path={routes.followersYouKnowWithUsername.path}
                  element={<routes.followersYouKnowWithUsername.Component />}
                />
                <Route
                  path={routes.followersYouKnowWithoutUsername.path}
                  element={<routes.followersYouKnowWithoutUsername.Component />}
                />
                <Route
                  path={routes.invites.path}
                  element={<routes.invites.Component />}
                />
                {/* Direct Casts utilize React Router's Outlet pattern to avoid full-page loads on each conversation press  */}
                <Route
                  path={routes.directCastsInbox.path}
                  element={<routes.directCastsInbox.Component />}
                >
                  <Route
                    path={routes.directCastsInbox.path}
                    element={<routes.directCastsConversation.Component />}
                  />
                  <Route
                    path={routes.directCastsConversation.path}
                    element={<routes.directCastsConversation.Component />}
                  />
                </Route>
                <Route
                  path={routes.directCastsCreate.path}
                  element={<routes.directCastsCreate.Component />}
                />
              </Route>
              <Route
                path={routes.wallet.path}
                element={<routes.wallet.Component />}
              />
              {/* Unauthed Routes */}
              <Route
                path={routes.starterPack.path}
                element={<routes.starterPack.Component />}
              />
              <Route
                path={routes.starterPackWithUsername.path}
                element={<routes.starterPackWithUsername.Component />}
              />
              <Route
                path={routes.token.path}
                element={<routes.token.Component />}
              />
              <Route path={routes.ca.path} element={<routes.ca.Component />} />
              <Route
                path={routes.settingsConnectedAccounts.path}
                element={<routes.settingsConnectedAccounts.Component />}
              />
              <Route
                path={routes.channel.path}
                element={<routes.channel.Component openSettings={false} />}
              />
              <Route
                path={routes.channelFeed.path}
                element={<routes.channelFeed.Component />}
              />
              <Route
                path={routes.channelFollowers.path}
                element={<routes.channelFollowers.Component />}
              />
              <Route
                path={routes.channelMembers.path}
                element={<routes.channelMembers.Component />}
              />
              <Route
                path={routes.conversationWithoutUsername.path}
                element={<routes.conversationWithoutUsername.Component />}
              />
              <Route
                path={routes.conversationReactionsWithoutUsername.path}
                element={
                  <routes.conversationReactionsWithoutUsername.Component />
                }
              />
              <Route
                path={routes.conversationRecastsWithoutUsername.path}
                element={
                  <routes.conversationRecastsWithoutUsername.Component />
                }
              />
              <Route
                path={routes.conversationQuotesWithoutUsername.path}
                element={<routes.conversationQuotesWithoutUsername.Component />}
              />
              <Route
                path={routes.followingWithoutUsername.path}
                element={<routes.followingWithoutUsername.Component />}
              />
              <Route
                path={routes.followersWithoutUsername.path}
                element={<routes.followersWithoutUsername.Component />}
              />
              <Route
                path={routes.profileCastsAndRepliesWithoutUsername.path}
                element={
                  <routes.profileCastsAndRepliesWithoutUsername.Component />
                }
              />
              <Route
                path={routes.profileCastsWithoutUsername.path}
                element={<routes.profileCastsWithoutUsername.Component />}
              />
              <Route
                path={routes.channels.path}
                element={<routes.channels.Component />}
              />
              <Route
                path={routes.manageChannelsCategory.path}
                element={<routes.manageChannelsCategory.Component />}
              />
              <Route
                path={routes.app.path}
                element={<routes.app.Component />}
              />
              <Route
                path={routes.apps.path}
                element={<routes.apps.Component />}
              />
              <Route
                path={routes.top.path}
                element={<routes.top.Component />}
              />
              <Route
                path={routes.recent.path}
                element={<routes.recent.Component />}
              />
              <Route
                path={routes.searchChannels.path}
                element={<routes.searchChannels.Component />}
              />
              <Route
                path={routes.searchUsers.path}
                element={<routes.searchUsers.Component />}
              />
              <Route
                path={routes.locationUsers.path}
                element={<routes.locationUsers.Component />}
              />
              <Route
                path={routes.debug.path}
                element={<routes.debug.Component />}
              />
              <Route
                path={routes.debugCasts.path}
                element={<routes.debugCasts.Component />}
              />
              <Route
                path={routes.recoveryStart.path}
                element={<routes.recoveryStart.Component />}
              />
              <Route
                path={routes.recoveryInitiate.path}
                element={<routes.recoveryInitiate.Component />}
              />
              <Route
                path={routes.recovery.path}
                element={<routes.recovery.Component />}
              />
              <Route
                path={routes.magicLink.path}
                element={<routes.magicLink.Component />}
              />
              {/*
              Vanity Routes
              These routes must be defined at the bottom of the `Routes` component
              to prevent React Router from prematurely matching a vanity url
              where the leading symbol (e.g. `~`) is the username and
              the path segment (e.g. `notifications`) is the `castHashPrefix` (for example).
              We must also adhere to this ordering in `routes` to accommodate vanity routes, unfortunately.

            */}
              <Route
                path={routes.conversationWithUsername.path}
                element={<routes.conversationWithUsername.Component />}
              />
              <Route
                path={routes.conversationReactionsWithUsername.path}
                element={<routes.conversationReactionsWithUsername.Component />}
              />
              <Route
                path={routes.conversationRecastsWithUsername.path}
                element={<routes.conversationRecastsWithUsername.Component />}
              />
              <Route
                path={routes.conversationQuotesWithUsername.path}
                element={<routes.conversationQuotesWithUsername.Component />}
              />
              <Route
                path={routes.followingWithUsername.path}
                element={<routes.followingWithUsername.Component />}
              />
              <Route
                path={routes.followersWithUsername.path}
                element={<routes.followersWithUsername.Component />}
              />
              <Route
                path={routes.profileCastsWithUsername.path}
                element={<routes.profileCastsWithUsername.Component />}
              />
              <Route
                path={routes.profileCastsAndRepliesWithUsername.path}
                element={
                  <routes.profileCastsAndRepliesWithUsername.Component />
                }
              />
            </Route>
            {/* Authed routes without scaffolding */}
            <Route element={<AuthedRoutes />}>
              <Route
                path={routes.adminFeedsComparison.path}
                element={<routes.adminFeedsComparison.Component />}
              />
            </Route>
            {/* Un-authed routes without scaffolding */}
            <Route element={isSignedIn ? <AuthedLayout /> : null}>
              <Route
                path={routes.homeFeed.path}
                element={
                  isSignedIn ? (
                    <routes.homeFeed.Component />
                  ) : (
                    <HomeLandingPage />
                  )
                }
              />
            </Route>
            <Route
              path={routes.download.path}
              element={<routes.download.Component />}
            />
            <Route
              path={routes.signup.path}
              element={<routes.signup.Component />}
            />
            <Route
              path={routes.directCastsInvite.path}
              element={<routes.directCastsInvite.Component />}
            />
            <Route
              path={routes.channelJoinViaCode.path}
              element={<routes.channelJoinViaCode.Component />}
            />
            <Route
              path={routes.mint.path}
              element={<routes.mint.Component />}
            />
            <Route
              path={routes.launchMiniApp.path}
              element={<routes.launchMiniApp.Component />}
            />
            <Route
              path={routes.launchComposerAction.path}
              element={<routes.launchComposerAction.Component />}
            />
            <Route
              path={routes.signInWithFarcaster.path}
              element={<routes.signInWithFarcaster.Component />}
            />
            <Route
              path={routes.signInWithFarcasterV2.path}
              element={<routes.signInWithFarcasterV2.Component />}
            />
            <Route
              path={routes.support.path}
              element={<routes.support.Component />}
            />
            <Route
              path={routes.termsOfUse.path}
              element={<routes.termsOfUse.Component />}
            />
            <Route
              path={routes.privacyPolicy.path}
              element={<routes.privacyPolicy.Component />}
            />
            <Route
              path={routes.catchAll.path}
              element={<Navigate replace to={defaultRoute.path} />}
            />
            {/* FIXME: This is a bit odd but what we are trying here is to redirect user to home feed
              if they are already signed up. Our route structure is built to handle the reverse
              of this already (redirects fallback to un-authed home feed). Lets consider building
              a similar mechanism of fallback for the logged in users so we don't have to do this
              dance for all routes. */}
            <Route element={isSignedIn ? <AuthedLayout /> : null}>
              <Route
                path={routes.signupForInvite.path}
                element={
                  isSignedIn ? (
                    <HomeFeedPage />
                  ) : (
                    <routes.signupForInvite.Component />
                  )
                }
              />
              <Route
                path={routes.cloudflareChallenge.path}
                element={
                  isSignedIn ? (
                    <HomeFeedPage />
                  ) : (
                    <routes.cloudflareChallenge.Component />
                  )
                }
              />
              <Route
                path={routes.paidInvite.path}
                element={
                  isSignedIn ? (
                    <HomeFeedPage />
                  ) : (
                    <routes.paidInvite.Component />
                  )
                }
              />
            </Route>
          </Routes>
        </DebugProvider>
      </OnCurrentNavLinkClickedProvider>
    </BrowserRouter>
  );
};

Router.displayName = 'Router';

export { Router };
