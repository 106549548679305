import { PersonFillIcon, SearchIcon } from '@primer/octicons-react';
import cn from 'classnames';
import { ApiChannel, ApiUser } from 'farcaster-client-data';
import {
  channelKeyExtractor,
  formatShorthandNumber,
  SharedAmpEvent,
  userKeyExtractor,
  useSearchSummary,
} from 'farcaster-client-hooks';
import {
  FC,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';

import { NFT_IMAGE_UNAVAILABLE_URL } from '~/components/collections/CollectionNameWithImage';
import { Form } from '~/components/forms/Form';
import { SearchInput } from '~/components/forms/SearchInput';
import { Image } from '~/components/images/Image';
import { DefaultEmptyListView } from '~/components/lists/DefaultEmptyListView';
import { FlatList } from '~/components/lists/FlatList';
import { LoadingIndicator } from '~/components/loaders/LoadingIndicator';
import { User } from '~/components/users/User';
import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { useNavigate } from '~/hooks/navigation/useNavigate';
import { useNavigateToChannel } from '~/hooks/navigation/useNavigateToChannel';
import { useNavigateToProfile } from '~/hooks/navigation/useNavigateToProfile';
import { useSelectInputKeyboardShortcuts } from '~/hooks/useSelectInputKeyboardShortcuts';
import { SearchTab } from '~/types';
import { applyCloudflarePath } from '~/utils/images';

type SearchProps = {
  className?: string;
  query?: string;
  focusedTab?: SearchTab;
  showFilterIcon?: boolean;
  showClearIcon?: boolean;
};

const Search: FC<SearchProps> = memo(
  ({
    className,
    query = '',
    focusedTab = 'top',
    showFilterIcon = false,
    showClearIcon = true,
  }) => {
    const { trackEvent } = useAnalytics();
    const location = useLocation();
    const navigate = useNavigate();

    const navigateToProfile = useNavigateToProfile();
    const navigateToChannel = useNavigateToChannel();
    const [q, setQ] = useState(query);
    const [isFocused, setIsFocused] = useState(false);

    const trimmedQ = useMemo(() => q.trim(), [q]);

    // What is focused via keyboard navigation
    const [focusedKeyword, setFocusedKeyword] = useState(false);
    const [focusedUser, setFocusedUser] = useState<ApiUser | undefined>(
      undefined,
    );
    const [focusedUsersMoreLink, setFocusedUsersMoreLink] = useState(false);
    const [focusedChannel, setFocusedChannel] = useState<
      ApiChannel | undefined
    >(undefined);
    const [focusedChannelsMoreLink, setFocusedChannelsMoreLink] =
      useState(false);

    // Blur in order to close dropdown when navigating
    useEffect(() => {
      inputRef.current?.blur();
    }, [location]);

    const inputRef = useRef<HTMLInputElement | null>(null);
    useHotkeys('mod+k', () => {
      inputRef.current?.focus();
    });

    const { data } = useSearchSummary({ q, maxChannels: 2, maxUsers: 4 });

    const { channels, hasMoreChannels, users, hasMoreUsers } = useMemo(() => {
      if (data?.result) {
        return {
          channels: data.result.channels,
          hasMoreChannels: data.result.hasMoreChannels,
          users: data.result.users,
          hasMoreUsers: data.result.hasMoreUsers,
        };
      } else {
        return {
          channels: undefined,
          hasMoreChannels: false,
          users: undefined,
          hasMoreUsers: false,
        };
      }
    }, [data]);

    const { channelsStartIndex, usersStartIndex, numListItems } =
      useMemo(() => {
        const keywordCount = trimmedQ ? 1 : 0;
        return {
          channelsStartIndex: keywordCount,
          usersStartIndex:
            keywordCount + (channels?.length || 0) + (hasMoreChannels ? 1 : 0),
          // The number of total items in the list -> need it for keyboard nav
          numListItems:
            keywordCount +
            (channels?.length || 0) +
            (hasMoreChannels ? 1 : 0) +
            (users?.length || 0) +
            (hasMoreUsers ? 1 : 0),
        };
      }, [channels, hasMoreChannels, hasMoreUsers, trimmedQ, users]);

    const goToTopSearch = useCallback(
      (query: string) => {
        trackEvent(SharedAmpEvent.ClickSearchResult, { type: 'top-search' });
        navigate({
          to: 'top',
          searchParams: { q: query },
          params: {},
        });
      },
      [navigate, trackEvent],
    );

    const goToRecentSearch = useCallback(
      (query: string) => {
        trackEvent(SharedAmpEvent.ClickSearchResult, { type: 'recent-search' });
        navigate({
          to: 'recent',
          searchParams: { q: query },
          params: {},
        });
      },
      [navigate, trackEvent],
    );

    const goToChannelsSearch = useCallback(
      (query: string) => {
        trackEvent(SharedAmpEvent.ClickSearchResult, {
          type: 'channels-search',
        });
        navigate({
          to: 'searchChannels',
          searchParams: { q: query },
          params: {},
        });
      },
      [navigate, trackEvent],
    );

    const goToUsersSearch = useCallback(
      (query: string) => {
        trackEvent(SharedAmpEvent.ClickSearchResult, { type: 'users-search' });
        navigate({
          to: 'searchUsers',
          searchParams: { q: query },
          params: {},
        });
      },
      [navigate, trackEvent],
    );

    const goToChannel = useCallback(
      (channel: ApiChannel) => {
        trackEvent(SharedAmpEvent.ClickSearchResult, { type: 'channel' });
        setQ('');
        navigateToChannel({ channelKey: channel.key });
      },
      [navigateToChannel, trackEvent],
    );

    const goToUser = useCallback(
      (user: ApiUser) => {
        trackEvent(SharedAmpEvent.ClickSearchResult, { type: 'user' });
        setQ('');
        navigateToProfile({ user });
      },
      [navigateToProfile, trackEvent],
    );

    const { focusedIndex, onKeyDown } = useSelectInputKeyboardShortcuts({
      // Need undefined in case of 0 items in order to reset the focused index after navigating
      data: numListItems ? [...Array(numListItems).keys()] : undefined,
      onEnter: useCallback(
        ({ event }: { event: KeyboardEvent }) => {
          event.preventDefault(); // We want to short-circuit form submission, so we don't navigate to the search results page
          if (focusedChannel) {
            goToChannel(focusedChannel);
          } else if (focusedChannelsMoreLink) {
            goToChannelsSearch(trimmedQ);
          } else if (focusedUser) {
            goToUser(focusedUser);
          } else if (focusedUsersMoreLink) {
            goToUsersSearch(trimmedQ);
          } else {
            // Keyword focused or nothing focused
            if (focusedTab === 'top') {
              goToTopSearch(trimmedQ);
            } else if (focusedTab === 'recent') {
              goToRecentSearch(trimmedQ);
            } else if (focusedTab === 'channels') {
              goToChannelsSearch(trimmedQ);
            } else if (focusedTab === 'users') {
              goToUsersSearch(trimmedQ);
            } else {
              goToTopSearch(trimmedQ);
            }
          }
        },
        [
          focusedChannel,
          focusedChannelsMoreLink,
          focusedUser,
          focusedUsersMoreLink,
          goToChannel,
          goToChannelsSearch,
          trimmedQ,
          goToUser,
          goToUsersSearch,
          focusedTab,
          goToTopSearch,
          goToRecentSearch,
        ],
      ),
    });

    // Determine what is focused bsed on keyboard navigation
    // Doing it here in a single function, otherwise if we just pass focusedIndex to the child
    // both the child will need to do this to determine highlighting and useSelectInputKeyboardShortcuts
    // above will need to do it again for onEnter
    useEffect(() => {
      let focusUsersMoreLink: boolean = false;
      let focusUser: ApiUser | undefined = undefined;
      let focusChannelsMoreLink: boolean = false;
      let focusChannel: ApiChannel | undefined = undefined;
      let focusKeyword: boolean = false;

      if (
        users &&
        hasMoreUsers &&
        focusedIndex === usersStartIndex + users.length
      ) {
        focusUsersMoreLink = true;
      } else if (users && focusedIndex >= usersStartIndex) {
        focusUser = users[focusedIndex - usersStartIndex];
      } else if (
        channels &&
        hasMoreChannels &&
        focusedIndex === channelsStartIndex + channels.length
      ) {
        focusChannelsMoreLink = true;
      } else if (channels && focusedIndex >= channelsStartIndex) {
        focusChannel = channels[focusedIndex - channelsStartIndex];
      } else if (focusedIndex === 0) {
        focusKeyword = true;
      }

      setFocusedUsersMoreLink(focusUsersMoreLink);
      setFocusedUser(focusUser);
      setFocusedChannelsMoreLink(focusChannelsMoreLink);
      setFocusedChannel(focusChannel);
      setFocusedKeyword(focusKeyword);
    }, [
      focusedIndex,
      users,
      usersStartIndex,
      channelsStartIndex,
      channels,
      hasMoreUsers,
      hasMoreChannels,
    ]);

    return (
      <div className="w-full">
        <Form
          className={cn('', className)}
          onSubmit={(e) => {
            e.preventDefault();
            if (trimmedQ) {
              if (focusedTab === 'top') {
                goToTopSearch(trimmedQ);
              } else if (focusedTab === 'recent') {
                goToRecentSearch(trimmedQ);
              } else if (focusedTab === 'channels') {
                goToChannelsSearch(trimmedQ);
              } else if (focusedTab === 'users') {
                goToUsersSearch(trimmedQ);
              } else {
                goToTopSearch(trimmedQ);
              }
            }
          }}
        >
          <SearchInput
            className={cn('!rounded-md border border-default')}
            ref={inputRef}
            value={q}
            maxLength={64}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              setQ(e.target.value);
            }}
            onClear={() => {
              setQ('');
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            showModSelectorHint={true}
            showFilterIcon={showFilterIcon}
            showClearIcon={showClearIcon}
          />
          <div className="relative bg-app">
            {trimmedQ && isFocused && (
              <SearchResults
                query={trimmedQ}
                channels={channels}
                hasMoreChannels={hasMoreChannels}
                users={users}
                hasMoreUsers={hasMoreUsers}
                focusedKeyword={focusedKeyword}
                focusedChannel={focusedChannel}
                focusedChannelsMoreLink={focusedChannelsMoreLink}
                focusedUser={focusedUser}
                focusedUsersMoreLink={focusedUsersMoreLink}
                goToTopSearch={goToTopSearch}
                goToChannelsSearch={goToChannelsSearch}
                goToUsersSearch={goToUsersSearch}
                goToChannel={goToChannel}
                goToUser={goToUser}
                focusedTab={focusedTab}
                goToRecentSearch={goToRecentSearch}
              />
            )}
          </div>
        </Form>
      </div>
    );
  },
);

Search.displayName = 'Search';

type SearchResultsProps = {
  query: string;
  channels: ApiChannel[] | undefined;
  hasMoreChannels: boolean;
  users: ApiUser[] | undefined;
  focusedTab: SearchTab | undefined;
  hasMoreUsers: boolean;
  focusedKeyword: boolean;
  focusedChannel: ApiChannel | undefined;
  focusedChannelsMoreLink: boolean;
  focusedUser: ApiUser | undefined;
  focusedUsersMoreLink: boolean;
  goToTopSearch: (q: string) => void;
  goToChannelsSearch: (q: string) => void;
  goToUsersSearch: (q: string) => void;
  goToChannel: (channel: ApiChannel) => void;
  goToUser: (user: ApiUser) => void;
  goToRecentSearch: (q: string) => void;
};

const SearchResults: FC<SearchResultsProps> = memo(
  ({
    query,
    channels,
    hasMoreChannels,
    users,
    hasMoreUsers,
    focusedKeyword,
    focusedChannel,
    focusedChannelsMoreLink,
    focusedUser,
    focusedUsersMoreLink,
    goToTopSearch,
    goToChannelsSearch,
    goToUsersSearch,
    goToChannel,
    goToUser,
    focusedTab,
    goToRecentSearch,
  }) => {
    const renderChannel = useCallback(
      ({ item }: { item: ApiChannel }) => {
        return (
          <div
            className={cn(
              'flex w-full cursor-pointer flex-row items-center p-2 hover:bg-overlay-medium',
              item.key === focusedChannel?.key && 'bg-overlay-medium',
            )}
            onClick={(e) => {
              e.preventDefault();
              goToChannel(item);
            }}
          >
            <Image
              src={
                applyCloudflarePath(item.imageUrl, 48) ||
                NFT_IMAGE_UNAVAILABLE_URL
              }
              className="aspect-square h-[48px] w-[48px] shrink-0 rounded-full border object-cover border-default"
              alt={`${item.name} image`}
              fallback={NFT_IMAGE_UNAVAILABLE_URL}
            />
            <div className="mx-2 flex shrink flex-col overflow-hidden">
              <div className="truncate break-words text-base font-semibold text-default">
                {'/' + item.key}
              </div>
              {item.followerCount !== undefined && (
                <div className="flex flex-row items-center space-x-1">
                  <PersonFillIcon size={12} className="text-faint" />
                  <div className="text-sm text-faint">
                    {formatShorthandNumber(item.followerCount)} followers
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      },
      [focusedChannel, goToChannel],
    );

    const renderUser = useCallback(
      ({ item }: { item: ApiUser }) => {
        return (
          <User
            user={item}
            compact
            hideFollowButton
            showFollowing
            className={cn(
              'cursor-pointer border-b-0 hover:bg-overlay-medium',
              item.fid === focusedUser?.fid && 'bg-overlay-medium',
            )}
            onClick={(e) => {
              e.preventDefault();
              goToUser(item);
            }}
          />
        );
      },
      [focusedUser, goToUser],
    );

    return (
      <div
        className="scrollbar-hide absolute z-10 mt-2 max-h-[680px] w-full overflow-y-auto rounded-lg border bg-app border-default"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <div className={cn(focusedKeyword && 'bg-overlay-medium')}>
          <div
            onClick={() => {
              if (focusedTab === 'top') {
                goToTopSearch(query);
              } else if (focusedTab === 'recent') {
                goToRecentSearch(query);
              } else if (focusedTab === 'channels') {
                goToChannelsSearch(query);
              } else if (focusedTab === 'users') {
                goToUsersSearch(query);
              } else {
                goToTopSearch(query);
              }
            }}
            className="relative flex cursor-pointer flex-row items-center justify-between px-2 py-4 hover:bg-overlay-medium"
          >
            <div className="flex flex-row items-center">
              <SearchIcon
                className="mr-2 w-[48px]"
                verticalAlign="middle"
                size={20}
              />
              <span className="max-w-[250px] truncate font-semibold">
                {query}
              </span>
            </div>
          </div>
        </div>
        {!users && !channels && (
          <div className="flex w-full items-center justify-center p-4">
            <LoadingIndicator />
          </div>
        )}
        {users && users.length ? (
          <div className="border-t border-faint">
            <div className="p-2 text-xs">Users</div>
            <FlatList
              data={users}
              renderItem={renderUser}
              keyExtractor={userKeyExtractor}
              emptyView={
                <DefaultEmptyListView
                  className="mt-1 rounded-lg border text-sm border-default"
                  message="No users match your query"
                />
              }
            />
            {hasMoreUsers && (
              <div
                onClick={() => goToUsersSearch(query)}
                className={cn(
                  'cursor-pointer p-3 text-xs text-faint hover:bg-overlay-medium',
                  focusedUsersMoreLink && 'bg-overlay-medium',
                )}
              >
                Show more users
              </div>
            )}
          </div>
        ) : null}
        {channels && channels.length ? (
          <div
            className={cn(
              'border-t border-faint',
              !hasMoreChannels && users ? 'pb-1' : '',
            )}
          >
            <div className="p-2 text-xs">Channels</div>
            <FlatList
              data={channels}
              renderItem={renderChannel}
              keyExtractor={channelKeyExtractor}
              emptyView={
                <DefaultEmptyListView
                  className="mt-1 rounded-lg border text-sm border-default"
                  message="No channels found"
                />
              }
            />
            {hasMoreChannels && (
              <div
                onClick={() => goToChannelsSearch(query)}
                className={cn(
                  'cursor-pointer p-3 text-xs text-faint hover:bg-overlay-medium',
                  focusedChannelsMoreLink && 'bg-overlay-medium',
                )}
              >
                Show more channels
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  },
);

SearchResults.displayName = 'SearchResults';

export { Search };
