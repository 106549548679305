import { ApiDefaultFeedPreference } from 'farcaster-client-data';
import { useHighlightedChannels } from 'farcaster-client-hooks';
import React from 'react';

type HomeLastSelectedTabContextType = {
  defaultFeed: ApiDefaultFeedPreference;
  feedKey: string;
  setFeedKey: ({ feedKey }: { feedKey: string }) => void;
};

const HomeLastSelectedTabContext =
  React.createContext<HomeLastSelectedTabContextType>({} as never);

export function HomeLastSelectedTabContextProvider({
  children,
}: React.PropsWithChildren) {
  const { data } = useHighlightedChannels();

  const defaultFeed = data.viewerContext.defaultFeed;

  const [feedKey, setFeedKey] = React.useState<string>(defaultFeed);

  const setFeedKeyForContext = React.useCallback(
    ({ feedKey: feedKeyToSet }: { feedKey: string }) => {
      if (feedKey !== feedKeyToSet) {
        setFeedKey(feedKeyToSet);
      }
    },
    [feedKey],
  );

  const value = React.useMemo(
    () => ({
      defaultFeed,
      feedKey,
      setFeedKey: setFeedKeyForContext,
    }),
    [defaultFeed, feedKey, setFeedKeyForContext],
  );

  return (
    <HomeLastSelectedTabContext.Provider value={value}>
      {children}
    </HomeLastSelectedTabContext.Provider>
  );
}

export function useHomeLastSelectedTab() {
  return React.useContext(HomeLastSelectedTabContext);
}
