import { useQueryClient } from '@tanstack/react-query';
import { ApiUserMinimal } from 'farcaster-client-data';
import { useCallback } from 'react';

import { useTrackEvent } from '../../../providers';
import { useFarcasterApiClient } from '../../../providers/FarcasterApiClientProvider';
import { frameKeyExtractor, SharedAmpEvent } from '../../../utils';
import { frameAnalyticsProperties } from '../../generic/useFrameAnalyticsProperties';
import { removeItemFromPaginatedResultCaches } from '../helpers';
import {
  buildFavoriteFramesKey,
  useInvalidateFavoriteFrames,
  useInvalidateFeaturedFrames,
  useInvalidateFrameDetails,
  useOptimisticallyUpdateFrame,
} from '../queries';

export function useRemoveFavoriteFrame() {
  const { apiClient } = useFarcasterApiClient();
  const queryClient = useQueryClient();
  const { trackEvent } = useTrackEvent();

  const invalidateFavoriteFrames = useInvalidateFavoriteFrames();
  const invalidateFeaturedFrames = useInvalidateFeaturedFrames();
  const invalidateFrameDetails = useInvalidateFrameDetails();
  const optimisticallyUpdateFrame = useOptimisticallyUpdateFrame();

  return useCallback(
    async ({
      domain,
      url,
      name,
      author,
    }: {
      domain: string;
      url: string;
      name: string;
      author?: ApiUserMinimal;
    }) => {
      const revertOptimisticUpdate = optimisticallyUpdateFrame({
        domain,
        viewerContext: {
          favorited: false,
          notificationsEnabled: false,
          notificationDetails: undefined,
        },
      });

      removeItemFromPaginatedResultCaches({
        queryClient,
        queryKey: buildFavoriteFramesKey(),
        keyExtractor: frameKeyExtractor,
        removeKey: domain,
      });

      try {
        const result = await apiClient.removeFavoriteFrame({ domain });

        trackEvent(
          SharedAmpEvent.RemoveFavoriteFrame,
          frameAnalyticsProperties({
            frameUrl: url,
            frameName: name,
            author,
          }),
        );

        return result.data.result;
      } catch (e: unknown) {
        revertOptimisticUpdate();
        invalidateFeaturedFrames();
        invalidateFavoriteFrames();
        invalidateFrameDetails({ domain });
        throw e;
      }
    },
    [
      apiClient,
      invalidateFavoriteFrames,
      invalidateFeaturedFrames,
      invalidateFrameDetails,
      optimisticallyUpdateFrame,
      queryClient,
      trackEvent,
    ],
  );
}
