import React from 'react';

export function StarterPackIcon({
  size,
  className,
}: {
  size: number;
  className: string;
}) {
  return (
    <svg width={size} height={size} viewBox={`0 0 20 21`} fill="none">
      <path
        d="M10 18.8333V11.3333"
        className={className}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6417 2.34167C12.8492 2.22562 13.0831 2.16469 13.3208 2.16469C13.5586 2.16469 13.7925 2.22562 14 2.34167L17.5 4.30833C17.7479 4.4485 17.9541 4.65197 18.0976 4.89794C18.2411 5.14391 18.3167 5.42357 18.3167 5.70833C18.3167 5.9931 18.2411 6.27275 18.0976 6.51873C17.9541 6.7647 17.7479 6.96816 17.5 7.10833L7.35002 12.825C7.14185 12.9437 6.90633 13.0062 6.66668 13.0062C6.42703 13.0062 6.19152 12.9437 5.98335 12.825L2.50002 10.8583C2.25214 10.7182 2.04592 10.5147 1.90244 10.2687C1.75895 10.0228 1.68335 9.7431 1.68335 9.45833C1.68335 9.17357 1.75895 8.89391 1.90244 8.64794C2.04592 8.40197 2.25214 8.1985 2.50002 8.05833L12.6417 2.34167Z"
        className={className}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6666 11.3333V14.5583C16.6669 14.8729 16.5808 15.1815 16.4177 15.4504C16.2546 15.7193 16.0207 15.9383 15.7416 16.0833L10.7416 18.65C10.5125 18.7691 10.2581 18.8312 9.99992 18.8312C9.74174 18.8312 9.48735 18.7691 9.25825 18.65L4.25825 16.0833C3.97916 15.9383 3.74528 15.7193 3.58215 15.4504C3.41903 15.1815 3.33293 14.8729 3.33325 14.5583V11.3333"
        className={className}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 10.8583C17.7479 10.7182 17.9541 10.5147 18.0976 10.2687C18.2411 10.0228 18.3167 9.7431 18.3167 9.45834C18.3167 9.17357 18.2411 8.89392 18.0976 8.64794C17.9541 8.40197 17.7479 8.1985 17.5 8.05834L7.35835 2.33334C7.15158 2.21493 6.91745 2.15263 6.67918 2.15263C6.44091 2.15263 6.20678 2.21493 6.00002 2.33334L2.50002 4.30834C2.25214 4.4485 2.04592 4.65197 1.90244 4.89794C1.75895 5.14392 1.68335 5.42357 1.68335 5.70834C1.68335 5.9931 1.75895 6.27276 1.90244 6.51873C2.04592 6.7647 2.25214 6.96817 2.50002 7.10834L12.65 12.825C12.8567 12.9437 13.0908 13.0062 13.3292 13.0062C13.5675 13.0062 13.8017 12.9437 14.0083 12.825L17.5 10.8583Z"
        className={className}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
