import {
  ApiActiveUserBoost,
  ApiUserAppContextNotificationTab,
  ApiUserCastAction,
} from 'farcaster-client-data';
import {
  // eslint-disable-next-line no-restricted-imports
  useNonSuspenseUserAppContext as useNonSuspenseUserAppContextQuery,
  useNonSuspenseUserPreferences,
} from 'farcaster-client-hooks';
import React, { createContext, useContext, useMemo } from 'react';

type UserAppContextValue = {
  disableFollow: boolean;
  adminForChannelKeys: Set<string>;
  modOfChannelKeys: Set<string>;
  canEditAllChannels: boolean;
  canUploadVideo: boolean;
  statsigEnabled: boolean;
  promptToAckFrameTxRisks: boolean;
  notificationTabs?: ApiUserAppContextNotificationTab[];
  castActions: ApiUserCastAction[];
  canAddCastAction: boolean;
  regularCastByteLimit: number;
  longCastByteLimit: number;
  optedOutChannelStreaks: boolean;
  userBoost: ApiActiveUserBoost | undefined;
  higherClientEventSamplingRateEnabled: boolean;
  trendingNotAvailable: boolean;
  emailLoginDisabled: boolean;
};

const UNAUTHED_VISITS_CAST_BYTE_LIMIT_FALLBACK = 320;

const defaultContextValue: UserAppContextValue = {
  disableFollow: false,
  adminForChannelKeys: new Set([]),
  modOfChannelKeys: new Set([]),
  canEditAllChannels: false,
  canUploadVideo: false,
  statsigEnabled: false,
  promptToAckFrameTxRisks: false,
  castActions: [],
  canAddCastAction: true,
  regularCastByteLimit: UNAUTHED_VISITS_CAST_BYTE_LIMIT_FALLBACK,
  longCastByteLimit: UNAUTHED_VISITS_CAST_BYTE_LIMIT_FALLBACK,
  optedOutChannelStreaks: false,
  userBoost: undefined,
  higherClientEventSamplingRateEnabled: false,
  trendingNotAvailable: false,
  emailLoginDisabled: false,
};

const Context = createContext<UserAppContextValue>(defaultContextValue);

interface UserAppContextProviderProps {
  children: React.ReactNode;
}

/**
 * Provides context about the current user of the application.
 */
export function UserAppContextProvider({
  children,
}: UserAppContextProviderProps) {
  const { data } = useNonSuspenseUserAppContextQuery({});

  // Don't block the UI from loading.
  const { data: userPreferencesData } = useNonSuspenseUserPreferences();

  const value = useMemo(() => {
    if (data) {
      return {
        disableFollow: !data.canAddLinks,
        adminForChannelKeys: new Set(data.adminForChannelKeys),
        modOfChannelKeys: new Set(data.modOfChannelKeys),
        canEditAllChannels: data.canEditAllChannels || false,
        canUploadVideo: data.canUploadVideo || false,
        statsigEnabled: data.statsigEnabled || false,
        promptToAckFrameTxRisks:
          !userPreferencesData?.result.preferences.ackFrameTransactionRisks ??
          false,
        notificationTabs: data.notificationTabsV2,
        castActions: data.castActions,
        canAddCastAction: data.canAddCastAction,
        regularCastByteLimit:
          data.regularCastByteLimit || UNAUTHED_VISITS_CAST_BYTE_LIMIT_FALLBACK,
        longCastByteLimit:
          data.longCastByteLimit || UNAUTHED_VISITS_CAST_BYTE_LIMIT_FALLBACK,
        optedOutChannelStreaks:
          userPreferencesData?.result.preferences.optOutChannelStreaks || false,
        userBoost: data.userBoost,
        higherClientEventSamplingRateEnabled:
          data.higherClientEventSamplingRateEnabled || false,
        trendingNotAvailable: data.trendingNotAvailable || false,
        emailLoginDisabled: data.emailLoginDisabled || false,
      } satisfies UserAppContextValue;
    } else {
      return defaultContextValue;
    }
  }, [
    data,
    userPreferencesData?.result.preferences.ackFrameTransactionRisks,
    userPreferencesData?.result.preferences.optOutChannelStreaks,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useUserAppContext = () => useContext(Context);
