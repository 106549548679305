import { ApiUserMinimal } from 'farcaster-client-data';
import { useMemo } from 'react';

import { resolveUsernameShort } from '../../utils';

export type FrameAnalyticsProperties = {
  frameUrl: string;
  frameName: string;
  frameNameExt: string;
  frameDomain: string;
  authorFid?: number;
  authorUsername?: string;
};

export const frameAnalyticsProperties = ({
  frameUrl,
  frameName,
  author,
}: {
  frameUrl: string;
  frameName: string;
  author?: ApiUserMinimal;
}) => {
  const frameDomain = new URL(frameUrl).hostname;
  const authorUsername = author ? resolveUsernameShort(author) : undefined;

  return {
    frameName,
    frameNameExt: author ? `${frameName} by ${authorUsername}` : frameDomain,
    frameUrl,
    frameDomain,
    authorFid: author?.fid,
    authorUsername,
  };
};

export const useFrameAnalytcsProperties = ({
  frameUrl,
  frameName,
  author,
}: {
  frameUrl: string;
  frameName: string;
  author?: ApiUserMinimal;
}): FrameAnalyticsProperties => {
  return useMemo<FrameAnalyticsProperties>(
    () => frameAnalyticsProperties({ frameUrl, frameName, author }),
    [author, frameName, frameUrl],
  );
};
