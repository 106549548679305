import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import mergeWith from 'lodash/mergeWith';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { stringifyGlobalCacheUsageKey } from '../providers/GlobalCacheUsageProvider';
import { DeepPartial } from './TypeUtils';
const shouldUpdateCache = <T extends object>({
  cache,
  updates,
}: {
  cache: T | undefined;
  updates: DeepPartial<T>;
}): boolean => {
  if (!cache) {
    return true;
  }

  // Since we iterate below based on keys of updates, in case updates is an
  // array and cache is missing or is a larger array, we want to update it for sure
  if (
    Array.isArray(updates) &&
    (!Array.isArray(cache) ||
      Object.keys(cache).length > Object.keys(updates).length)
  ) {
    return true;
  }

  for (const key in updates) {
    const cachedValue: unknown = cache[key as unknown as keyof T];
    const updateValue: unknown = updates[key];

    if (typeof cachedValue === 'object' && typeof updateValue === 'object') {
      if (
        shouldUpdateCache({
          cache: cachedValue as object,
          updates: updateValue as object,
        })
      ) {
        return true;
      }
    } else if (
      typeof updateValue === 'string' &&
      typeof cachedValue === 'string'
    ) {
      // For strings we want to do a case-insensitive comparison,
      // because different endpoints may respond with different casing
      // for fids, hashes, etc.
      if (updateValue.toLowerCase() !== cachedValue.toLowerCase()) {
        return true;
      }
    } else {
      if (cachedValue !== updateValue) {
        return true;
      }
    }
  }

  return false;
};

// Override merge() to always replcae arrays in full. We don't ever expect our API to submit a partial update
// to an array (vs we do expect partial updates to objects). Merging arrays causes two problems:
// - When the current user is the last recaster and they delete their recast, the new recasters array is
//   just an element shorter. merge() keeps the original array (since it's a superset) which is
//   wrong.
// - When a React element is updated with a new cast and the updates are merged in the global cache,
//   React will update the single element tags array in-place, which will not retrigger rendering of
//   objects which have the overall array object as a dependency
function mergeExceptArrays<CachedType, UpdatesType>(
  cache: CachedType,
  updates: UpdatesType,
): CachedType & UpdatesType {
  return mergeWith(cache, updates, (_prevValue, newValue) => {
    if (Array.isArray(newValue)) {
      return newValue;
    }
  });
}

// 3-way mergeExceptArrays(), including a base object, so that the caller can manage what React
// considers as updated
const mergeWithBaseExceptArrays = <CachedType, UpdatesType>({
  base,
  cache,
  updates,
}: {
  base: CachedType | Record<string, never>;
  cache: CachedType | undefined;
  updates: UpdatesType | undefined;
}) => {
  return mergeExceptArrays(mergeExceptArrays(base, cache), updates);
};

//
// An attempt at generic hooks for global caching
//

type KeyType<
  CachedType extends object,
  KeyFieldName extends keyof CachedType,
> = Pick<CachedType, KeyFieldName>;

export type Update<
  CachedType extends object,
  KeyFieldName extends keyof CachedType,
> = KeyType<CachedType, KeyFieldName> & DeepPartial<CachedType>;

export function useMergeIntoGlobalCache<
  CachedType extends object,
  KeyFieldName extends keyof CachedType,
>({
  keyGenerator,
}: {
  keyGenerator: (value: KeyType<CachedType, KeyFieldName>) => QueryKey;
}) {
  const queryClient = useQueryClient();

  return useCallback(
    (update: Update<CachedType, KeyFieldName>) => {
      const cacheKey = keyGenerator(update);

      const cachedValue = queryClient.getQueryData<CachedType | undefined>(
        cacheKey,
      );

      if (shouldUpdateCache({ cache: cachedValue, updates: update })) {
        queryClient.setQueryData<CachedType | undefined>(
          cacheKey,
          (prevValue: CachedType | undefined) => {
            const newValue = mergeWithBaseExceptArrays<
              CachedType,
              Update<CachedType, KeyFieldName>
            >({
              base: {},
              cache: prevValue,
              updates: update,
            });

            return newValue;
          },
        );
      }
    },
    [keyGenerator, queryClient],
  );
}

export function useBatchMergeIntoGlobalCache<
  CachedType extends object,
  KeyFieldName extends keyof CachedType,
>({
  keyGenerator,
}: {
  keyGenerator: (value: KeyType<CachedType, KeyFieldName>) => QueryKey;
}) {
  const mergeIntoGlobalCache = useMergeIntoGlobalCache({ keyGenerator });

  return useCallback(
    (updates: Update<CachedType, KeyFieldName>[]) => {
      for (const update of updates) {
        mergeIntoGlobalCache(update);
      }
    },
    [mergeIntoGlobalCache],
  );
}

export function useGloballyCachedObject<CachedType>({
  fallback,
  keyGenerator,
}: {
  fallback: CachedType;
  // Generate the global cache key to use; should change when the the fallback is replaced (e.g. new frame)
  keyGenerator: (value: CachedType) => QueryKey;
}): CachedType {
  const cacheKey = useMemo(
    () => keyGenerator(fallback),
    [fallback, keyGenerator],
  );

  const stringifiedKey = useMemo(
    () => stringifyGlobalCacheUsageKey(cacheKey),
    [cacheKey],
  );

  // We need a dummy query to front the cached value so that we get state updates (after directly inserting into the cache). We
  // use the fallback to make typing work, and the query is disabled so that the fallback does not overwrite the cached value.
  const cachedValue = useQuery({
    queryKey: cacheKey,
    queryFn: () => fallback,
    enabled: false,
  }).data;

  // We keep a reference to the the merged value (i.e. the fallback object combined with the global cache overrides),
  // so we can return a stable value if the hook re-renders but none of the inputs have changed.
  // We use a ref and not a state for performance reasons. Dependent components will re-render when the cached value changes anyway
  // so we don't need to trigger an additional re-render with state
  const mergedValueRef = useRef<CachedType | undefined>(undefined);

  useEffect(() => {
    // When either the fallback object or the cached value change, we _do_ want to return a new object,
    // so any components/hooks depending on the return value know to update
    if (!fallback) {
      // If the fallback is undefined/null, use it directly (there is no point storing {})
      mergedValueRef.current = undefined;
    } else {
      mergedValueRef.current = mergeWithBaseExceptArrays({
        base: {},
        cache: fallback,
        updates: cachedValue,
      });
    }
  }, [cachedValue, fallback]);

  // While the `useEffect` hook above takes care of generating a new merged value (used by consumers who need it),
  // we also merge the fallback and cached values directly into our the current mergedValue object. Since we aren't
  // creating a new object with each render, this won't trigger unnecessary re-renders for consumers of the return value.
  // However, in the event that the fallback of cached value change, by doing merge into the our soon-to-be-replaced merged value,
  // we can render the new data slightly sooner (i.e. before the `useEffect` runs) for any components that aren't waiting for the
  // object reference to change.
  return useMemo(() => {
    // Short-circuit if the fallback is undefined/null, as we don't want to return {}
    if (!fallback) {
      return fallback;
    }

    const mergedValue = mergedValueRef.current;

    // Change the base immediately if the cached value no longer corresponds to the fallback object, detected via a change in the
    // cache key
    // TS has some trouble with typing so we help it
    const base: CachedType | Record<string, never> =
      typeof mergedValue === 'undefined' ||
      stringifiedKey !== stringifyGlobalCacheUsageKey(keyGenerator(mergedValue))
        ? {}
        : mergedValue;

    return mergeWithBaseExceptArrays({
      base,
      cache: fallback,
      updates: cachedValue,
    });
  }, [cachedValue, fallback, keyGenerator, stringifiedKey]);
}

export function useOptimisticallyUpdateObject<
  CachedType extends object,
  KeyFieldName extends keyof CachedType,
>({
  keyGenerator,
}: {
  keyGenerator: (value: KeyType<CachedType, KeyFieldName>) => QueryKey;
}) {
  const queryClient = useQueryClient();
  const mergeIntoGlobalCache = useMergeIntoGlobalCache({ keyGenerator });

  return useCallback(
    (update: Update<CachedType, KeyFieldName>) => {
      const cacheKey = keyGenerator(update);
      const previouslyCachedValue = queryClient.getQueryData<
        CachedType | undefined
      >(cacheKey);

      mergeIntoGlobalCache(update);

      // Return revert function
      return () => {
        queryClient.setQueryData<CachedType | undefined>(
          cacheKey,
          previouslyCachedValue,
        );
      };
    },
    [keyGenerator, mergeIntoGlobalCache, queryClient],
  );
}

export { mergeExceptArrays, mergeWithBaseExceptArrays, shouldUpdateCache };
