import { useCallback } from 'react';

const useExternalNavigate = () => {
  return useCallback(
    ({ to, openInNewTab }: { to: string; openInNewTab: boolean }) => {
      if (window.open) {
        window.open(to, openInNewTab ? '_blank' : '_self');
      }
    },
    [],
  );
};

export { useExternalNavigate };
